import AddressField from "@/components/common/form/AddressField";
import { FC } from "react";
import { Field } from "formik";
import { PhoneInput } from "@halftax/ui";
import { TextField } from "formik-mui";

type Props = {
  formPrefix: string;
};

export const ContactEditOfficeFields: FC<Props> = ({ formPrefix }) => {
  return (
    <>
      <Field
        name={`${formPrefix}.description`}
        label="Description"
        component={TextField}
        fullWidth
      />
      <AddressField label="Property Address" prefix={`${formPrefix}.address`} />
      <Field name={`${formPrefix}.website`} label="Website" component={TextField} fullWidth />
      <Field
        name={`${formPrefix}.phone`}
        label="Phone"
        component={TextField}
        fullWidth
        InputProps={{
          inputComponent: PhoneInput,
        }}
      />
      <Field
        name={`${formPrefix}.email`}
        label="E-mail"
        type="email"
        component={TextField}
        fullWidth
      />
    </>
  );
};

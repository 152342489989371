import { Autocomplete, AutocompleteProps } from "formik-mui";
import { TextField as MuiTextField, TextFieldProps } from "@mui/material";
import { StateCode, stateLabels } from "@halftax/ui";

import React from "react";

export const StateAutocomplete = <
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
>(
  props: Omit<
    AutocompleteProps<StateCode, Multiple, DisableClearable, false>,
    "options" | "getOptionLabel" | "renderInput"
  >,
) => (
  <Autocomplete
    options={Object.values(StateCode)}
    getOptionLabel={(stateCode: StateCode) => (stateCode ? stateLabels[stateCode] : "")}
    renderInput={(params: TextFieldProps) => <MuiTextField {...params} placeholder="State" />}
    isOptionEqualToValue={(option, value) => value === undefined || option === value}
    fullWidth
    {...props}
  />
);

import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";

import { Plus as AddIcon } from "mdi-material-ui";
import ContactCard from "@/components/common/ContactCard";
import { ContactType } from "@/types/contact";
import { FC } from "react";
import Reminder from "@/components/common/Reminder";
import { Styles } from "@halftax/ui";
import { EntityType } from "@/types/common";
import { useGetEntity } from "@/utils/entity";
import { useContactEditDialog } from "@/components/common/dialogs/contactEdit";

interface Props {
  entityId: number;
  entityType: Extract<EntityType, "prospects" | "leads" | "customers">;
}

const GeneralTab: FC<Props> = ({ entityId, entityType }) => {
  const { data, isSuccess } = useGetEntity[entityType](entityId);
  const { openContactEdit } = useContactEditDialog(entityType, entityId);

  const styles: Styles = {
    description: (theme) => ({
      color: theme.palette.text.disabled,
      fontSize: "0.875rem",
      fontWeight: 400,
      letterSpacing: "0.15px",
      lineHeight: "1.25rem",
    }),
    addContactButton: (theme) => ({
      bgcolor: theme.palette.primary.main,
      p: 1.5,

      ":hover": { bgcolor: theme.palette.primary.accent },
    }),
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={9}>
        <Grid container>
          {isSuccess ? (
            <>
              <Grid item xs={12} sm={6} md={4}>
                {data && <ContactCard {...data?.owner} description="Owner of Record" />}
              </Grid>

              {data?.contacts.map(
                (contact, index) =>
                  contact.type !== ContactType.Owner && (
                    <Grid key={`${contact.name}${index}`} item xs={12} sm={6} md={4}>
                      <ContactCard {...contact} />
                    </Grid>
                  ),
              )}
            </>
          ) : (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <ContactCard skeleton />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ContactCard skeleton />
              </Grid>
            </>
          )}

          {data?.contacts?.length === 1 && (
            <Grid item xs={12} sm={6} md={4}>
              <Stack direction="row" sx={{ p: 3 }} spacing={1}>
                <Box>
                  <IconButton sx={styles.addContactButton} onClick={openContactEdit}>
                    <AddIcon />
                  </IconButton>
                </Box>

                <Box>
                  <Typography component="div" variant="subtitle1">
                    Add contact
                  </Typography>
                  <Typography component="div" sx={styles.description}>
                    Add another contact
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Reminder />
      </Grid>
    </Grid>
  );
};

export default GeneralTab;

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Field, Form, FormikProvider, useFormik } from "formik";
import { useEffect, useRef, useState } from "react";

import { FC } from "react";
import PasswordTextField from "@/components/auth/PasswordTextField";
import { Styles } from "@halftax/ui";
import { TextField } from "formik-mui";
import { useAuthenticator } from "@aws-amplify/ui-react";

type AmplifyAuthRoute = ReturnType<typeof useAuthenticator>["route"];

const ForgotPasswordDialog: FC = () => {
  const { route, isPending, error, toSignIn, toResetPassword, submitForm, resendCode } =
    useAuthenticator((ctx) => [
      ctx.route,
      ctx.isPending,
      ctx.error,
      ctx.toSignIn,
      ctx.toResetPassword,
      ctx.submitForm,
      ctx.updateBlur,
      ctx.resendCode,
    ]);

  const formikReset = useFormik({
    initialValues: { username: "" },
    onSubmit: (values) => {
      submitForm({
        type: "RESET_PASSWORD",
        ...values,
      });
    },
  });

  const formikResetConfirm = useFormik({
    initialValues: {
      confirmation_code: "",
      password: "",
      confirm_password: "",
    },
    onSubmit: (values) => {
      submitForm({
        type: "CONFIRM_RESET_PASSWORD",
        ...values,
      });
    },
  });

  useEffect(() => {
    formikReset.setSubmitting(isPending);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPending]);

  useEffect(() => {
    if (route === "signIn") {
      setOpen(false);
      formikResetConfirm.setSubmitting(isPending);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPending]);

  const [open, setOpen] = useState(false);
  const prevRouteRef = useRef<AmplifyAuthRoute>();

  const handleOpen = () => {
    toResetPassword();
    setOpen(true);
  };

  const handleClose = () => {
    toSignIn();
    setOpen(false);
  };

  useEffect(() => {
    if (prevRouteRef.current !== route) prevRouteRef.current = route;
  }, [route]);

  const styles: Styles = {
    forgotPasswordButton: (theme) => ({
      borderRadius: 0,
      boxSizing: "border-box",
      fontSize: "0.875rem",
      fontWeight: 500,
      letterSpacing: "0.15px",
      lineHeight: "1.25rem",
      padding: 0,

      ":hover": {
        background: "none",
        color: theme.palette.common.black,
      },
    }),
  };

  return (
    <>
      <Button
        variant="text"
        sx={styles.forgotPasswordButton}
        disableRipple
        disableElevation
        onClick={handleOpen}
      >
        Forgot password?
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        {route === "resetPassword" ||
        (route === "signIn" && prevRouteRef.current === "resetPassword") ? (
          <FormikProvider value={formikReset}>
            <Form>
              <DialogTitle sx={{ background: "none", p: 3, mb: 2 }}>Forgot password?</DialogTitle>
              <DialogContent>
                <DialogContentText sx={{ mb: 5 }}>
                  Enter your e-mail or username and we will send you a code to reset your password.
                </DialogContentText>

                <Field
                  component={TextField}
                  name="username"
                  label="Email / Username"
                  required
                  fullWidth
                  sx={{ mb: 0 }}
                />

                {error && (
                  <Alert severity="error" sx={{ my: 2 }}>
                    {error}
                  </Alert>
                )}
              </DialogContent>
              <DialogActions sx={{ p: 3 }}>
                <Button variant="outlined" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" disabled={formikReset.isSubmitting}>
                  Send Code
                </Button>
              </DialogActions>
            </Form>
          </FormikProvider>
        ) : (
          <FormikProvider value={formikResetConfirm}>
            <Form>
              <DialogTitle sx={{ background: "none", p: 3, mb: 2 }}>
                Reset your password
              </DialogTitle>

              <DialogContent>
                <Field
                  component={TextField}
                  name="confirmation_code"
                  label="Code"
                  placeholder="Enter your code"
                  fullWidth
                  required
                />

                <Field
                  component={PasswordTextField}
                  name="password"
                  label="New Password"
                  placeholder="Enter your new password"
                  required
                  fullWidth
                />

                <Field
                  component={PasswordTextField}
                  name="confirm_password"
                  label="Confirm Password"
                  placeholder="Confirm your new password"
                  required
                  fullWidth
                  sx={{ mb: 0 }}
                />

                {error && (
                  <Alert severity="error" sx={{ my: 2 }}>
                    {error}
                  </Alert>
                )}
              </DialogContent>
              <DialogActions sx={{ p: 3 }}>
                <Button
                  variant="outlined"
                  disabled={formikResetConfirm.isSubmitting}
                  onClick={resendCode}
                >
                  Resend Code
                </Button>
                <Button type="submit" disabled={formikResetConfirm.isSubmitting}>
                  Submit
                </Button>
              </DialogActions>
            </Form>
          </FormikProvider>
        )}
      </Dialog>
    </>
  );
};

export default ForgotPasswordDialog;

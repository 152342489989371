import { useCallback, useState } from "react";

import ProspectEditDialog from "@/components/prospect/ProspectEditDialog/ProspectEditDialog";
import { useModal } from "@halftax/ui";

const useProspectEditDialog = () => {
  const [prospectId, setProspectId] = useState<number>();

  const { showModal } = useModal(
    ({ hideModal }) =>
      !!prospectId && <ProspectEditDialog onExited={hideModal} prospectId={prospectId} />,
    [prospectId],
  );

  const openProspectEditDialog = useCallback(
    (prospectId: number) => {
      setProspectId(prospectId);
      showModal();
    },
    [showModal],
  );

  return { openProspectEditDialog };
};

export default useProspectEditDialog;

import { Plus as AddIcon, PencilOutline as EditIcon } from "mdi-material-ui";
import { Grid, IconButton, Typography } from "@mui/material";
import { useGetCounty } from "@/api/county";

import AppealsTab from "@/components/common/AppealsTab";
import AssessmentsTab from "@/components/common/AssessmentsTab";
import CountyContactTab from "@/components/county/details/tabs/CountyContactTab";
import CountyLinks from "@/components/county/details/tabs/CountyLinks";
import CountyTaxRates from "@/components/county/details/tabs/CountyTaxRates";
import DocumentsTab from "@/components/document/DocumentsTab";
import { FC } from "react";
import TabPanel from "@/components/common/TabPanel";
import invariant from "tiny-invariant";
import { useContactEditDialog } from "@/components/common/dialogs/contactEdit";
import { useDocumentsUploadDialog } from "@/components/document/DocumentsUploadDialog";
import { useLinkAddDialog } from "@/components/common/dialogs/linkAdd";
import { useParams } from "react-router-dom";
import LatestActivity from "@/components/common/LatestActivity";
import MessagesTab from "@/components/common/MessagesTab";
import { useHeader } from "@/hooks/useHeader";
import { displayState } from "@/utils/display";

const CountyDetails: FC = () => {
  const { countyId: id } = useParams();
  invariant(id, "Missing URL param for county ID!");

  const { data, isSuccess } = useGetCounty(id);
  const countyId = Number(id);

  const { openContactEdit } = useContactEditDialog("counties", countyId);
  const { openLinkAddDialog } = useLinkAddDialog();
  const { openDocumentsUploadDialog } = useDocumentsUploadDialog();

  useHeader({
    title: `${data?.name} County, ${displayState(data?.state)}`,
    backRoute: "/counties",
    enabled: isSuccess,
  });

  const tabs = {
    contact: {
      label: "Contact information",
      content: <CountyContactTab countyId={id} />,
      buttons: (
        <IconButton onClick={openContactEdit}>
          <EditIcon />
        </IconButton>
      ),
    },
    messages: {
      label: "Messages",
      content: <MessagesTab />,
    },
    appeals: {
      label: "Appeals",
      disabled: true,
      content: isSuccess ? <AppealsTab {...data} /> : <AppealsTab skeleton />,
      note: (
        <Typography noWrap sx={{ fontStyle: "italic", pr: 0.5 }}>
          Informal
        </Typography>
      ),
    },
    assessments: {
      label: "Assessments",
      disabled: true,
      content: isSuccess ? <AssessmentsTab {...data} /> : <AssessmentsTab skeleton />,
    },
    taxRates: {
      label: "Municipalities Tax Rates",
      disabled: true,
      content: <CountyTaxRates municipalities={data?.municipalities ?? []} />,
      note: (
        <Typography noWrap sx={{ fontStyle: "italic", pr: 0.5 }}>
          {isSuccess && `${data.name} County ${parseFloat(data.tax_rate)}%`}
        </Typography>
      ),
    },
    links: {
      label: "Links",
      disabled: true,
      content: <CountyLinks countyId={countyId} />,
      buttons: (
        <IconButton size="large" onClick={() => openLinkAddDialog(countyId)}>
          <AddIcon />
        </IconButton>
      ),
    },
    documents: {
      label: "Documents",
      disabled: true,
      content: <DocumentsTab entityType="counties" entityId={Number(id)} />,
      buttons: (
        <IconButton onClick={() => openDocumentsUploadDialog("counties", countyId)}>
          <AddIcon />
        </IconButton>
      ),
    },
    activity: {
      label: "Latest Activity",
      disabled: true,
      content: <LatestActivity />,
    },
  };

  return (
    <Grid container columnSpacing={3} rowSpacing={4} sx={{ p: 3, flexGrow: 1 }}>
      <Grid item xs={12}>
        <TabPanel tabs={[tabs.contact, tabs.messages]} />
      </Grid>

      <Grid item xs={12} lg={6}>
        <TabPanel tabs={[tabs.appeals]} />
      </Grid>

      <Grid item xs={12} lg={6}>
        <TabPanel tabs={[tabs.assessments]} />
      </Grid>

      <Grid item xs={12} lg={6}>
        <TabPanel tabs={[tabs.taxRates]} />
      </Grid>

      <Grid item xs={12} lg={6}>
        <TabPanel tabs={[tabs.links]} />
      </Grid>

      <Grid item xs={12}>
        <TabPanel tabs={[tabs.documents]} />
      </Grid>

      <Grid item xs={12}>
        <TabPanel tabs={[tabs.activity]} />
      </Grid>
    </Grid>
  );
};

export default CountyDetails;

import { Attachment, DeleteAttachment, GetAttachments, UploadAttachment } from "@/types/attachment";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { API } from "aws-amplify";
import { API_NAME } from "@/config";
import { deleteById, queryKey } from "@/api";

export const useGetAttachments = ({ entity_id, entity_type, type }: GetAttachments) =>
  useQuery(
    queryKey(["attachments", "list", entity_type, entity_id, type]),
    (): Promise<Attachment[]> =>
      API.get(API_NAME, "attachments", {
        queryStringParameters: { entity_id, entity_type, type },
      }),
  );

export const useUploadAttachment = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (data: UploadAttachment) => {
      await uploadFile(data);
      return data;
    },
    onSuccess: ({ entity_id, entity_type, type }) => {
      queryClient.invalidateQueries({
        queryKey: queryKey(["attachments", "list", entity_type, entity_id, type]),
      });
    },
  });

  return mutation;
};

export const useDeleteAttachment = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({ id, ...rest }: DeleteAttachment) => {
      await deleteById<Attachment>("attachments", id);
      return rest;
    },
    onSuccess: ({ entity_id, entity_type, type }) => {
      queryClient.invalidateQueries({
        queryKey: queryKey(["attachments", "list", entity_type, entity_id, type]),
      });
    },
  });

  return mutation;
};

const uploadToS3 = (
  presignedUrl: { fields: Record<string, string>; url: string },
  file: File,
): Promise<Response | null> => {
  const { fields, url } = presignedUrl;
  const form = new FormData();

  Object.keys(fields).forEach((key) => form.append(key, fields[key]));
  form.append("file", file);

  return fetch(url, {
    method: "POST",
    body: form,
  });
};

export const uploadFile = async ({ file, ...rest }: UploadAttachment) => {
  const uploadFields = await API.post(API_NAME, "attachments", {
    body: { filename: file.name, ...rest },
  });

  return await uploadToS3(uploadFields, file);
};

export const getDownloadURL = (uploadKey: string): Promise<{ url: string }> =>
  API.get(API_NAME, "attachments/download", {
    queryStringParameters: {
      key: uploadKey,
    },
  });

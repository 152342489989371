import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

export const sentryInit = () => {
  Sentry.init({
    release: import.meta.env.VITE_APP_VERSION,
    environment: "development",
    dsn: "https://6b4cc5c679744d68bdc02106806fe2b9@o1081075.ingest.sentry.io/4504831226019840",
    integrations: [new BrowserTracing({ tracingOrigins: [/^\//] })],
    tracesSampleRate: 1.0,
    beforeBreadcrumb(breadcrumb, hint) {
      return clientErrorResponseBreadcrumb(breadcrumb, hint) ?? breadcrumb;
    },
  });
};

/**
 * Utility to Include 4xx error responses in Sentry XHR Breadcrumbs.
 *
 * Useful when investigating issues involving bad API requests (like validation errors).
 */
const clientErrorResponseBreadcrumb = (
  breadcrumb: Sentry.Breadcrumb,
  hint?: Sentry.BreadcrumbHint,
): Sentry.Breadcrumb | undefined => {
  const statusCode = breadcrumb.data?.status_code;
  const isClientError = breadcrumb.category === "xhr" && statusCode >= 400 && statusCode < 500;

  if (!isClientError) {
    return;
  }

  const response = hint?.xhr?.response;

  if (typeof response === "object") {
    return {
      ...breadcrumb,
      level: "error",
      data: { ...response, ...breadcrumb.data },
    };
  } else if (typeof response === "string") {
    try {
      return {
        ...breadcrumb,
        level: "error",
        data: { ...JSON.parse(response), ...breadcrumb.data },
      };
    } catch {
      return { ...breadcrumb, level: "error", message: response };
    }
  }
};

import { EntityType } from "@/types/common";

export type NestedPartial<T> = {
  [P in keyof T]?: NestedPartial<T[P]>;
};

export type PartialSkeleton<T extends object> =
  | (Partial<T> & { skeleton: true })
  | (T & { skeleton?: never });

export enum PlaceholderEnum {
  ONE = 1,
  TWO = 2,
}

export interface SearchParams {
  q?: string;
  order?: string[];
  size: number;
  page: number;
  by?: string[];
}

export interface Search<Only extends readonly string[]> extends SearchParams {
  entity: EntityType;
  only: Only;
}

/**
 * An utility type from Typescript internals that converts an union type to an intersection type.
 * e. g.
 * ```
 * type Foo = UnionToIntersection<{ a: string } | { b: number }> = { a: string, b: number }
 * ```
 *
 * https://github.com/microsoft/TypeScript/blob/88d25b4f232929df59729156dfda6b65277affec/src/compiler/types.ts#L9181
 */
type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void
  ? I
  : never;

export type UnionToIntersectionRecursive<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [K in keyof T]: T[K] extends any[]
        ? UnionToIntersectionRecursive<UnionToIntersection<T[K][number]>[]>
        : T[K] extends object
        ? UnionToIntersectionRecursive<UnionToIntersection<T[K]>>
        : T[K];
    }
  : T; // if T is an union stop recursion, it's already wrapped by the parent

/**
 * Represents an union of all the possible nested keys of an object.
 */
export type Only<T, Visited = never> = T extends Visited // if already visited this type
  ? never // stop to avoid infinite recursion
  : T extends Record<number, Record<string, any>> // if it's an array of objects
  ? keyof T[number] | OnlyPrefix<T[number], keyof T[number], Visited | T> // get nested keys of the objects inside the array
  : T extends object // if it's an object
  ? keyof T | OnlyPrefix<T, keyof T, Visited | T> // get nested keys of the object
  : never;

type OnlyPrefix<T, TPrefix, Visited> = TPrefix extends keyof T & string
  ? `${TPrefix}.${Only<T[TPrefix], Visited> & string}`
  : never;

export type OnlyPickUnion<T, O extends Only<T>> = T extends Record<number, Record<string, any>>
  ? OnlyPickUnion<T[number], O extends Only<T[number]> ? O : never>[] // bypass array indexes when traversing keys
  : {
      // TODO(Kornelijus): make _Type with underscore not trigger no-unused-vars
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      [K in O as K extends `${infer Root}.${infer _Rest}` // if K is a nested key, use only Root as key, else use K
        ? Root
        : K]: K extends `${infer Root}.${infer Rest}` // if nested key
        ? Root extends keyof T // if root keyof T
          ? Rest extends Only<T[Root]> // if rest of nested key is valid
            ? OnlyPickUnion<T[Root], Rest>
            : never
          : never
        : K extends keyof T
        ? T[K]
        : never;
    };

export type OnlyPick<T, O extends Only<T>> = UnionToIntersectionRecursive<OnlyPickUnion<T, O>>;

import { Box, Stack, Typography } from "@mui/material";

import { FC } from "react";
import { Municipality } from "@/types/county";
import SmallTable from "@/components/common/SmallTable";
import { Styles } from "@halftax/ui";

type Props = {
  municipalities: Municipality[];
};

const CountyTaxRates: FC<Props> = ({ municipalities }) => {
  const displayTaxRate = (rate: string | number) => `${Number(rate).toFixed(2)}%`;
  const getAverageTaxRate = () =>
    municipalities.reduce((acc, municipality) => acc + Number(municipality.tax_rate), 0) /
    municipalities.length;

  const styles: Styles = {
    average: {
      width: "100%",
      justifyContent: "flex-end",
      px: 3,
      mb: 2,

      ".MuiTypography-root": {
        fontWeight: 600,
        fontSize: "0.875rem",
        lineHeight: "1.375rem",
        letterSpacing: "0.1px",
      },
    },
  };

  return (
    <>
      <SmallTable
        data={municipalities}
        rowsToDisplay={4}
        columns={(col) => [
          col.accessor("name", {
            header: "Name",
          }),
          col.accessor("tax_rate", {
            header: () => <Box sx={{ textAlign: "right" }}>Tax Rate</Box>,
            cell: (info) => (
              <Box sx={{ textAlign: "right" }}>{displayTaxRate(info.getValue())}</Box>
            ),
          }),
        ]}
      />

      {municipalities.length !== 0 && (
        <Stack direction="row" sx={styles.average} spacing={3}>
          <Typography>AVG</Typography>
          <Typography>{displayTaxRate(getAverageTaxRate())}</Typography>
        </Stack>
      )}
    </>
  );
};

export default CountyTaxRates;

import { EditContact, EditOwnerContact } from "@/types/contact";
import { Entities, useEditEntity, useGetEntity } from "@/utils/entity";
import { contactsProcess, ownerContactProcess } from "@/components/county/utils";
import { useCallback, useEffect, useState } from "react";

import { ContactEdit } from "@/components/common/dialogs/contactEdit";
import { useModal } from "@halftax/ui";

export type SetSubmitting = (isSubmitting: boolean) => void;

export const useContactEditDialog = (entityType: Entities, entityId: number) => {
  const { data } = useGetEntity[entityType](entityId);
  const { mutate } = useEditEntity[entityType](entityId);
  const [contactsList, setContactsList] = useState<EditContact[] | null>(null);
  const [ownerContact, setOwnerContact] = useState<EditOwnerContact | null>(null);

  useEffect(() => {
    if (data?.contacts) {
      setContactsList(data.contacts);
    }

    if (data && "owner" in data) {
      setOwnerContact(data.owner);
    }
  }, [data]);

  const { showModal, hideModal } = useModal(
    ({ hideModal }) => {
      return (
        contactsList?.length && (
          <ContactEdit
            onClose={hideModal}
            onSave={handleSave}
            contactsList={contactsList}
            ownerContactValue={ownerContact}
          />
        )
      );
    },
    [contactsList, ownerContact],
  );

  const openContactEdit = useCallback(() => {
    showModal();
  }, [showModal]);

  const handleSave = useCallback(
    // eslint-disable-next-line max-params
    (contacts: EditContact[], owner: EditOwnerContact | null, setSubmitting: SetSubmitting) => {
      setSubmitting(true);
      if (data && contacts && mutate) {
        mutate(
          {
            contacts: contactsProcess(contacts),
            ...(owner ? { owner: ownerContactProcess(owner) } : {}),
          },
          {
            onSuccess: () => {
              setSubmitting(false);
              hideModal();
            },
          },
        );
      }
    },
    [hideModal, data, mutate],
  );

  return {
    openContactEdit,
  };
};

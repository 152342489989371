import { FC } from "react";
import SmallTable from "@/components/common/SmallTable";
import { Database as ValueAddIcon } from "mdi-material-ui";

export const AddValueTab: FC = () => {
  return (
    <SmallTable
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      data={[] as any[]}
      options={{
        placeholder: {
          icon: <ValueAddIcon />,
          title: "Value Add is empty",
          description: "Enter information and it will be displayed here",
        },
      }}
      columns={(col) => [
        col.accessor("value", {
          header: "Value",
        }),
        col.accessor("marketCap", {
          header: "Market Cap",
        }),
        col.accessor("valueBoost", {
          header: "Value Boost",
        }),
        col.accessor("valueIncrease", {
          header: "Value increase",
        }),
      ]}
    />
  );
};

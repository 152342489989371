import { FC } from "react";
import {
  Grid,
  Typography,
  Paper,
  Box,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  useTheme,
} from "@mui/material";
import { Property } from "@/components/dashboard/types";
import { getColorByDirection } from "@/components/dashboard/utils";
import { displayState } from "@/utils/display";
import Incomplete from "@/components/common/Incomplete";

type Props = {
  property: Property;
};

export const DashboardProperties: FC<Props> = ({ property }) => {
  const theme = useTheme();
  const { total, states } = property;

  const styles = {
    map: {
      width: 1,
      height: 185,
      borderRadius: "0.5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    row: {
      "&:nth-last-of-type(1) .MuiTableCell-root": {
        pb: 0,
      },
    },
    cell: {
      "&:nth-last-of-type(1)": {
        pr: 0,
      },
      pr: 3,
      pt: 0,
      pb: 2.5,
      border: "none",
      whiteSpace: "nowrap",
    },
  };

  return (
    <Paper variant="outlined">
      <Grid
        container
        flexDirection="column"
        sx={{
          p: 3,
        }}
      >
        <Grid
          item
          textAlign="center"
          sx={{
            pb: 3,
          }}
        >
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 500 }}>
            {total}
          </Typography>
          <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
            Properties
          </Typography>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={6}>
              <Box sx={styles.map}>
                <Incomplete />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <TableContainer
                sx={{
                  p: 0,
                  pl: 3,
                }}
              >
                <Table>
                  <TableBody>
                    {states.map(({ code, value, change, direction }, index) => (
                      <TableRow
                        key={index}
                        sx={[
                          {
                            color: (theme) => theme.palette.text.secondary,
                          },
                          styles.row,
                        ]}
                      >
                        <TableCell sx={styles.cell}>{displayState(code)}</TableCell>
                        <TableCell sx={styles.cell}>{value}</TableCell>
                        <TableCell sx={styles.cell}>
                          <span
                            style={{
                              color: getColorByDirection(direction, theme),
                            }}
                          >
                            {change}
                          </span>{" "}
                          {direction} vs. last month
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

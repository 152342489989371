import { Styles } from "@halftax/ui";
import { Dialog, DialogProps, DialogActions, DialogContent, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { FC, forwardRef, useState } from "react";
import {
  NotificationsFilter,
  NotificationsList,
  NotificationsHeader,
  NotificationsFooter,
  getNotificationType,
} from "@/components/common/dialogs/notifications";
import { useGetNotifications } from "@/api/notification";
import { Notification } from "@/components/common/dialogs/notifications/types";

// TODO(HT-I81): Refactor notifications to use Drawer instead of Dialog
// https://sprints.zoho.eu/team/10speed#itemdetails/P6/I81

export interface NotificationsProps extends Omit<DialogProps, "open"> {
  hide: () => void;
}

const Transition: React.JSXElementConstructor<
  TransitionProps & { children: React.ReactElement<any, any> }
> = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const Notifications: FC<NotificationsProps> = ({ hide }) => {
  const [open, setOpen] = useState(true);
  const [notificationsFilter, setNotificationsFilter] = useState<NotificationsFilter | string>(
    NotificationsFilter.AllNotifications,
  );
  const { data, isSuccess } = useGetNotifications();

  const filterNotifications = (notifications: Notification[]) => {
    return notifications
      .filter((notification) => {
        if (notificationsFilter === NotificationsFilter.AllNotifications) {
          return true;
        }
        return notification.type === getNotificationType(notificationsFilter);
      })
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  };

  const styles: Styles = {
    wrapper: {
      position: "fixed",
      top: 0,
      right: 0,
      m: 0,
      height: "100%",
      width: 480,
      borderRadius: 0,
    },
    content: {
      width: 1,
      height: "calc(100% - 80px)",
      px: 0,
      pb: 9,
    },
    settings: {
      position: "absolute",
      right: 0,
      bottom: 0,
      p: 0,
      width: 1,
    },
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      scroll="body"
      TransitionComponent={Transition}
      transitionDuration={{ enter: 500, exit: 500 }}
      PaperProps={{ sx: styles.wrapper }}
      TransitionProps={{ onExited: hide }}
    >
      <NotificationsHeader
        onClose={() => setOpen(false)}
        notificationsFilterUpdate={setNotificationsFilter}
        notificationsFilter={notificationsFilter}
        loading={!isSuccess}
      />
      <DialogContent sx={styles.content}>
        {isSuccess && <NotificationsList notifications={filterNotifications(data)} />}
      </DialogContent>
      <DialogActions sx={styles.settings}>
        <NotificationsFooter closeModal={hide} />
      </DialogActions>
    </Dialog>
  );
};

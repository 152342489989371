import { Paper } from "@mui/material";
import { FC } from "react";
import Incomplete from "@/components/common/Incomplete";

export const DashboardCalendar: FC = () => {
  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Incomplete />
    </Paper>
  );
};

import { IconButton, Link as MuiLink, Stack } from "@mui/material";

import { LinkVariant as LinkIcon, TrashCanOutline as DeleteIcon } from "mdi-material-ui";
import { FC } from "react";
import SmallTable from "@/components/common/SmallTable";
import { displayURL } from "@/utils/display";
import { useConfirmationDialog } from "@/components/common/ConfirmationDialog";
import { useEditCounty, useGetCounty } from "@/api/county";
import invariant from "tiny-invariant";

export interface CountyLinksProps {
  countyId: number;
}

const CountyLinks: FC<CountyLinksProps> = ({ countyId }) => {
  const { data } = useGetCounty(countyId);
  const { mutate } = useEditCounty(countyId);

  const removeLink = (index: number) => {
    invariant(data, "County data must be fetched before removing a link");

    const links = [...data.links];
    links.splice(index, 1);

    mutate({ links });
  };

  const { confirm: confirmDelete } = useConfirmationDialog({
    title: "Delete Link",
    description: "Are you sure you want to delete this link?",
    confirmLabel: "Delete",
  });

  return (
    <SmallTable
      data={data?.links ?? []}
      options={{
        placeholder: {
          icon: <LinkIcon />,
          title: "No Links Yet",
          description: "Once links are added, they will be displayed here",
        },
      }}
      columns={(col) => [
        col.accessor("name", {
          header: "Name",
        }),
        col.accessor("url", {
          header: "Link",
          cell: (info) => (
            <MuiLink href={info.getValue()} target="_blank" rel="noopener noreferrer">
              {displayURL(info.getValue())}
            </MuiLink>
          ),
        }),
        col.display({
          id: "actions",
          size: 32,
          cell: (info) => (
            <Stack direction="row" sx={{ my: -1, justifyContent: "flex-end" }}>
              <IconButton
                size="small"
                onClick={() => confirmDelete(() => removeLink(info.row.index))}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Stack>
          ),
        }),
      ]}
    />
  );
};

export default CountyLinks;

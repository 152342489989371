import { Skeleton, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";

import { County } from "@/types/county";
import { FC } from "react";
import { PartialSkeleton } from "@/types/util";
import { displayDate } from "@/utils/display";

export type AssessmentsTabProps = Pick<
  County,
  "assessment_next" | "assessment_last" | "assessment_frequency"
>;

const AssessmentsTab: FC<PartialSkeleton<AssessmentsTabProps>> = ({
  assessment_next,
  assessment_last,
  assessment_frequency,
  skeleton,
}) => (
  <TableContainer>
    <Table
      sx={(theme) => ({
        ".MuiTableCell-root": {
          ":first-of-type": { pr: 1, ...theme.typography.subtitle2 },
          ...theme.typography.body2,
        },
      })}
    >
      <TableBody>
        <TableRow>
          <TableCell>Next Assessment</TableCell>
          <TableCell>{displayDate({ ISOdate: assessment_next, showSkeleton: false })}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Last Assessment</TableCell>
          <TableCell>{displayDate({ ISOdate: assessment_last, showSkeleton: false })}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Assessment Frequency</TableCell>
          <TableCell>
            {skeleton ? (
              <Skeleton width="12ch" />
            ) : assessment_frequency > 1 ? (
              `Every ${assessment_frequency} years`
            ) : (
              "Every year"
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

export default AssessmentsTab;

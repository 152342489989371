import { Notification } from "@/components/common/dialogs/notifications/types";

export const notifications: Notification[] = [
  {
    title: "Invoice issued",
    description: "Invoice nr. 12222",
    date: "2023-01-28T15:45:30",
    type: 1,
  },
  {
    title: "Lilly MacDonald",
    description: "Hello, Thomas, Please send me private message ok",
    date: "2023-01-28T09:22:30",
    type: 0,
  },
  {
    title: "John Johnson",
    description: "Hello, Thomas, Please send me private message ok",
    date: "2023-01-28T08:22:15",
    type: 0,
  },
  {
    title: "New link added",
    description: "www.notes.com added to Alabama County",
    date: "2023-01-27T07:45:30",
    type: 2,
  },
  {
    title: "New list imported",
    description: "Prospects from Alabama imported to Prospects",
    date: "2023-01-27T15:46:30",
    type: 3,
  },
  {
    title: "John Johnson",
    description: "Hello, Thomas, Please send me private message ok",
    date: "2023-01-18T08:22:30",
    type: 0,
  },
  {
    title: "John Johnson status changed",
    description: "Data uploaded changed to Marketing completed",
    date: "2022-08-08T15:45:30",
    type: 4,
  },
  {
    title: "Lilly MacDonald",
    description: "Hello, Thomas, Please send me private message ok",
    date: "2022-08-08T09:22:30",
    type: 0,
  },
  {
    title: "John Johnson contact information changed",
    description: "655-544-8544 changed to 544-544-4544",
    date: "2022-08-09T09:22:30",
    type: 5,
  },
  {
    title: "New template added",
    description: "Intro added to Templates",
    date: "2022-08-09T09:22:30",
    type: 6,
  },
  {
    title: "John Johnson",
    description: "Hello, Thomas, Please send me private message ok",
    date: "2022-08-08T08:22:30",
    type: 0,
  },
];

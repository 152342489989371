import { FC, ReactNode } from "react";

import { Grid } from "@mui/material";
import { Styles } from "@halftax/ui";

interface AuthWallProps {
  children: ReactNode;
}

const AuthWall: FC<AuthWallProps> = ({ children }) => {
  const styles: Styles = {
    gridLogo: (theme) => ({
      alignItems: "center",
      bgcolor: theme.palette.secondary.main,
      display: { xs: "none", md: "flex" },
      justifyContent: { xs: "center", xl: "flex-end" },
      pr: { xs: 0, xl: 20 },
    }),
    gridAuth: (theme) => ({
      alignItems: "center",
      bgcolor: theme.palette.common.white,
      display: "flex",
      justifyContent: { xs: "center", xl: "flex-start" },
      pl: { xs: 0, xl: 16 },
    }),
  };

  return (
    <Grid container sx={{ height: "100vh" }}>
      <Grid item xs={12} md={6} sx={styles.gridLogo}>
        <img src="/logo-login.svg" alt="Halftax Logo" />
      </Grid>

      <Grid item xs={12} md={6} sx={styles.gridAuth}>
        {children}
      </Grid>
    </Grid>
  );
};

export default AuthWall;

import { useCallback, useState } from "react";

import { AppealResults } from "@/components/common/dialogs/appealResults/AppealResults";
import { useModal } from "@halftax/ui";

export const useAppealResultsDialog = () => {
  const [disableAfter, setDisableAfter] = useState<boolean | undefined>(false);

  const { showModal } = useModal(
    ({ hideModal }) => <AppealResults onExited={hideModal} disableAfter={disableAfter} />,
    [],
  );

  const openAppealResult = useCallback(
    (disableAfter?: boolean) => {
      setDisableAfter(disableAfter);
      showModal();
    },
    [showModal],
  );

  return { openAppealResult };
};

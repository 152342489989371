import { Alert, Box, Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { FC, useEffect } from "react";
import { Field, Form, FormikProvider, useFormik } from "formik";
import { object, string } from "yup";

import { Auth } from "aws-amplify";
import ForgotPasswordDialog from "@/components/auth/ForgotPasswordDialog";
import PasswordTextField from "@/components/auth/PasswordTextField";
import SignUpDialog from "@/components/auth/SignUpDialog";
import { Styles } from "@halftax/ui";
import { TextField } from "formik-mui";
import { atomWithStorage } from "jotai/utils";
import { useAtom } from "jotai";
import { useAuthenticator } from "@aws-amplify/ui-react";

const loginSchema = object({
  username: string().required("Required").default(""),
  password: string().required("Required").default(""),
});

// remember me state stored in local storage
export const rememberMeAtom = atomWithStorage("rememberMe", false);

const LoginForm: FC = () => {
  const { route, submitForm, isPending, error } = useAuthenticator((ctx) => [
    ctx.route,
    ctx.submitForm,
    ctx.isPending,
    ctx.error,
  ]);
  const formik = useFormik({
    validationSchema: loginSchema,
    initialValues: loginSchema.cast({}),
    validateOnBlur: false,
    onSubmit: (values) => {
      // Amplify does not provide "Remember Me" functionality &
      // stores auth tokens in local storage, so it's persisted by default.
      // https://github.com/aws-amplify/amplify-js/issues/3436
      // https://github.com/aws-amplify/amplify-js/issues/3010
      // a quick workaround is to configure Auth to use sessionStorage when remember me is not checked.
      if (rememberMe) {
        Auth.configure({ storage: localStorage });
      } else {
        Auth.configure({ storage: sessionStorage });
      }
      submitForm({
        type: "SIGN_IN",
        ...values,
      });
    },
  });

  const { setSubmitting, isSubmitting } = formik;
  useEffect(() => {
    if (route === "signIn") setSubmitting(isPending);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPending]);

  const [rememberMe, setRememberMe] = useAtom(rememberMeAtom);

  const styles: Styles = {
    containerAuth: {
      display: "flex",
      flexDirection: "column",
      minHeight: { sm: "45rem" },
      padding: 4,
      width: { xs: "100%", sm: "24.5rem" },
    },
    rememberMe: {
      height: "1.25rem",

      "& .MuiTypography-root": {
        fontSize: "0.875rem",
        fontWeight: 400,
        letterSpacing: "0.15px",
        lineHeight: "1.25rem",
        userSelect: "none",
      },
    },
  };

  return (
    <Box sx={styles.containerAuth}>
      <Box sx={{ mb: { xs: 2, md: 10 } }}>
        <Typography variant="h4" sx={{ mb: 1 }}>
          Welcome back!
        </Typography>
        <Typography
          variant="body1"
          sx={(theme) => ({
            color: theme.palette.text.secondary,
          })}
        >
          Login to continue
        </Typography>
      </Box>

      <FormikProvider value={formik}>
        <Form>
          <Field component={TextField} name="username" label="Email / Username" fullWidth />
          <Field component={PasswordTextField} name="password" label="Password" fullWidth />

          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 6 }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  size="small"
                  onChange={(event) => setRememberMe(event.target.checked)}
                  checked={rememberMe}
                />
              }
              label="Remember me"
              sx={styles.rememberMe}
            />
            <ForgotPasswordDialog />
          </Box>

          <Button size="medium" type="submit" disabled={isSubmitting} fullWidth>
            Log In
          </Button>

          {route === "signIn" && error && (
            <Alert severity="error" sx={{ mt: 4 }}>
              {error}
            </Alert>
          )}
        </Form>
      </FormikProvider>

      <Box sx={{ mt: "auto", pt: 4 }}>
        <SignUpDialog />
      </Box>
    </Box>
  );
};

export default LoginForm;

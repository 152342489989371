import { Authenticator as AmplifyAuthenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { FC, ReactNode } from "react";

import AuthWall from "@/components/auth/AuthWall";
import ConfirmSignUpForm from "@/components/auth/ConfirmSignUpForm";
import LoadingScreen from "@/components/auth/LoadingScreen";
import LoginForm from "@/components/auth/LoginForm";

interface AuthenticatorProps {
  children: ReactNode;
}

const Authenticator: FC<AuthenticatorProps> = ({ children }) => {
  const { route } = useAuthenticator((ctx) => [ctx.route]);

  // TODO(Kornelijus): Refactor to not use Amplify internals like submitForm once either of these is resolved:
  // https://github.com/aws-amplify/amplify-ui/issues/1977
  // https://github.com/aws-amplify/amplify-ui/issues/2288

  switch (route) {
    case "authenticated":
      return <>{children}</>;
    case "idle":
    case "signIn":
    case "signOut":
    case "signUp":
    case "resetPassword":
    case "confirmResetPassword":
      return (
        <AuthWall>
          <LoginForm />
        </AuthWall>
      );
    case "confirmSignUp":
      return (
        <AuthWall>
          <ConfirmSignUpForm />
        </AuthWall>
      );
    default:
      return (
        <AuthWall>
          <AmplifyAuthenticator />
        </AuthWall>
      );
  }
};

const AuthenticatorWrapper: FC<AuthenticatorProps> = ({ children }) => (
  <AmplifyAuthenticator.Provider>
    <LoadingScreen />
    <Authenticator>{children}</Authenticator>
  </AmplifyAuthenticator.Provider>
);

export default AuthenticatorWrapper;

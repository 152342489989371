import { Box, ButtonGroup, Tab as MuiTab, Paper } from "@mui/material";
import { FC, ReactNode, useState } from "react";
import { TabPanel as MuiTabPanel, TabContext, TabList } from "@mui/lab";
import { TabGroupProps, TabGroupTab } from "@/components/common/TabGroup";

import { Styles } from "@halftax/ui";

export interface TabPanelTab extends Pick<TabGroupTab, "label" | "content"> {
  disabled?: boolean;
  buttons?: ReactNode | ReactNode[];
  id?: string;
}

type TabPanelTopRight =
  | {
      buttons?: ReactNode;
      note?: never;
    }
  | {
      buttons?: never;
      note?: ReactNode;
    };

export type TabPanelProps = TabGroupProps<TabPanelTab & TabPanelTopRight>;

const TabPanel: FC<TabPanelProps> = ({ tabs }) => {
  const [value, setValue] = useState<string>(tabs?.[0]?.id || tabs?.[0]?.label);

  const styles: Styles = {
    list: {
      display: "flex",
      justifyContent: "space-between",
      mb: "-1px", // tabs should overlap the border on the Paper element
      minHeight: "2.25rem",

      ".MuiTab-root:first-of-type": {
        borderStartStartRadius: "0.5rem",
      },

      ".MuiTab-root:last-of-type": {
        borderStartEndRadius: "0.5rem",
      },

      ".MuiTabs-indicator": {
        display: "none",
      },
    },
    tab: (theme) => ({
      bgcolor: theme.palette.primary.light,
      color: theme.palette.text.disabled,
      textTransform: "capitalize",
      ...theme.typography.subtitle1,
      padding: "0.25rem 1.5rem",
      minHeight: "2.25rem",

      "&.Mui-selected": {
        bgcolor: theme.palette.primary.main,
        color: theme.palette.text.primary,
      },
    }),
    buttons: {
      position: "absolute",
      right: 0,
      top: 0,
      transform: "translateY(-100%)",
    },
    note: {
      position: "absolute",
      right: 0,
      top: 0,
      transform: "translateY(-100%)",
      background: "none",
      boxShadow: "none",
    },
    paper: (theme) => ({
      borderRadius: "0 0.5rem 0.5rem 0.5rem",
      border: `1px solid ${theme.palette.other.border}`,
      boxShadow: "0px 0px 5px rgba(213, 213, 213, 0.25)",
      minHeight: "9.125rem",
      height: 1,
    }),
    panelWrapper: {
      p: 0,
      height: "calc(100% - 2.25rem)",
    },
    paperWithButtons: {
      borderRadius: "0 0 0.5rem 0.5rem",
    },
  };

  return (
    <TabContext value={value}>
      <TabList
        onChange={(_event, newValue) => {
          setValue(newValue);
        }}
        sx={styles.list}
      >
        {tabs.map(({ label, disabled, id }) => (
          <MuiTab
            label={label}
            value={id || label}
            key={id || label}
            disabled={disabled}
            sx={styles.tab}
          />
        ))}
      </TabList>

      {tabs.map(({ label, buttons, note, content, id }) => (
        <MuiTabPanel value={id || label} key={id || label} sx={styles.panelWrapper}>
          {buttons && (
            <Box sx={{ position: "relative" }}>
              <ButtonGroup variant="icon" sx={styles.buttons}>
                {buttons}
              </ButtonGroup>
            </Box>
          )}
          {note && (
            <Box sx={{ position: "relative" }}>
              <Paper sx={styles.note}>{note}</Paper>
            </Box>
          )}
          <Paper sx={[styles.paper, !!buttons && styles.paperWithButtons]}>{content}</Paper>
        </MuiTabPanel>
      ))}
    </TabContext>
  );
};

export default TabPanel;

import { EditCustomer, Customer } from "@/types/lead";
import { getByIdOnly, putById, queryKey } from "@/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const useGetCustomer = (id: number | string) => {
  const only = [
    "id",
    "status",
    "contacts",
    "owner",
    "properties.id",
    "properties.main_prospect.address",
    "properties.main_prospect.type_property",
    "properties.prospects.id",
    "properties.prospects.pin",
  ] as const;

  return useQuery(queryKey(["customers", "detail", id, { only }]), () =>
    getByIdOnly<Customer>()("customers", id, only),
  );
};

export const useEditCustomer = (id: number | string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: EditCustomer) => putById<EditCustomer, Customer>("customers", id, data),
    onSuccess: (data) => queryClient.setQueriesData(queryKey(["customers", "detail", id]), data),
  });
};

import { AppealsProcessType, appealsProcessLables } from "@/types/property";
import { Box, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { CheckCircle as CheckIcon } from "mdi-material-ui";
import { Styles } from "@halftax/ui";

export enum ItemState {
  Active = 1,
  Progress = 2,
  NotStarted = 3,
}

type Props = {
  step: AppealsProcessType;
  status: AppealsProcessType;
};

export const getState = (state: number, status: AppealsProcessType): ItemState => {
  if (state === status) {
    return ItemState.Progress;
  } else if (state < status) {
    return ItemState.Active;
  }
  return ItemState.NotStarted;
};

export const AppealsProcessItem: FC<Props> = ({ step, status }) => {
  const statusStep: AppealsProcessType = step + 1;
  const currentState = getState(statusStep, status);

  const styles: Styles = {
    circleIcon: {
      borderRadius: "50%",
      width: 24,
      height: 24,
      fontSize: 14,
      textAlign: "center",
    },
    progressCircleIcon: (theme) => ({
      bgcolor: theme.palette.primary.main,
      color: theme.palette.common.black,
    }),
    notStartedCircleIcon: (theme) => ({
      bgcolor: theme.palette.text.disabled,
      color: theme.palette.common.white,
    }),
    label: {
      textAlign: "left",
      mt: 2,
    },
    icon: {
      justifyContent: "start",
      display: "flex",
    },
    wrapper: {
      flexDirection: "column",
      justifyContent: "center",
    },
  };

  const getIcon = (state: ItemState, step: AppealsProcessType) => {
    if (state === ItemState.Progress || state === ItemState.NotStarted) {
      return (
        <Box
          sx={[
            styles.circleIcon,
            state === ItemState.Progress && styles.progressCircleIcon,
            state === ItemState.NotStarted && styles.notStartedCircleIcon,
          ]}
        >
          {statusStep}
        </Box>
      );
    }
    return <CheckIcon />;
  };

  return (
    <Grid container sx={styles.wrapper}>
      <Grid item sx={styles.icon}>
        {getIcon(currentState, step)}
      </Grid>
      <Grid item>
        <Typography
          variant="subtitle2"
          sx={[
            styles.label,
            currentState === ItemState.NotStarted && {
              color: (theme) => theme.palette.text.disabled,
            },
          ]}
        >
          {appealsProcessLables[statusStep]}
        </Typography>
      </Grid>
    </Grid>
  );
};

import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import {
  ColumnDef,
  ColumnHelper,
  RowData,
  TableOptions,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { MenuDown as ShowMoreIcon, MenuUp as ShowLessIcon } from "mdi-material-ui";
import { Styles } from "@halftax/ui";
import EmptyTabMessage, { EmptyTabMessageProps } from "@/components/common/EmptyTabMessage";
import { useEffect, useState } from "react";

export interface SmallTableOptions<T extends RowData> extends TableOptions<T> {
  placeholder: EmptyTabMessageProps;
}

export interface SmallTableProps<T extends RowData> {
  data: T[];
  rowsToDisplay?: number;

  // Note that column values (info.getValue()) will be typed as any if columns prop is defined inline on the component
  // https://github.com/TanStack/table/issues/4302
  columns: ((columnHelper: ColumnHelper<T>) => ColumnDef<T, any>[]) | ColumnDef<T, any>[];
  options?: Partial<SmallTableOptions<T>>;
}

const SmallTable = <T extends RowData>({
  data,
  columns,
  options,
  rowsToDisplay = 5,
}: SmallTableProps<T>) => {
  const [displayFullTable, setDisplayFullTable] = useState(false);
  const columnHelper = createColumnHelper<T>();
  const tableColumns = typeof columns === "function" ? columns(columnHelper) : columns;
  const table = useReactTable({
    data: data ?? [],
    getPaginationRowModel: getPaginationRowModel(),
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    ...options,
  });

  useEffect(() => {
    table.setPageSize(rowsToDisplay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleShowMore = () => {
    displayFullTable ? table.setPageSize(rowsToDisplay) : table.resetPageSize();
    setDisplayFullTable((prev) => !prev);
  };

  const styles: Styles = {
    table: (theme) => ({
      thead: {
        ...theme.typography.subtitle2,
      },
      tbody: {
        ...theme.typography.body2,
      },
    }),
    tableFooter: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
  };

  return (
    <Box>
      <TableContainer sx={styles.tableContainer}>
        <Table sx={styles.table}>
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableCell
                    key={header.id}
                    colSpan={header.colSpan}
                    sx={{ width: header.getSize() }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          {table.getRowModel().rows.length ? (
            <TableBody>
              {table.getRowModel().rows.map((row) => (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} sx={{ width: cell.column.getSize() }}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          ) : null}
        </Table>
      </TableContainer>

      <Box sx={styles.tableFooter}>
        {!table.getRowModel().rows.length && options?.placeholder ? (
          <Box sx={{ mt: -2 }}>
            <EmptyTabMessage {...options.placeholder} />
          </Box>
        ) : (
          <>
            {(table.getPageCount() > 1 || displayFullTable) && (
              <Tooltip title={!displayFullTable ? "Show More" : "Show Less"} sx={{ mb: 1.5 }}>
                <IconButton onClick={toggleShowMore}>
                  {!displayFullTable ? <ShowMoreIcon /> : <ShowLessIcon />}
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default SmallTable;

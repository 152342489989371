import { Box, Grid, IconButton, Tab as MuiTab } from "@mui/material";
import { FC, useState } from "react";
import { ArrowRight as NextTabIcon, ArrowLeft as PreviousTabIcon } from "mdi-material-ui";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { TabGroupProps, TabGroupTab } from "@/components/common/TabGroup";

import { Styles } from "@halftax/ui";

export type TabMenuProps = TabGroupProps<
  Pick<TabGroupTab, "label" | "content" | "actions" | "additionalInfo">
>;
const tabKey = (index: number) => `tab_${index}`;

const TabMenu: FC<TabMenuProps> = ({ tabs, customNavigation }) => {
  const [value, setValue] = useState<string>(tabKey(0));

  const isTabExist = () => Boolean(tabs.filter((item, index) => tabKey(index) === value).length);

  const tabValueGuard = () => {
    if (!isTabExist()) {
      setValue(tabKey(0));
    }
    return value;
  };

  const styles: Styles = {
    root: {
      pl: 3,
    },
    list: (theme) => ({
      px: 0,
      py: 1,
      border: `1px solid ${theme.palette.other.border}`,
      borderRadius: "0.5rem",
      ...theme.typography.subtitle1,

      minWidth: "12.25rem",

      ".MuiTabs-indicator": {
        borderLeft: `4px solid ${theme.palette.primary.dark}`,
        left: 0,
      },
    }),
    tab: (theme) => ({
      py: 2,
      pl: 3,
      textTransform: "capitalize",
      color: theme.palette.text.primary,
      alignItems: "flex-start",
      textAlign: "left",

      "&.Mui-selected": {
        bgcolor: theme.palette.primary.lightest,
        color: theme.palette.text.primary,
      },
    }),
    panel: {
      px: 10,
    },
    navButtonWrapper: {
      display: "flex",
      justifyContent: "space-between",
      mt: 6,
    },
    navButton: (theme) => ({
      bgcolor: theme.palette.primary.light,
      borderRadius: "0.5rem",

      "&:hover": {
        bgcolor: theme.palette.primary.main,
      },

      width: "2.5rem",
      height: "2.5rem",
    }),
  };

  return (
    <TabContext value={tabValueGuard()}>
      <Grid container sx={styles.root}>
        <Grid item xs={3}>
          <Box sx={styles.list}>
            <TabList onChange={(_event, newValue) => setValue(newValue)} orientation="vertical">
              {tabs.map(({ label, actions, additionalInfo }, index) => (
                <MuiTab
                  component={Box}
                  label={
                    <Grid container justifyContent="space-between">
                      <Grid
                        item
                        xs={8}
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {label}
                      </Grid>
                      {value === tabKey(index) && (
                        <Grid item xs={2} sx={{ position: "relative" }}>
                          {actions}
                        </Grid>
                      )}
                      {additionalInfo && (
                        <Grid item xs={2} sx={{ position: "relative" }}>
                          {additionalInfo}
                        </Grid>
                      )}
                    </Grid>
                  }
                  value={tabKey(index)}
                  key={tabKey(index)}
                  sx={styles.tab}
                />
              ))}
            </TabList>
            {customNavigation}
          </Box>
        </Grid>

        <Grid item xs={9}>
          {tabs.map(({ content }, index) => (
            <TabPanel value={tabKey(index)} key={tabKey(index)} sx={styles.panel}>
              {content}

              <Box sx={styles.navButtonWrapper}>
                <Box>
                  <IconButton
                    sx={[styles.navButton, index === 0 && { display: "none" }]}
                    onClick={() => index !== 0 && setValue(tabKey(index - 1))}
                  >
                    <PreviousTabIcon />
                  </IconButton>
                </Box>
                <Box>
                  <IconButton
                    sx={[styles.navButton, index === tabs.length - 1 && { display: "none" }]}
                    onClick={() => index !== tabs.length - 1 && setValue(tabKey(index + 1))}
                  >
                    <NextTabIcon />
                  </IconButton>
                </Box>
              </Box>
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </TabContext>
  );
};

export default TabMenu;

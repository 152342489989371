import { Grid, IconButton } from "@mui/material";

import AppealsTab from "@/components/common/AppealsTab";
import AssessmentsTab from "@/components/common/AssessmentsTab";
import { PencilOutline as EditIcon, LinkVariantPlus as PinLinkIcon } from "mdi-material-ui";
import { FC } from "react";
import { useProspectEditDialog } from "@/components/prospect/ProspectEditDialog";
import ProspectTab from "@/components/prospect/ProspectTab";
import TabPanel from "@/components/common/TabPanel";
import { useGetProperty } from "@/api/property";
import { useAppealResultsDialog } from "@/components/common/dialogs/appealResults";
import { usePinLinkDialog } from "@/components/common/dialogs/pinLink";
import { InfoTilesList } from "@/components/common/infoTiles";
import { infoTiles } from "@/mockData/property";
import { displayCurrency } from "@/utils/display";
import { AppealsProcess } from "@/components/common/appealsProcess";
import { AppealResults } from "@/components/common/AppealResults";
import { useAppealProcess } from "@/components/common/dialogs/appealProcess";
import PropertySummaryTab from "@/components/property/PropertySummaryTab";

type Props = {
  propertyId: number;
  displayAppealsProcess?: boolean;
  displayInfoTiles?: boolean;
  disableAppealsResultAfter?: boolean;
};

export const PropertyTabsContent: FC<Props> = ({
  propertyId,
  displayAppealsProcess,
  displayInfoTiles,
  disableAppealsResultAfter,
}) => {
  const { data, isSuccess } = useGetProperty(propertyId);

  const { openAppealResult } = useAppealResultsDialog();
  const { openPinLinkDialog } = usePinLinkDialog();
  const { openProspectEditDialog } = useProspectEditDialog();
  const { openAppealProcess } = useAppealProcess();

  const tabs = {
    appeals: {
      label: "Appeals",
      content: isSuccess ? <AppealsTab {...data.main_prospect.county} /> : <AppealsTab skeleton />,
    },
    assessments: {
      label: "Assessments",
      content: isSuccess ? (
        <AssessmentsTab {...{ ...data.main_prospect.county, ...data.main_prospect }} />
      ) : (
        <AssessmentsTab skeleton />
      ),
    },
    propertySummary: {
      label: "Main info",
      content: <PropertySummaryTab propertyId={propertyId} />,
      buttons: (
        <IconButton onClick={() => openPinLinkDialog(propertyId)}>
          <PinLinkIcon />
        </IconButton>
      ),
    },
    properties: data?.prospects?.map((prospect, index) => ({
      label: `PIN ${prospect.pin}`,
      content: <ProspectTab prospectId={prospect.id} />,
      buttons: (
        <>
          <IconButton onClick={() => openPinLinkDialog(propertyId)}>
            <PinLinkIcon />
          </IconButton>
          <IconButton onClick={() => openProspectEditDialog(prospect.id)}>
            <EditIcon />
          </IconButton>
        </>
      ),
      id: index.toString(),
    })),
    appealProcess: {
      label: "Appeal Process",
      content: <AppealsProcess status={1} />,
      buttons: (
        <IconButton onClick={openAppealProcess}>
          <EditIcon />
        </IconButton>
      ),
    },
    appealResults: {
      label: "Appeal Results",
      content: <AppealResults />,
      buttons: (
        <IconButton onClick={() => openAppealResult(disableAppealsResultAfter)}>
          <EditIcon />
        </IconButton>
      ),
    },
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TabPanel tabs={[tabs.propertySummary, ...(tabs.properties ?? [])]} />
      </Grid>

      {displayInfoTiles && (
        <Grid item xs={12}>
          <InfoTilesList list={infoTiles} valueProcessor={displayCurrency} />
        </Grid>
      )}

      <Grid item xs={12} lg={6}>
        <TabPanel tabs={[tabs.appeals]} />
      </Grid>

      <Grid item xs={12} lg={6}>
        <TabPanel tabs={[tabs.assessments]} />
      </Grid>

      {displayAppealsProcess && (
        <Grid item xs={12}>
          <TabPanel tabs={[tabs.appealProcess]} />
        </Grid>
      )}

      <Grid item xs={12}>
        <TabPanel tabs={[tabs.appealResults]} />
      </Grid>
    </Grid>
  );
};

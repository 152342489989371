import { useCallback, useState } from "react";

import { LinkAdd } from "@/components/common/dialogs/linkAdd/LinkAdd";
import { useModal } from "@halftax/ui";

export const useLinkAddDialog = () => {
  const [countyId, setCountyId] = useState<number>();

  const { showModal } = useModal(
    ({ hideModal }) => !!countyId && <LinkAdd onExited={hideModal} countyId={countyId} />,
    [countyId],
  );

  const openLinkAddDialog = useCallback(
    (countyId: number) => {
      setCountyId(countyId);
      showModal();
    },
    [showModal],
  );

  return { openLinkAddDialog };
};

import { getByIdOnly, postByUrl, queryKey } from "@/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Property } from "@/types/property";
import invariant from "tiny-invariant";

export const useGetProperty = (id?: number | string) => {
  const only = [
    "main_prospect.id",
    "main_prospect.pin",
    "main_prospect.county.deadline_appeal_annual",
    "main_prospect.county.link_appeal_form",
    "main_prospect.county.link_property_database",
    "main_prospect.county.assessment_frequency",
    "main_prospect.assessment_next",
    "main_prospect.assessment_last",
    "main_prospect.address",
    "main_prospect.type_property",
    "prospects.id",
    "prospects.pin",
    "prospects.year_built",
    "prospects.year_renovated",
    "prospects.type_zoning",
    "size_land",
    "size_building",
    "last_sale_amount",
    "last_sale_date",
    "lead_id",
  ] as const;

  return useQuery(
    queryKey(["properties", "detail", id, { only }]),
    () => {
      invariant(id, "Query should always have id when enabled");
      return getByIdOnly<Property>()("properties", id, only);
    },
    {
      enabled: !!id,
    },
  );
};

type LinkPins = {
  prospect_ids: number[];
};

export const usePropertyLinkPins = (id: number | string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: LinkPins) => postByUrl<LinkPins, Property>(`properties/${id}/pins`, data),
    onSuccess: (data) => {
      queryClient.setQueriesData(queryKey(["properties", "detail", id]), data);

      if (!!data.lead_id) {
        queryClient.invalidateQueries({
          queryKey: queryKey(["leads", "detail", data.lead_id]),
        });
      }
    },
  });
};

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Field, Form, FormikProvider, useFormik } from "formik";

import { Magnify as SearchIcon } from "mdi-material-ui";
import { FC, useEffect, useState } from "react";
import { TextField } from "formik-mui";
import { propertyAddSchema } from "@/validation";
import { PropertyList } from "@/components/common/dialogs/addProperty";
import { useDebounce } from "use-debounce";
import { useQuery } from "@tanstack/react-query";
import { queryKey, search } from "@/api";
import { Property } from "@/types/property";
import Dialog, { DialogProps } from "@/components/common/Dialog";

const DEFAULT_PAGE_SIZE = 10;

export interface AddPropertyProps extends DialogProps {
  leadId: number;
}

export const AddProperty: FC<AddPropertyProps> = ({ leadId, ...rest }) => {
  const [open, setOpen] = useState(true);
  const [inputValue, setInputValue] = useState<string>("");
  const [page, setPage] = useState(0);

  const [properties, setProperties] = useState<Pick<Property, "id" | "main_prospect">[]>([]);
  const formik = useFormik({
    validationSchema: propertyAddSchema,
    initialValues: propertyAddSchema.cast({}),
    onSubmit: (values, { setSubmitting }) => {
      // TODO(HT-I80): mutate lead with new property
      // https://sprints.zoho.eu/team/10speed#itemdetails/P6/I80
      setOpen(false);
      setSubmitting(false);
    },
  });

  useEffect(() => {
    setPage(0);
    setProperties([]);
  }, [inputValue]);

  const [searchQuery] = useDebounce(inputValue, 250);

  const { refetch, isFetching } = useQuery(
    queryKey(["properties", "list", { q: searchQuery }]),
    () => {
      const isFirstFetch = !properties.length;
      const result = search<Property>()({
        entity: "properties",
        only: ["id", "main_prospect"],
        by: ["main_prospect.address", "main_prospect.pin"],
        page: page,
        size: isFirstFetch ? 2 : DEFAULT_PAGE_SIZE,
        q: searchQuery,
      });
      setPage((prev) => prev + 1);
      return result;
    },
    {
      cacheTime: 5000,
      onSuccess: (data) => {
        setProperties((prev) => [...prev, ...data]);
      },
    },
  );

  return (
    <Dialog open={open} scroll="body" maxWidth="md" fullWidth closeButton {...rest}>
      <DialogTitle>Add new property</DialogTitle>
      <FormikProvider value={formik}>
        <Form>
          <DialogContent sx={{ pb: 6 }}>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Search property by PIN or Address
            </Typography>
            <Box
              sx={{
                position: "relative",
                "& .MuiInputBase-input": {
                  pl: 4,
                },
              }}
            >
              <Field
                name="pin"
                component={TextField}
                InputProps={{
                  startAdornment: (
                    <SearchIcon
                      sx={{
                        color: (theme) => theme.palette.text.disabled,
                        position: "absolute",
                        left: 12,
                        top: 8,
                      }}
                    />
                  ),
                }}
                value={inputValue}
                placeholder="Search"
                onChange={(event: any) => {
                  setInputValue(event.target.value);
                }}
                fullWidth
              />
            </Box>
            <Grid container sx={{ justifyContent: "center" }}>
              <Grid item>{!!properties.length && <PropertyList properties={properties} />}</Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  size="medium"
                  disabled={isFetching}
                  onClick={() => refetch()}
                >
                  Show {DEFAULT_PAGE_SIZE}+ properties
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button type="submit" disabled={formik.isSubmitting}>
              Add property
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

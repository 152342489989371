export enum NotificationsType {
  Messages = 0,
  Billing = 1,
  Counties = 2,
  Prospects = 3,
  Leads = 4,
  Customers = 5,
  Templates = 6,
}

export enum NotificationsFilter {
  AllNotifications = "All Notifications",
  Messages = "Messages",
}

export type Notification = {
  title: string;
  description: string;
  date: string;
  type: NotificationsType;
};

import { useGetLead } from "@/api/lead";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import invariant from "tiny-invariant";
import TabPanel from "@/components/common/TabPanel";
import { Grid, IconButton } from "@mui/material";
import { Plus as AddIcon, PencilOutline as EditIcon } from "mdi-material-ui";
import { useStatusDialog } from "@/components/common/dialogs/status";
import { useContactEditDialog } from "@/components/common/dialogs/contactEdit";
import { StatusTab } from "@/components/common/status";
import { PropertyTab } from "@/components/property";
import LatestActivity from "@/components/common/LatestActivity";
import GalleryTab from "@/components/common/GalleryTab";
import MessagesTab from "@/components/common/MessagesTab";
import GeneralTab from "@/components/common/GeneralTab";
import { useHeader } from "@/hooks/useHeader";
import DocumentsTab from "@/components/document/DocumentsTab";
import { useDocumentsUploadDialog } from "@/components/document/DocumentsUploadDialog";

export const LeadDetails: FC = () => {
  const { leadId: id } = useParams();
  invariant(id, "Missing URL param for lead ID!");
  const leadId = Number(id);

  const { data, isSuccess } = useGetLead(leadId);
  const [propertyIndex, setPropertyIndex] = useState(0);

  const { openDocumentsUploadDialog } = useDocumentsUploadDialog();
  const { openStatus } = useStatusDialog();
  const { openContactEdit } = useContactEditDialog("leads", leadId);

  useHeader({
    title: `${data?.owner?.name} Properties`,
    backRoute: "/leads",
    enabled: isSuccess,
  });

  const tabs = {
    contact: {
      label: "General",
      content: <GeneralTab entityId={leadId} entityType="leads" />,
      buttons: (
        <IconButton onClick={openContactEdit}>
          <EditIcon />
        </IconButton>
      ),
    },
    messages: {
      label: "Messages",
      content: <MessagesTab />,
    },
    status: {
      label: "Status",
      content: isSuccess && <StatusTab status={data.status} />,
      buttons: (
        <IconButton onClick={openStatus}>
          <EditIcon />
        </IconButton>
      ),
    },
    documents: {
      label: "Documents",
      content: <DocumentsTab entityType="leads" entityId={leadId} />,
      buttons: (
        <IconButton onClick={() => openDocumentsUploadDialog("leads", leadId)}>
          <AddIcon />
        </IconButton>
      ),
    },
    propertyDocuments: [
      {
        label: "All documents",
        content: data && (
          <DocumentsTab entityId={data?.properties[propertyIndex].id} entityType="properties" />
        ),
      },
      ...(data?.properties[propertyIndex].prospects.length
        ? data?.properties[propertyIndex].prospects.map((prospect) => ({
            label: `PIN ${prospect.pin}`,
            content: <DocumentsTab entityId={prospect.id} entityType="prospects" />,
            buttons: (
              <IconButton onClick={() => openDocumentsUploadDialog("prospects", prospect.id)}>
                <AddIcon />
              </IconButton>
            ),
          }))
        : []),
    ],
    gallery: {
      label: "Gallery",
      content: <GalleryTab />,
    },
    latestActivity: {
      label: "Latest Activity",
      content: <LatestActivity />,
    },
  };

  return (
    <Grid container columnSpacing={3} rowSpacing={4} sx={{ p: 3, flexGrow: 1 }}>
      <Grid item xs={12}>
        <TabPanel tabs={[tabs.contact, tabs.messages, tabs.documents, tabs.status]} />
      </Grid>

      <Grid item xs={12}>
        {isSuccess && (
          <PropertyTab
            leadId={leadId}
            setPropertyIndex={setPropertyIndex}
            disableAppealsResultAfter
            {...data}
          />
        )}
      </Grid>

      <Grid item xs={12}>
        {!!tabs.propertyDocuments?.length && <TabPanel tabs={tabs.propertyDocuments} />}
      </Grid>

      <Grid item xs={12}>
        <TabPanel tabs={[tabs.gallery]} />
      </Grid>

      <Grid item xs={12}>
        <TabPanel tabs={[tabs.latestActivity]} />
      </Grid>
    </Grid>
  );
};

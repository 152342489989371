import { queryKey, search } from "@/api";
import {
  AutocompleteRenderGetTagProps,
  Box,
  Grid,
  TextField as MuiTextField,
  TextFieldProps,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Autocomplete, AutocompleteProps as MuiAutocompleteProps } from "formik-mui";
import { useState } from "react";
import { useDebounce } from "use-debounce";
import { OnlyPick } from "@/types/util";
import { displayAddress } from "@/utils/display";
import { PinLinksList } from "@/components/common/dialogs/pinLink/PinLinksList";
import { Prospect } from "@/types/prospect";

type AutocompleteProps = Omit<
  MuiAutocompleteProps<OnlyPick<Prospect, "owner.address" | "id" | "pin">, true, false, true>,
  "values" | "options" | "renderInput"
>;

export interface SearchAutocompleteProps extends AutocompleteProps {}

const PinSearchAutosuggestion = ({ ...rest }: SearchAutocompleteProps) => {
  const { isSubmitting } = rest.form;
  const [inputValue, setInputValue] = useState<string>();
  const [options, setOptions] = useState<OnlyPick<Prospect, "owner.address" | "id" | "pin">[]>([]);

  const [searchQuery] = useDebounce(inputValue, 250);

  useQuery(
    queryKey(["prospects", "list", { q: searchQuery }]),
    () =>
      search<Prospect>()({
        entity: "prospects",
        only: ["owner.address", "id", "pin"],
        by: ["owner.address", "pin"],
        page: 0,
        size: 10,
        q: searchQuery,
      }),
    {
      cacheTime: 5000,
      onSuccess: (data) => setOptions(data),
    },
  );

  return (
    <Autocomplete
      multiple
      freeSolo
      autoHighlight
      autoComplete
      getOptionLabel={(data) => (typeof data === "string" ? data : data?.pin)}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <Grid
            container
            justifyContent="space-between"
            sx={{
              textAlign: "left",
            }}
          >
            <Grid item xs={5}>
              {option?.pin}
            </Grid>
            <Grid
              item
              sx={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              xs={7}
            >
              {displayAddress(option?.owner?.address)}
            </Grid>
          </Grid>
        </Box>
      )}
      options={options}
      filterOptions={(x) => x}
      filterSelectedOptions
      placeholder="enter PIN or address"
      onInputChange={(_event, newInputValue) => setInputValue(newInputValue)}
      disabled={isSubmitting}
      renderInput={(params: TextFieldProps) => (
        <MuiTextField placeholder="enter PIN or address" {...params} />
      )}
      renderTags={(value, getTagProps: AutocompleteRenderGetTagProps) => (
        <Box
          sx={{
            position: "absolute",
            top: "100%",
            left: 0,
            width: "100%",
          }}
        >
          <PinLinksList pinLinkList={value} getTagProps={getTagProps} isSubmitting={isSubmitting} />
        </Box>
      )}
      {...rest}
    />
  );
};

export default PinSearchAutosuggestion;

import { useAddPropertyDialog } from "@/components/common/dialogs/addProperty";
import { Styles } from "@halftax/ui";
import { Avatar, Button, Grid, Typography } from "@mui/material";
import { HomePlusOutline as PropertyAddIcon } from "mdi-material-ui";

import { FC } from "react";

export interface PropertyAddButtonProps {
  leadId: number;
}

const PropertyAddButton: FC<PropertyAddButtonProps> = ({ leadId }) => {
  const { openAddProperty } = useAddPropertyDialog();

  const styles: Styles = {
    propertyAddBtn: (theme) => ({
      height: 1,
      width: 1,
      py: 2,
      bgcolor: theme.palette.common.white,
      color: theme.palette.common.black,
      borderRadius: "0.5rem",

      "&:hover": {
        bgcolor: theme.palette.common.white,
      },
    }),
    propertyAddIcon: (theme) => ({
      height: 64,
      width: 64,
      bgcolor: theme.palette.primary.main,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.other.border,
      color: theme.palette.common.black,
      position: "relative",

      "& .MuiSvgIcon-root": {
        width: 40,
        height: 40,
      },
    }),
  };

  return (
    <Button sx={styles.propertyAddBtn} onClick={() => openAddProperty(leadId)}>
      <Grid
        container
        sx={{
          height: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item>
          <Grid container direction="column" rowSpacing={2}>
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Avatar sx={styles.propertyAddIcon}>
                <PropertyAddIcon />
              </Avatar>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">Add new property</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Button>
  );
};

export default PropertyAddButton;

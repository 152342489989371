import { CreateLead, EditLead, Lead } from "@/types/lead";
import { getByIdOnly, post, putById, queryKey } from "@/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const useGetLead = (id: number | string) => {
  const only = [
    "id",
    "status",
    "contacts",
    "owner",
    "properties.id",
    "properties.main_prospect.id",
    "properties.main_prospect.address",
    "properties.main_prospect.type_property",
    "properties.prospects.id",
    "properties.prospects.pin",
  ] as const;

  return useQuery(queryKey(["leads", "detail", id, { only }]), () =>
    getByIdOnly<Lead>()("leads", id, only),
  );
};

export const useEditLead = (id: number | string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: EditLead) => putById<EditLead, Lead>("leads", id, data),
    onSuccess: (data) => queryClient.setQueriesData(queryKey(["leads", "detail", id]), data),
  });
};

export const useCreateLead = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreateLead) => post<CreateLead, Lead>("leads", data),
    onSuccess: (data) => queryClient.setQueriesData(queryKey(["leads", "detail", data.id]), data),
  });
};

import { AttachmentEntityType, AttachmentType } from "@/types/attachment";
import {
  TrashCanOutline as DeleteIcon,
  FileDocument as DocumentIcon,
  DownloadOutline as DownloadIcon,
} from "mdi-material-ui";
import { IconButton, Stack } from "@mui/material";
import { getDownloadURL, useDeleteAttachment, useGetAttachments } from "@/api/attachment";

import { FC } from "react";
import SmallTable from "@/components/common/SmallTable";
import { displayDate } from "@/utils/display";
import { useConfirmationDialog } from "@/components/common/ConfirmationDialog";

export interface DocumentsTabProps {
  entityType: AttachmentEntityType;
  entityId: number;
}

const DocumentsTab: FC<DocumentsTabProps> = ({ entityType, entityId }) => {
  const { data } = useGetAttachments({
    entity_id: entityId,
    entity_type: entityType,
    type: AttachmentType.Document,
  });

  const { mutate: deleteAttachment } = useDeleteAttachment();
  const { confirm: confirmDelete } = useConfirmationDialog({
    title: "Delete Document",
    description: "Are you sure you want to delete this document?",
    confirmLabel: "Delete",
  });

  const downloadDocument = (uploadKey: string) =>
    getDownloadURL(uploadKey).then(({ url }) => window.open(url, "_blank"));

  return (
    <SmallTable
      data={data ?? []}
      options={{
        placeholder: {
          icon: <DocumentIcon />,
          title: "No documents yet",
          description: "Once documents are added, they will be displayed here",
        },
      }}
      columns={(col) => [
        col.accessor("filename", {
          header: "Name",
          size: 140,
        }),
        col.accessor("description", {
          header: "Description",
        }),
        col.accessor("created_by", {
          header: "Uploaded by",
          cell: (info) => info.getValue() ?? "Unknown",
        }),
        col.accessor("created_at", {
          header: "Date",
          cell: (info) => displayDate({ ISOdate: info.getValue() }),
        }),
        col.display({
          id: "actions",
          size: 32,
          maxSize: 32,

          cell: (info) => (
            <Stack spacing={0.5} direction="row" sx={{ my: -1 }}>
              <IconButton
                size="small"
                sx={{ ml: "auto" }}
                disabled={!info.row.original.upload_key}
                onClick={() => downloadDocument(info.row.original.upload_key ?? "")}
              >
                <DownloadIcon fontSize="small" />
              </IconButton>
              <IconButton
                onClick={() =>
                  confirmDelete(() =>
                    deleteAttachment({
                      id: info.row.original.id,
                      entity_id: entityId,
                      entity_type: entityType,
                      type: AttachmentType.Document,
                    }),
                  )
                }
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Stack>
          ),
        }),
      ]}
    />
  );
};

export default DocumentsTab;

import { Address } from "@/types/address";

export enum ContactType {
  General = 1,
  Owner = 2,
  Office = 3,
}

export interface Contact {
  id: number;
  name: string;
  type: ContactType;
  description?: string;
  email?: string;
  phone?: string;
  website?: string;
  address?: Address;
}

export interface EditContact extends Omit<Contact, "id" | "type" | "address"> {
  id?: number;
  type: number;
  address?: Partial<Omit<Address, "id">> | null;
}

export interface EditOwnerContact extends Omit<Contact, "id" | "type" | "address" | "description"> {
  type: number;
  address?: Partial<Omit<Address, "id">> | null;
}

export interface ContactOwner extends Omit<Contact, "description"> {
  address: Address;
}

export interface AddContactOwner extends Omit<Contact, "id" | "description"> {
  address: Address;
}

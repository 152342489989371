import { StatusType, statusTypeLabels } from "@/types/status";
import { Box, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { CheckCircle as CheckIcon } from "mdi-material-ui";
import { Styles } from "@halftax/ui";

export enum ItemState {
  Active = 1,
  Progress = 2,
  NotStarted = 3,
}

type Props = {
  step: StatusType;
  status: StatusType;
};

export const getState = (state: number, status: StatusType): ItemState => {
  if (state === status) {
    return ItemState.Progress;
  } else if (state < status) {
    return ItemState.Active;
  }
  return ItemState.NotStarted;
};

export const StatusItem: FC<Props> = ({ step, status }) => {
  const statusStep: StatusType = step + 1;
  const currentState = getState(statusStep, status);

  const styles: Styles = {
    circleIcon: {
      borderRadius: "50%",
      width: 24,
      height: 24,
      fontSize: 14,
      textAlign: "center",
    },
    progressCircleIcon: (theme) => ({
      bgcolor: theme.palette.primary.main,
      color: theme.palette.common.black,
    }),
    notStartedCircleIcon: (theme) => ({
      bgcolor: theme.palette.text.disabled,
      color: theme.palette.common.white,
    }),
    text: {
      fontWeight: 500,
    },
  };

  const getIcon = (state: ItemState, step: StatusType) => {
    if (state === ItemState.Progress || state === ItemState.NotStarted) {
      return (
        <Box
          sx={[
            styles.circleIcon,
            state === ItemState.Progress && styles.progressCircleIcon,
            state === ItemState.NotStarted && styles.notStartedCircleIcon,
          ]}
        >
          {statusStep}
        </Box>
      );
    }
    return <CheckIcon />;
  };

  return (
    <Grid
      container
      sx={{
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Grid
        item
        sx={{
          justifyContent: "center",
          display: "flex",
        }}
      >
        {getIcon(currentState, step)}
      </Grid>
      <Grid item>
        <Typography
          variant="subtitle2"
          sx={[
            {
              textAlign: "center",
              mt: 2,
            },
            currentState === ItemState.NotStarted && {
              color: (theme) => theme.palette.text.disabled,
            },
          ]}
        >
          {statusTypeLabels[statusStep]}
        </Typography>
      </Grid>
    </Grid>
  );
};

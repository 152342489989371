import { useEffect } from "react";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { useLocation } from "react-router-dom";
import { sidebarItems } from "@/components/Sidebar";

export interface HeaderState {
  title?: string;
  backRoute?: string;
}

export interface UseHeader extends HeaderState {
  enabled?: boolean;
}

export const headerAtom = atom<HeaderState>({});

export const setWindowTitle = (windowTitle?: string) => {
  document.title = windowTitle ? `${windowTitle} - Halftax` : "Halftax";
};

export const useHeader = ({ title, backRoute, enabled = true }: UseHeader) => {
  const setHeader = useSetAtom(headerAtom);

  useEffect(() => {
    if (enabled) {
      setHeader({ title, backRoute });
      setWindowTitle(title);
    } else {
      setHeader({});
      setWindowTitle();
    }

    // on unmount
    return () => {
      setHeader({});
      setWindowTitle();
    };
  }, [backRoute, title, enabled, setHeader]);
};

export const useDefaultHeader = () => {
  const { pathname } = useLocation();
  const header = useAtomValue(headerAtom);
  const { title } = header;

  const routeLabels = Object.fromEntries(sidebarItems.map((item) => [item.path, item.label]));

  useEffect(() => {
    if (!title) {
      setWindowTitle(routeLabels?.[pathname]);
    }
  }, [pathname, routeLabels, title]);

  return title ? header : { title: routeLabels?.[pathname] };
};

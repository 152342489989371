import { FC } from "react";
import { useGetProspect } from "@/api/prospect";
import { useGetProperty } from "@/api/property";
import ProspectTabContent from "@/components/prospect/ProspectTabContent";

type Props = {
  propertyId?: number;
};

const PropertySummaryTab: FC<Props> = ({ propertyId }) => {
  const { data: property, isSuccess: isSuccessProperty } = useGetProperty(propertyId);
  const { data: prospect, isSuccess: isSuccessProspect } = useGetProspect(
    property?.main_prospect?.id,
  );

  return isSuccessProperty && isSuccessProspect ? (
    <ProspectTabContent
      {...prospect}
      pin={property.prospects.map((prospect) => prospect.pin)}
      size_land={property.size_land}
      size_building={property.size_building}
      last_sale_amount={property.last_sale_amount}
      last_sale_date={property.last_sale_date}
      year_built={property.prospects.map((prospect) => prospect.year_built)}
      year_renovated={property.prospects.map((prospect) => prospect.year_renovated)}
    />
  ) : (
    <ProspectTabContent skeleton />
  );
};

export default PropertySummaryTab;

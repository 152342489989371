import { useEditLead, useGetLead } from "@/api/lead";
import { useEditCustomer, useGetCustomer } from "@/api/customer";
import { useEditProspect, useGetProspect } from "@/api/prospect";
import { EntityType } from "@/types/common";
import { useEditCounty, useGetCounty } from "@/api/county";

export type Entities = Extract<EntityType, "prospects" | "leads" | "customers" | "counties">;

export const useGetEntity = {
  prospects: useGetProspect,
  leads: useGetLead,
  customers: useGetCustomer,
  counties: useGetCounty,
} satisfies { [E in Entities]: Function };

export const useEditEntity = {
  prospects: useEditProspect,
  leads: useEditLead,
  customers: useEditCustomer,
  counties: useEditCounty,
} satisfies { [E in Entities]: Function };

import { Direction } from "@/components/dashboard/types";
import { Theme } from "@mui/material";

export const getColorByDirection = (direction: Direction, theme: Theme) => {
  switch (direction) {
    case Direction.Up:
      return theme.palette.success.light;
    case Direction.Down:
      return theme.palette.error.light;
  }
};

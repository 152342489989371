import { FC } from "react";
import { Notification } from "@/components/common/dialogs/notifications/types";
import { Grid } from "@mui/material";
import { NotificationsGroup } from "@/components/common/dialogs/notifications";
import { displayDateTitle } from "@/utils/display";

type Props = {
  notifications: Notification[];
};

export const NotificationsList: FC<Props> = ({ notifications }) => {
  const groupedNotifications = notifications.reduce<{ [key: string]: Notification[] }>(
    (notificationGroups, notificationItem) => {
      const dateKey = displayDateTitle(notificationItem.date);
      notificationGroups[dateKey] = [...(notificationGroups[dateKey] || []), notificationItem];
      return notificationGroups;
    },
    {},
  );

  return (
    <Grid
      container
      direction="column"
      sx={{
        width: 1,
      }}
    >
      {Object.values(groupedNotifications).map((group, index) => (
        <Grid
          item
          key={index}
          sx={{
            width: 1,
          }}
        >
          <NotificationsGroup notifications={group} />
        </Grid>
      ))}
    </Grid>
  );
};

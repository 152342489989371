export const dashboard = {
  general: [
    {
      label: "Contracts Signed",
      value: 534,
    },
    {
      label: "Customers",
      value: 456,
    },
    {
      label: "New Prospects",
      value: 243,
    },
    {
      label: "Appointments",
      value: 24,
    },
  ],
  appeals: [
    {
      name: "In Progress",
      value: 54,
    },
    {
      name: "Completed",
      value: 70,
    },
  ],
  revenue: [
    {
      date: "Jun",
      value: 40000,
    },
    {
      date: "Jul",
      value: 23300.1,
    },
    {
      date: "Aug",
      value: 40000,
    },
    {
      date: "Sep",
      value: 23300.1,
    },
    {
      date: "Oct",
      value: 40000,
    },
  ],
  properties: {
    total: 8445,
    states: [
      {
        code: "AL",
        value: 455,
        change: "12%",
        direction: "up",
      },
      {
        code: "DE",
        value: 410,
        change: "20%",
        direction: "up",
      },
      {
        code: "NC",
        value: 388,
        change: "11%",
        direction: "up",
      },
      {
        code: "SD",
        value: 252,
        change: "16%",
        direction: "up",
      },
      {
        code: "VA",
        value: 138,
        change: "10%",
        direction: "down",
      },
    ],
  },
  reminder: [
    {
      date: "07/10/22",
      time: "8:30-9:30",
      description: "Call Thomas | Logical LLC",
    },
    {
      date: "07/10/22",
      time: "11:00-11:30",
      description: "Call Adam | Douglas LLC",
    },
  ],
};

import { Box, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { prospectPropertyTypeIcons, prospectPropertyTypeLabels } from "@/types/prospect";
import { displayAddress } from "@/utils/display";
import { Styles } from "@halftax/ui";
import { Property } from "@/types/property";

export type PropertyTileType = {
  address: string;
  pin: string;
  place: string;
};

type Props = {
  property: Pick<Property, "id" | "main_prospect">;
  active: boolean;
};

export const PropertyTile: FC<Props> = ({ property, active }) => {
  const { address, pin, county, type_property } = property.main_prospect;

  const styles: Styles = {
    wrapper: {
      border: "2px solid transparent",
      borderRadius: "0.5rem",
      cursor: "pointer",
    },
    gridWrapper: {
      border: (theme) => `1px solid ${theme.palette.other.border}`,
      borderRadius: "0.5rem",
      p: 2,
    },
    iconWrapper: {
      width: 120,
      height: 1,
      backgroundColor: (theme) => theme.palette.primary.lightest,
      borderRadius: "0.5rem",
      color: (theme) => theme.palette.primary.dark,
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",

      "& .MuiSvgIcon-root": {
        fontSize: 35,
      },
    },
    text: {
      mb: 1,
      lineHeight: 2,
    },
  };

  return (
    <Box
      sx={[
        styles.wrapper,
        active && {
          borderColor: (theme) => theme.palette.primary.main,
        },
      ]}
    >
      <Grid
        container
        direction="row"
        sx={[
          styles.gridWrapper,
          active && {
            borderColor: "transparent",
          },
        ]}
      >
        <Grid item sx={{ pr: 2 }} xs={3}>
          <Box sx={styles.iconWrapper}>
            {prospectPropertyTypeIcons[type_property]}
            <Typography variant="subtitle1">{prospectPropertyTypeLabels[type_property]}</Typography>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Grid container>
            <Grid item>
              <Typography variant="subtitle1" sx={styles.text}>
                {displayAddress(address)}
              </Typography>
              <Typography variant="subtitle1" sx={styles.text}>
                {pin}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                {county.name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

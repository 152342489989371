import CloseDialogButton from "@/components/common/CloseDialogButton";
import { Dialog as MuiDialog, DialogProps as MuiDialogProps } from "@mui/material";
import { FC, useEffect, useState } from "react";

export interface DialogProps extends Omit<MuiDialogProps, "open"> {
  open?: MuiDialogProps["open"];
  closeButton?: boolean;

  /**
   * Callback to be called when the Dialog is fully closed i. e. the transition has finished.
   * Should be set to hideModal when used with useModal.
   */
  onExited?: () => void;
}

const Dialog: FC<DialogProps> = ({
  open,
  onExited,
  children,
  closeButton,
  TransitionProps,
  ...rest
}) => {
  const [muiOpen, setMuiOpen] = useState(open ?? true);

  useEffect(() => {
    setMuiOpen(open ?? true);
  }, [open]);

  return (
    <MuiDialog
      open={muiOpen}
      onClose={() => setMuiOpen(false)}
      TransitionProps={{
        ...TransitionProps,
        onExited: () => onExited?.(),
      }}
      {...rest}
    >
      {closeButton && <CloseDialogButton onClick={() => setMuiOpen(false)} />}
      {children}
    </MuiDialog>
  );
};

export default Dialog;

import { AppealsProcessType, appealsProcessLables } from "@/types/property";
import { Box, Grid } from "@mui/material";
import { FC } from "react";
import { getState, AppealsProcessItem, ItemState } from "@/components/common/appealsProcess";
import { Styles } from "@halftax/ui";

type Props = {
  status: AppealsProcessType;
};

export const AppealsProcess: FC<Props> = ({ status }) => {
  const styles: Styles = {
    activeLine: {
      "&::after": {
        content: "''",
        width: "calc(100% - 48px)",
        height: "1px",
        bgcolor: (theme) => theme.palette.secondary.main,
        display: "block",
        position: "absolute",
        right: "calc(100%)",
        top: 12,
      },
    },
    wrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 1,
      minHeight: "9.125rem",
      px: 2,
    },
    item: {
      position: "relative",
      flexBasis: "calc(100% / 7)",
      flexGrow: 0,
      maxWidth: "calc(100% / 7)",
      px: 1,
    },
  };

  return (
    <Box sx={styles.wrapper}>
      <Grid container>
        {Object.keys(appealsProcessLables).map((label, index) => (
          <Grid
            item
            key={index}
            sx={[
              styles.item,
              index > 0 && styles.activeLine,
              getState(index + 1, status) === ItemState.NotStarted && {
                "&::after": {
                  bgcolor: (theme) => theme.palette.text.disabled,
                },
              },
            ]}
          >
            <AppealsProcessItem step={index} status={status} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

import { FC } from "react";
import { Appeal } from "@/components/dashboard/types";
import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell, Legend, Label } from "recharts";
import { Box, Paper, Grid, Typography, useTheme } from "@mui/material";
import { DashboardAppealsLegend } from "@/components/dashboard";

type Props = {
  appeals: Appeal[];
};

export const DashboardAppeals: FC<Props> = ({ appeals }) => {
  const theme = useTheme();
  const total = appeals.reduce((sum, item) => {
    sum += item.value;
    return sum;
  }, 0);
  const colors = [theme.palette.primary.light, theme.palette.primary.main];

  return (
    <Paper variant="outlined">
      <Grid
        sx={{ p: 3, height: "100%" }}
        flexDirection="column"
        justifyContent="space-between"
        container
      >
        <Typography
          variant="h5"
          align="center"
          sx={{
            fontWeight: 500,
          }}
        >
          Appeals
        </Typography>
        <Grid container justifyContent="center">
          <Box
            sx={{
              height: 300,
              width: 400,
            }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  dataKey="value"
                  data={appeals}
                  innerRadius={80}
                  outerRadius={110}
                  startAngle={-270}
                >
                  {appeals.map((entry, index) => (
                    <Cell key={index} fill={colors[index]} />
                  ))}
                  <Label
                    offset={0}
                    position="center"
                    content={
                      <>
                        <text
                          y="124"
                          style={{ fontSize: 24, fontWeight: 500, transform: "translateX(50%)" }}
                          textAnchor="middle"
                        >
                          {total}
                        </text>
                        <text
                          y="152"
                          style={{
                            fontSize: 16,
                            transform: "translateX(calc(50% - 18px))",
                            color: theme.palette.text.secondary,
                          }}
                        >
                          Total
                        </text>
                      </>
                    }
                  />
                </Pie>
                <Tooltip />
                <Legend content={DashboardAppealsLegend} />
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

import { Button, DialogActions, DialogContent } from "@mui/material";
import { ContactType, EditContact, EditOwnerContact } from "@/types/contact";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { FieldArray, Form, FormikProvider, useFormik } from "formik";

import { ContactEditFormFields } from "@/components/common/dialogs/contactEdit";
import { CustomContactEditGroup } from "@/components/common/dialogs/contactEdit/types";
import { SetSubmitting } from "@/components/common/dialogs/contactEdit/ContactEdit";
import { cleanPhone } from "@halftax/ui";
import { contactsEditSchema } from "@/validation";

type Props = {
  contacts: EditContact[];
  ownerContact: EditOwnerContact | null;
  onSave: (
    contacts: EditContact[],
    owner: EditOwnerContact | null,
    setSubmitting: SetSubmitting,
  ) => void;
  setDirtyForm: Dispatch<SetStateAction<boolean>>;
};

export const ContactEditForm: FC<Props> = ({ contacts, ownerContact, onSave, setDirtyForm }) => {
  const [submitTriggered, setSubmitTriggered] = useState(false);

  const initialValues = [...((ownerContact && [ownerContact]) || []), ...contacts].reduce<
    CustomContactEditGroup[]
  >((arr, contact) => {
    if (contact?.type === ContactType.Office || contact?.type === ContactType.Owner) {
      const offileSchem = {
        ...contact,
        email: contact.email || "",
        website: contact.website || "",
        phone: cleanPhone(contact.phone),
        address: contact.address || {
          line1: "",
          line2: "",
          city: "",
          state: "",
          zip: "",
          county: "",
        },
      };
      return [...arr, offileSchem];
    }
    contact.phone = cleanPhone(contact.phone);
    return [...arr, contact];
  }, []);

  const submitHandler = () => {
    setSubmitTriggered(true);
  };

  const formik = useFormik({
    initialValues: { contacts: initialValues },
    validationSchema: contactsEditSchema,
    onSubmit: ({ contacts }, { setSubmitting }) => {
      const regularContacts = contacts
        .filter((item) => item.type !== ContactType.Owner)
        .map((item) => ({
          ...item,
          description: item.description || "",
        }));
      const ownerContact = contacts.filter(({ type }) => type === ContactType.Owner)[0];
      onSave(regularContacts, ownerContact, setSubmitting);
      setSubmitTriggered(false);
    },
  });

  const { errors, isValid, dirty, isSubmitting } = formik;

  useEffect(() => {
    setDirtyForm(dirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirty]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <DialogContent sx={{ px: 0, pb: 0 }}>
          <FieldArray
            name="contacts"
            render={(arrayHelpers) => (
              <ContactEditFormFields
                arrayHelpers={arrayHelpers}
                errors={errors}
                isValid={isValid}
                submitTriggered={submitTriggered}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit" onClick={submitHandler} disabled={isSubmitting}>
            Save
          </Button>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
};

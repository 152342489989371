import { useCallback, useState } from "react";

import { LeadAddedDialog } from "@/components/lead/LeadAddedDialog";
import { useModal } from "@halftax/ui";

const useLeadAddedDialog = () => {
  const [leadId, setLeadId] = useState<number>();
  const { showModal } = useModal(
    ({ hideModal }) => !!leadId && <LeadAddedDialog onExited={hideModal} leadId={leadId} />,
    [leadId],
  );

  const openLeadAddedDialog = useCallback(
    (leadId: number) => {
      setLeadId(leadId);
      showModal();
    },
    [showModal],
  );

  return { openLeadAddedDialog };
};

export default useLeadAddedDialog;

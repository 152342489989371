import {
  Cash as BillingIcon,
  CityVariantOutline as CountiesIcon,
  AccountTieOutline as CustomersIcon,
  ViewDashboardOutline as DashboardIcon,
  AccountGroupOutline as LeadsIcon,
  AccountMultiplePlusOutline as ProspectsIcon,
  CogOutline as SettingsIcon,
  ChevronLeft as SidebarCloseIcon,
  ChevronRight as SidebarOpenIcon,
  TextBoxPlusOutline as TemplatesIcon,
} from "mdi-material-ui";
import {
  Box,
  Button,
  CSSObject,
  DrawerProps,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  SxProps,
  Theme,
  styled,
} from "@mui/material";
import { FC, ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { Styles } from "@halftax/ui";
import { grey } from "@mui/material/colors";

interface SidebarItem {
  label: string;
  icon: ReactNode;
  path: string;
  sx?: SxProps<Theme>;
}

export const sidebarItems: SidebarItem[] = [
  {
    label: "Dashboard",
    icon: <DashboardIcon />,
    path: "/dashboard",
  },
  {
    label: "Counties",
    icon: <CountiesIcon />,
    path: "/counties",
  },
  {
    label: "Prospects",
    icon: <ProspectsIcon />,
    path: "/prospects",
  },
  {
    label: "Leads",
    icon: <LeadsIcon />,
    path: "/leads",
  },
  {
    label: "Customers",
    icon: <CustomersIcon />,
    path: "/customers",
  },
  {
    label: "Billing",
    icon: <BillingIcon />,
    path: "/billing",
  },
  {
    label: "Templates",
    icon: <TemplatesIcon />,
    path: "/templates",
  },
  {
    label: "Settings",
    icon: <SettingsIcon />,
    path: "/settings",
    sx: {
      marginTop: "auto",
    },
  },
];

const openedMixin = (theme: Theme): CSSObject => ({
  width: "10.5rem",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: "3.5rem",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  ".MuiDrawer-paper": {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    overflowX: "hidden",
    border: 0,
  },

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
    "& .halftax-logo-small": { opacity: 0 },
  }),

  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
    "& .halftax-logo": { opacity: 0 },
  }),

  "& .MuiList-root": {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: 0,
  },

  "& .MuiListItemButton-root": {
    alignItems: "center",
    padding: "1rem",

    "&.active": {
      backgroundColor: grey[800],
      color: theme.palette.primary.main,

      "&:hover": { backgroundColor: grey[800] },
      "& .MuiListItemIcon-root": { color: theme.palette.primary.main },
    },

    "&:hover": { backgroundColor: "rgba(66, 66, 66, 0.5)" },
  },

  ".MuiTouchRipple-child": { color: "#6d6d6d" },

  "& .MuiListItemText-root": {
    margin: 0,
    opacity: open ? 1 : 0,

    "& .MuiTypography-root": {
      fontWeight: 500,
      fontSize: "1rem",
    },

    transition: theme.transitions.create("opacity", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  "& .MuiListItemIcon-root": {
    color: theme.palette.secondary.contrastText,
    minWidth: "2rem",
  },

  "& .halftax-logo, .halftax-logo-small": {
    position: "absolute",

    transition: theme.transitions.create("opacity", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

interface SidebarProps extends DrawerProps {
  open: boolean;
  onToggle: () => void;
}

const Sidebar: FC<SidebarProps> = ({ open, onToggle, ...rest }) => {
  const styles: Styles = {
    logoWrapper: {
      display: "flex",
      height: "8.875rem",
      justifyContent: "center",
      px: 3,
      pt: 2,
      pb: 7,
    },
    toggleButton: {
      borderRadius: 0,
      borderTop: "1px solid rgba(255, 255, 255, 0.38)",
      height: "2.5rem",
      minWidth: "3.5rem",
      "&:hover": { backgroundColor: "rgba(66, 66, 66, 0.5)" },
    },
    toggleButtonOpen: {
      justifyContent: "flex-end",
      pr: 2,
    },
  };

  return (
    <Drawer variant="permanent" open={open} {...rest}>
      <Box sx={styles.logoWrapper}>
        <img src="/logo.svg" alt="Halftax Logo" className="halftax-logo" />
        <img src="/logo-small.svg" alt="Halftax Logo" className="halftax-logo-small" />
      </Box>

      <List>
        {sidebarItems.map(({ label, path, icon, sx }) => (
          <ListItem key={label} disablePadding sx={sx}>
            <ListItemButton component={NavLink} to={path}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <Button onClick={onToggle} sx={[styles.toggleButton, open && styles.toggleButtonOpen]}>
        {open ? <SidebarCloseIcon /> : <SidebarOpenIcon />}
      </Button>
    </Drawer>
  );
};

export default Sidebar;

import { queryKey } from "@/api";
import { Avatar, Box, Chip, TextField as MuiTextField, TextFieldProps } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Autocomplete, AutocompleteProps as MuiAutocompleteProps } from "formik-mui";
import { useState } from "react";
import { useDebounce } from "use-debounce";
import { EntityType } from "@/types/common";
import { SearchParams } from "@/types/util";
import { nameToInitials } from "@/utils/display";

type AutocompleteProps<T> = Omit<
  MuiAutocompleteProps<T, true, false, true>,
  "values" | "options" | "renderInput"
>;

export interface SearchAutocompleteProps<T extends object> extends AutocompleteProps<T> {
  entityType: EntityType;
  query: (params: SearchParams) => Promise<T[]>;
  label?: string;
  getOptionLabel: NonNullable<AutocompleteProps<T>["getOptionLabel"]>;
}

const SearchAutocomplete = <T extends object>({
  entityType,
  query,
  placeholder,
  label,
  getOptionLabel,
  ...rest
}: SearchAutocompleteProps<T>) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<T[]>([]);

  // don't refetch faster than every 250ms when typing search query
  const [searchQuery] = useDebounce(inputValue, 250);

  useQuery(
    queryKey([entityType, "list", { q: searchQuery }]),
    () =>
      query({
        page: 0,
        size: 10,
        q: searchQuery,
      }),
    {
      cacheTime: 5000, // cache specific search query for 5s
      onSuccess: (data) => setOptions(data),
    },
  );

  return (
    <Autocomplete
      multiple
      freeSolo
      autoHighlight
      autoComplete
      getOptionLabel={getOptionLabel}
      options={options}
      filterOptions={(x) => x}
      filterSelectedOptions
      placeholder={placeholder}
      onInputChange={(_event, newInputValue) => setInputValue(newInputValue)}
      renderInput={(params: TextFieldProps) => (
        <MuiTextField label={label} placeholder={placeholder} {...params} />
      )}
      renderTags={(value, getTagProps) => (
        <Box sx={{ position: "absolute", top: "100%", left: 0 }}>
          {value.map((option, index) => (
            <Chip
              variant="outlined"
              size="small"
              avatar={
                entityType !== "counties" ? (
                  <Avatar>{nameToInitials(getOptionLabel(option), 1)}</Avatar>
                ) : undefined
              }
              label={getOptionLabel(option)}
              {...getTagProps({ index })}
              key={`option-${getOptionLabel(option)}-${index}`}
            />
          ))}
        </Box>
      )}
      {...rest}
    />
  );
};

export default SearchAutocomplete;

import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Field, Form, FormikProvider, useFormik } from "formik";

import { FC, useState } from "react";
import { TextField } from "formik-mui";
import { linkAddSchema } from "@/validation";
import { addMissingProtocol } from "@/utils/display";
import Dialog, { DialogProps } from "@/components/common/Dialog";
import { useEditCounty, useGetCounty } from "@/api/county";
import invariant from "tiny-invariant";

export interface LinkAddProps extends DialogProps {
  countyId: number;
}

export const LinkAdd: FC<LinkAddProps> = ({ countyId, ...rest }) => {
  const [open, setOpen] = useState(true);
  const { data, isSuccess } = useGetCounty(countyId);
  const { mutate } = useEditCounty(countyId);

  const formik = useFormik({
    validationSchema: linkAddSchema,
    initialValues: linkAddSchema.cast({}),
    onSubmit: (values, { setSubmitting }) => {
      invariant(data, "County data must be fetched before adding a link");

      mutate({
        links: [
          ...data.links,
          {
            ...values,
            url: addMissingProtocol(values.url),
          },
        ],
      });

      setOpen(false);
      setSubmitting(false);
    },
  });

  if (!isSuccess) {
    return null;
  }

  return (
    <Dialog open={open} scroll="body" maxWidth="xs" closeButton fullWidth {...rest}>
      <DialogTitle>Add Link</DialogTitle>
      <FormikProvider value={formik}>
        <Form>
          <DialogContent>
            <Field name="name" label="Link name" component={TextField} fullWidth />
            <Field name="url" label="Add Link" component={TextField} fullWidth />
          </DialogContent>
          <DialogActions>
            <Button type="submit" disabled={formik.isSubmitting}>
              Save
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FC, useState } from "react";
import Dialog, { DialogProps } from "@/components/common/Dialog";
import { UseConfirmationDialog } from "@/components/common/ConfirmationDialog/useConfirmationDialog";

export interface ConfirmationDialogProps extends UseConfirmationDialog, DialogProps {
  onConfirm: () => void;
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  onConfirm,
  onCancel,
  title = "Confirmation",
  description = "Are you sure you want to do this?",
  cancelLabel = "Cancel",
  confirmLabel = "Confirm",
  primaryCancel = false,
  ...rest
}) => {
  const [open, setOpen] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const cancel = () => {
    if (onCancel) {
      setDisabled(true);
      onCancel();
    }
    setOpen(false);
  };

  const confirm = () => {
    setDisabled(true);
    onConfirm();
    setOpen(false);
  };

  return (
    <Dialog open={open} closeButton fullWidth maxWidth="xs" {...rest}>
      <DialogTitle sx={{ background: "none", p: 3, mb: 2 }}>{title}</DialogTitle>
      <DialogContent>{description}</DialogContent>
      <DialogActions>
        {primaryCancel ? (
          <>
            <Button variant="outlined" onClick={confirm} disabled={disabled}>
              {confirmLabel}
            </Button>
            <Button variant="contained" onClick={cancel} disabled={disabled}>
              {cancelLabel}
            </Button>
          </>
        ) : (
          <>
            <Button variant="outlined" onClick={cancel} disabled={disabled}>
              {cancelLabel}
            </Button>
            <Button variant="contained" onClick={confirm} disabled={disabled}>
              {confirmLabel}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;

import { EditContact, EditOwnerContact } from "@/types/contact";

import { addMissingProtocol } from "@/utils/display";

const assigneValue = (name: string, value: any) => (value ? { [name]: value } : {});

export const contactProcess = (contact: EditContact) => {
  const { id, phone, name, website, email, description, address, type } = contact;

  const isAddressEmpty =
    address && Boolean(Object.entries(address).filter(([key, value]) => !value).length);

  return {
    id: id,
    type: type,
    phone: phone ?? "",
    name: name ?? "",
    email: email ?? "",
    ...(description && { description: description }),
    ...assigneValue("website", website),
    ...(address && !isAddressEmpty
      ? {
          address: {
            city: address?.city,
            line2: address?.line2,
            zip: address?.zip,
            line1: address?.line1,
            county: address?.county,
            state: address?.state,
          },
        }
      : null),
  };
};

export const ownerContactProcess = (contact: EditOwnerContact) => {
  const { phone, name, website, email, address, type } = contact;

  const isAddressEmpty =
    address && Boolean(Object.entries(address).filter(([key, value]) => !value).length);

  return {
    type: type,
    name: name ?? "",
    ...assigneValue("phone", phone),
    ...assigneValue("email", email),
    ...assigneValue("website", website ? addMissingProtocol(website) : null),
    ...(address && !isAddressEmpty
      ? {
          address: {
            city: address?.city,
            line2: address?.line2,
            zip: address?.zip,
            line1: address?.line1,
            county: address?.county,
            state: address?.state,
          },
        }
      : null),
  };
};

export const getUntachedList = (list: { [key: string]: string }) => {
  return Object.keys(list).reduce((obj, item) => {
    return {
      ...obj,
      [item]: false,
    };
  }, {});
};

export const contactsProcess = (contacts: EditContact[]) =>
  contacts.map((contact) => {
    return contactProcess(contact);
  });

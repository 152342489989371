import {
  Box,
  Checkbox,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import {
  ColumnDef,
  ColumnHelper,
  ColumnOrderState,
  ColumnSizingState,
  ExpandedState,
  PaginationState,
  RowData,
  TableOptions,
  VisibilityState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
  RowSelectionState,
} from "@tanstack/react-table";
import { ChevronDown as ExpandClosedIcon, ChevronUp as ExpandOpenIcon } from "mdi-material-ui";
import { atomFamily, atomWithStorage } from "jotai/utils";
import { atom, useSetAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";

import { SearchParams } from "@/types/util";
import { Styles } from "@halftax/ui";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { EntityType } from "@/types/common";
import { queryKey } from "@/api";

export type RowDataWithId = RowData & { id: string | number };

export interface SearchTableProps<T extends RowDataWithId> {
  entity: EntityType;
  query: (params: SearchParams) => Promise<T[]>;

  // Note that column values (info.getValue()) will be typed as any if columns prop is defined inline on the component
  // https://github.com/TanStack/table/issues/4302
  columns: ((columnHelper: ColumnHelper<T>) => ColumnDef<T, any>[]) | ColumnDef<T, any>[];
  options?: Partial<TableOptions<T>>;
}

// Atoms used to store the user preferences for each table, such as column order, visibility, and size in local storage.
// Order and visibility may be fetched from the server in the future, to customize the default UI for different user groups.

export const columnVisibilityAtom = atomFamily((queryKey: string) =>
  atomWithStorage<VisibilityState>(`columnVisibility-${queryKey}`, {}),
);

export const columnSizingAtom = atomFamily((queryKey: string) =>
  atomWithStorage<ColumnSizingState>(`columnSizing-${queryKey}`, {}),
);

export const columnOrderAtom = atomFamily((queryKey: string) =>
  atomWithStorage<ColumnOrderState>(`columnOrder-${queryKey}`, []),
);

export const selectedTableRowsAtom = atom<Record<string, any>[]>([]);

// TODO(Kornelijus): store page size preferences as well

const SearchTable = <T extends RowDataWithId>({
  entity,
  query,
  columns,
  options,
}: SearchTableProps<T>) => {
  const navigate = useNavigate();
  const [columnSizing, setColumnSizing] = useAtom(columnSizingAtom(entity));
  const [columnVisibility, setColumnVisibility] = useAtom(columnVisibilityAtom(entity));
  const [columnOrder, setColumnOrder] = useAtom(columnOrderAtom(entity));
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);
  const setSelectedTableRowsAtom = useSetAtom(selectedTableRowsAtom);

  const { data, isSuccess } = useQuery(queryKey([entity, "list", { pageIndex, pageSize }]), () =>
    query({ page: pageIndex, size: pageSize }),
  );

  const columnHelper = createColumnHelper<T>();

  const tableColumns = [
    columnHelper.display({
      id: "checkbox",
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
          sx={{
            p: 0,
            maxHeight: "1.375rem",
          }}
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
          sx={[
            {
              p: 0,
              maxHeight: "1.375rem",
            },
            row.depth !== 0 && { display: "none" },
          ]}
        />
      ),
      enableResizing: false,
      maxSize: 58,
      size: 58,
    }),
  ];

  if (typeof columns === "function") {
    tableColumns.push(...columns(columnHelper));
  } else {
    tableColumns.push(...columns);
  }

  if (options?.getSubRows) {
    tableColumns.push(
      columnHelper.display({
        id: "expand",
        cell: ({ row }) =>
          row.getCanExpand() && (
            <IconButton onClick={row.getToggleExpandedHandler()}>
              {row.getIsExpanded() ? <ExpandOpenIcon /> : <ExpandClosedIcon />}
            </IconButton>
          ),
        enableResizing: false,
        maxSize: 58,
        size: 58,
      }),
    );
  }

  const table = useReactTable({
    data: data ?? [],
    columns: tableColumns,

    manualPagination: true,
    enableColumnResizing: true,
    columnResizeMode: "onEnd",

    state: {
      columnOrder,
      columnSizing,
      columnVisibility,
      expanded,
      pagination,
      rowSelection,
    },

    onExpandedChange: setExpanded,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onColumnSizingChange: setColumnSizing,
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    ...options,
  });

  useEffect(() => {
    const list = table.getSelectedRowModel().rows.map((item) => item.original);
    setSelectedTableRowsAtom(list);
  }, [table, rowSelection, setSelectedTableRowsAtom]);

  const styles: Styles = {
    tableContainer: (theme) => ({
      bgcolor: theme.palette.common.white,
      borderRadius: "0.5rem 0.5rem 0 0",
      flexGrow: 1,
      p: 0,
    }),
    table: (theme) => ({
      tableLayout: "fixed",
      "td, th": {
        px: 1,
        py: 2,
        ":first-of-type": {
          px: 2,
        },
      },
      thead: {
        bgcolor: "#e1e6ec",
        fontWeight: "bold",
        th: {
          bgcolor: "#e1e6ec",
          fontWeight: 500,
          lineHeight: "1.375rem",
          minWidth: "0px",
          borderBottom: "none",
        },
      },
      tbody: {
        tr: {
          ":hover": {
            bgcolor: "rgba(0, 0, 0, 0.04)",
          },
          td: {
            borderBottom: `1px solid ${theme.palette.other.divider}`,
            whiteSpace: "pre-line",
            minWidth: "0px",
          },
        },
      },
      "& .Mui-checked, & .MuiCheckbox-indeterminate": { color: theme.palette.primary.dark },
    }),
    tableRowSelected: (theme) => ({
      bgcolor: theme.palette.primary.lightest,
    }),
    tableRow: {
      cursor: "pointer",
    },
    tablePagination: (theme) => ({
      bgcolor: theme.palette.common.white,
      borderTop: `1px solid ${theme.palette.other.divider}`,
      borderRadius: "0 0 0.5rem 0.5rem",
    }),
    resizer: {
      position: "absolute",
      right: 0,
      top: "35%",
      height: "30%",
      width: "2px",
      cursor: "col-resize",
      userSelect: "none",
      touchAction: "none",
      bgcolor: "rgb(179, 197, 204)",
    },
    resizerActive: {
      bgcolor: "rgb(165, 182, 189)",
      zIndex: "100",
    },
    resizerNotResizable: {
      display: "none",
    },
  };

  return (
    <Box
      sx={{
        px: 3,
        display: "flex",
        flexDirection: "column",
        height: { xs: "calc(100vh - 14.375rem)", lg: "calc(100vh - 12rem)" },
      }}
    >
      <TableContainer sx={styles.tableContainer}>
        <Table sx={styles.table} stickyHeader>
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableCell
                    key={header.id}
                    colSpan={header.colSpan}
                    sx={{ width: header.getSize() }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                    <Box
                      sx={[
                        styles.resizer,
                        !header.column.getCanResize() && styles.resizerNotResizable,
                        header.column.getIsResizing() && styles.resizerActive,
                        header.column.getIsResizing() && {
                          transform: `translateX(${
                            table.getState().columnSizingInfo.deltaOffset
                          }px)`,
                        },
                      ]}
                      onMouseDown={header.getResizeHandler()}
                      onTouchStart={header.getResizeHandler()}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>

          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                sx={[styles.tableRow, row.getIsSelected() && styles.tableRowSelected]}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    sx={{ width: cell.column.getSize() }}
                    onClick={(event) => {
                      // only if parent, not child clicked, and not subrow
                      if (event.target === event.currentTarget && row.depth === 0) {
                        navigate(`./${row.original.id}`);
                      }
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {!isSuccess && <LinearProgress />}
      </TableContainer>

      <Box sx={styles.tablePagination}>
        <TablePagination
          component={Box}
          count={-1}
          labelDisplayedRows={() => <></>}
          onPageChange={(_event, newPage) => table.setPageIndex(newPage)}
          onRowsPerPageChange={(event) => table.setPageSize(Number(event.target.value))}
          page={table.getState().pagination.pageIndex}
          rowsPerPage={table.getState().pagination.pageSize}
        />
      </Box>
    </Box>
  );
};

export default SearchTable;

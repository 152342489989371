import { TimelineClock as ActivityIcon } from "mdi-material-ui";
import EmptyTabMessage from "@/components/common/EmptyTabMessage";

const LatestActivity = () => (
  <EmptyTabMessage
    icon={<ActivityIcon />}
    title="No latest activity yet"
    description="Latest activity is coming soon, come back later"
  />
);

export default LatestActivity;

import { useCallback, useState } from "react";

import { PinLink } from "@/components/common/dialogs/pinLink/PinLink";
import { useModal } from "@halftax/ui";

export const usePinLinkDialog = () => {
  const [propertyId, setPropertyId] = useState<number>();

  const { showModal } = useModal(
    ({ hideModal }) => !!propertyId && <PinLink onExited={hideModal} propertyId={propertyId} />,
    [propertyId],
  );

  const openPinLinkDialog = useCallback(
    (propertyId: number) => {
      setPropertyId(propertyId);
      showModal();
    },
    [showModal],
  );

  return { openPinLinkDialog };
};

import "@aws-amplify/ui-react/styles.css";

import { API_NAME, USE_LOCAL_SERVER } from "@/config";
import { Amplify, Auth } from "aws-amplify";
import { getEnvironment, getPool, isLocalhost } from "@/utils/environment";

import App from "@/components/App";
import React from "react";
import ReactDOM from "react-dom/client";
import { sentryInit } from "@/sentry";

if (!isLocalhost()) {
  sentryInit();
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const pool = getPool();

if (pool) {
  Amplify.configure({
    Auth: {
      region: "eu-central-1",
      identityPoolId: pool.identityPoolId,
      userPoolId: pool.id,
      userPoolWebClientId: pool.app,
    },
    API: {
      endpoints: [
        {
          name: API_NAME,
          endpoint:
            getEnvironment() === "dev"
              ? USE_LOCAL_SERVER
                ? "http://localhost:8000/"
                : "https://dev.halftax.com/api/"
              : "/api/",
          custom_header: async () => ({
            Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
          }),
        },
      ],
    },
  });
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

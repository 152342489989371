import { Styles } from "@halftax/ui";
import { prospectPropertyTypeIcons } from "@/types/prospect";
import { displayAddress } from "@/utils/display";
import { Grid, Skeleton, Typography, Avatar } from "@mui/material";
import { FC } from "react";
import { PropertyStatus } from "@/components/property/PropertyStatus";
import { useGetProperty } from "@/api/property";

type Props = {
  active: boolean;
  displayStatus?: boolean;
  propertyId: number;
};

export const PropertyTile: FC<Props> = ({ propertyId, active, displayStatus }) => {
  const { data, isSuccess } = useGetProperty(propertyId);

  const styles: Styles = {
    active: {
      borderColor: (theme) => theme.palette.primary.main,
      opacity: 1,
    },
    propertyAvatar: (theme) => ({
      height: "3.5rem",
      width: "3.5rem",
      bgcolor: theme.palette.common.white,
      border: `1px solid ${theme.palette.other.border}`,
      color: theme.palette.primary.dark,
      position: "relative",
      mb: "-3.5rem",
      mr: 2,
      transform: "translateY(-50%)",

      "& .MuiSvgIcon-root": {
        width: "1.75rem",
        height: "1.75rem",
      },
    }),
    wrapper: {
      borderRadius: "0.5rem",
      overflow: "hidden",
      bgcolor: (theme) => theme.palette.common.white,
      border: (theme) => `2px solid ${theme.palette.background.default}`,
      cursor: "pointer",
      opacity: 0.8,
      height: 1,
    },
    statusWrapper: {
      height: 120,
      backgroundColor: (theme) => theme.palette.secondary.light,
      position: "relative",
    },
  };

  return (
    <Grid container direction="column" sx={[styles.wrapper, active && styles.active]}>
      <Grid item sx={styles.statusWrapper}>
        {displayStatus && <PropertyStatus />}
      </Grid>
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        {isSuccess && (
          <Avatar sx={styles.propertyAvatar}>
            {prospectPropertyTypeIcons[data.main_prospect.type_property]}
          </Avatar>
        )}
      </Grid>
      <Grid
        item
        sx={{
          p: 2,
        }}
      >
        <Grid container direction="column" rowSpacing={2}>
          <Grid item>
            <Typography variant="subtitle1" sx={{ whiteSpace: "pre-line", pr: 8 }}>
              {isSuccess ? (
                displayAddress(data.main_prospect.address)
              ) : (
                <Skeleton width="min(40ch, 80%)" />
              )}
            </Typography>
          </Grid>
          <Grid item>
            {isSuccess &&
              data.prospects.map(({ pin }, key) => (
                <Typography variant="body2" key={key}>
                  {pin}
                </Typography>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

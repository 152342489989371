import { ContactType, EditContact } from "@/types/contact";
import { FieldArrayRenderProps, FormikErrors } from "formik";
import { FC } from "react";
import { TrashCanOutline as DeleteIcon } from "mdi-material-ui";
import {
  ContactEditOfficeFields,
  ContactEditOwnerFields,
  ContactEditGeneralFields,
} from "@/components/common/dialogs/contactEdit";
import { Box, IconButton, useTheme } from "@mui/material";
import TabMenu from "@/components/common/TabMenu";
import { AlertCircleOutline as Alert } from "mdi-material-ui";
import { ContactError } from "@/components/common/dialogs/contactEdit/ContactError";
import { Styles } from "@halftax/ui";
import { useConfirmationDialog } from "@/components/common/ConfirmationDialog";

type Props = {
  arrayHelpers: void | FieldArrayRenderProps;
  errors: FormikErrors<{
    contacts: EditContact[];
  }>;
  isValid: boolean;
  submitTriggered: boolean;
};

export const ContactEditFormFields: FC<Props> = ({
  arrayHelpers,
  errors,
  isValid,
  submitTriggered,
}) => {
  const theme = useTheme();
  const { confirm: confirmDelete } = useConfirmationDialog({
    title: "Delete contact",
    description: "Are you sure you want to delete this contact?",
    confirmLabel: "Delete",
  });

  const addContact = () => {
    const length = arrayHelpers?.form.values.contacts.length || 0;
    arrayHelpers?.push({
      name: `contact #${length + 1}`,
      description: "",
      phone: "",
      email: "",
      type: ContactType.General,
    });
  };

  const styles: Styles = {
    alignedIcon: {
      position: "absolute",
      top: "50%",
      right: 0,
      transform: "translateY(-50%)",
    },
    customNavigation: {
      py: 2,
      pl: 3,
      color: theme.palette.text.secondary,
      alignItems: "flex-start",
      fontSize: 14,
      cursor: "pointer",
    },
  };

  return (
    <TabMenu
      customNavigation={
        <Box sx={styles.customNavigation} onClick={addContact}>
          +add contact
        </Box>
      }
      tabs={arrayHelpers?.form.values.contacts.map((item: EditContact, index: number) => ({
        label: item.name || "",
        actions: (
          <>
            {item?.type !== ContactType.Owner && (
              <IconButton
                size="large"
                sx={styles.alignedIcon}
                onClick={() =>
                  confirmDelete(() => {
                    arrayHelpers?.remove(index);
                  })
                }
              >
                <DeleteIcon sx={{ fontSize: 20 }} />
              </IconButton>
            )}
          </>
        ),
        additionalInfo: errors?.contacts?.[index] && submitTriggered && (
          <Alert sx={[styles.alignedIcon, { color: "error.main" }]} />
        ),
        content: (
          <>
            {item?.type === ContactType.Office && (
              <ContactEditOfficeFields formPrefix={`contacts.${index.toString()}`} />
            )}
            {item?.type === ContactType.Owner && (
              <ContactEditOwnerFields formPrefix={`contacts.${index.toString()}`} />
            )}
            {item?.type === ContactType.General && (
              <ContactEditGeneralFields formPrefix={`contacts.${index.toString()}`} />
            )}
            {!isValid && submitTriggered && <ContactError />}
          </>
        ),
      }))}
    />
  );
};

import { Button, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import { Field, Form, FormikProvider, useFormik } from "formik";
import { FC, useState } from "react";
import { valueAddSchema } from "@/validation";
import { TextField } from "formik-mui";
import Dialog, { DialogProps } from "@/components/common/Dialog";

export const ValueAdd: FC<DialogProps> = ({ ...rest }) => {
  const [open, setOpen] = useState(true);

  const formik = useFormik({
    validationSchema: valueAddSchema,
    initialValues: valueAddSchema.cast({}),
    onSubmit: (values, { setSubmitting }) => {
      // TODO(Kornelijus): value add query once feature is implemented, placeholder for now

      setOpen(false);
      setSubmitting(false);
    },
  });

  const fields = [
    {
      name: "marketCap",
      label: "Market Cap",
    },
    {
      name: "valueBoost",
      label: "Value Boost",
    },
    {
      name: "valueIncrease",
      label: "Value Increase",
    },
  ];

  return (
    <Dialog open={open} scroll="body" maxWidth="xs" closeButton {...rest}>
      <DialogTitle>Value Add</DialogTitle>
      <FormikProvider value={formik}>
        <Form>
          <DialogContent>
            {fields.map(({ name, label }) => (
              <Field
                key={name}
                name={name}
                label={label}
                component={TextField}
                fullWidth
                InputProps={{
                  startAdornment: "$",
                }}
              />
            ))}
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button type="submit" disabled={formik.isSubmitting}>
              Save
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

import { EditContact } from "@/types/contact";
import { SecondaryContact } from "@/types/lead";

export const checkIfSecondaryContactFilled = (contact: SecondaryContact): EditContact | null => {
  if (contact && contact.address) {
    const { line1, city, county, zip, state } = contact.address;
    if (line1 && city && county && zip && state) {
      const filledContact: EditContact = contact;
      return filledContact;
    }
  }
  return null;
};

import { CssBaseline, ThemeProvider } from "@mui/material";

import Authenticator from "@/components/auth/Authenticator";
import { BrowserRouter } from "react-router-dom";
import { FC } from "react";
import Main from "@/components/Main";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "@/api";

import { getTheme } from "@halftax/ui";

import ContextsProvider from "@/contexts/ContextsProvider";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { SnackbarProvider } from "notistack";

const App: FC = () => {
  return (
    <>
      <SnackbarProvider />
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools position="bottom-right" />

        <ThemeProvider theme={getTheme("admin")}>
          <CssBaseline />
          <BrowserRouter>
            <Authenticator>
              <ContextsProvider>
                <Main />
              </ContextsProvider>
            </Authenticator>
          </BrowserRouter>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
};

export default App;

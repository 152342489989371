import { Button, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { CogOutline } from "mdi-material-ui";
import { useNavigate } from "react-router-dom";
import { Styles } from "@halftax/ui";

type Props = {
  closeModal: () => void;
};

export const NotificationsFooter: FC<Props> = ({ closeModal }) => {
  const navigate = useNavigate();

  const settingsClickHandler = () => {
    navigate("/settings");
    closeModal();
  };

  const styles: Styles = {
    button: {
      width: 1,
      borderRadius: 0,
      height: 64,
      justifyContent: "flex-start",
      p: 3,
    },
  };

  return (
    <Grid
      container
      sx={{
        width: 1,
      }}
    >
      <Grid
        item
        sx={{
          width: 1,
        }}
      >
        <Button fullWidth onClick={settingsClickHandler} sx={styles.button}>
          <CogOutline sx={{ mr: 2 }} />
          <Typography variant="subtitle1">Notifications settings</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

import { ModalProvider } from "@halftax/ui";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const ContextsProvider = (props: Props) => {
  const { children } = props;

  return <ModalProvider>{children}</ModalProvider>;
};

export default ContextsProvider;

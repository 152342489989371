import { useCallback, useState } from "react";

import ConfirmationDialog from "@/components/common/ConfirmationDialog/ConfirmationDialog";
import { useModal } from "@halftax/ui";

export interface UseConfirmationDialog {
  onCancel?: () => void;
  title?: string;
  description?: string;
  cancelLabel?: string;
  confirmLabel?: string;
  primaryCancel?: boolean;
}

const useConfirmationDialog = (props: UseConfirmationDialog) => {
  const [callback, setCallback] = useState<(() => void) | null>(null);

  const { showModal } = useModal(
    ({ hideModal }) =>
      callback && <ConfirmationDialog onExited={hideModal} onConfirm={callback} {...props} />,
    [callback],
  );

  const confirm = useCallback(
    (callback: () => void) => {
      showModal();
      setCallback(() => callback);
    },
    [showModal],
  );

  return { confirm };
};

export default useConfirmationDialog;

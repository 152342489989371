import { Button, Grid, Toolbar } from "@mui/material";
import { displayDate, displayState } from "@/utils/display";

import { County } from "@/types/county";
import { PlaylistPlus as CreateListIcon } from "mdi-material-ui";
import { FC } from "react";
import LinkButton from "@/components/common/LinkButton";
import SearchOptions from "@/components/common/SearchOptions";
import { SearchParams } from "@/types/util";
import SearchTable from "@/components/common/SearchTable";
import { search } from "@/api";
import { useCreateListDialog } from "@/components/common/CreateListDialog";

const CountyList: FC = () => {
  const { openCreateListDialog } = useCreateListDialog("counties");

  return (
    <>
      <Toolbar variant="regular" sx={{ py: 3 }}>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item>
            <Button startIcon={<CreateListIcon />} onClick={openCreateListDialog}>
              Create List
            </Button>
          </Grid>

          <Grid item>
            <SearchOptions />
          </Grid>
        </Grid>
      </Toolbar>

      <SearchTable
        entity="counties"
        query={(params: SearchParams) =>
          search<County>()({
            entity: "counties",
            only: [
              "id",
              "name",
              "state",
              "assessment_frequency",
              "assessment_last",
              "assessment_next",
              "deadline_appeal_tax",
              "link_property_database",
            ],
            ...params,
          })
        }
        columns={(col) => [
          col.accessor("name", {
            header: "Name",
            cell: (info) => `${info.getValue()} County`,
            size: 140,
          }),
          col.accessor("state", {
            header: "State",
            cell: (info) => displayState(info.getValue()),
            size: 140,
          }),
          col.accessor("assessment_frequency", {
            header: "Assessment Frequency",
            cell: (info) => `${info.getValue()} ${info.getValue() === 1 ? "year" : "years"}`,
          }),
          col.accessor("deadline_appeal_tax", {
            header: "Deadline Appeal Tax",
            cell: (info) => displayDate({ ISOdate: info.getValue() }),
            size: 140,
          }),
          col.accessor("assessment_last", {
            header: "Last Assessment",
            cell: (info) => displayDate({ ISOdate: info.getValue() }),
            size: 140,
          }),
          col.accessor("assessment_next", {
            header: "Next Assessment",
            cell: (info) => displayDate({ ISOdate: info.getValue() }),
            size: 140,
          }),
          col.accessor("link_property_database", {
            header: "Link Property Database",
            cell: (info) => (
              <LinkButton href={info.getValue()} target="_blank">
                Link to Database
              </LinkButton>
            ),
            size: 200,
          }),
        ]}
      />
    </>
  );
};

export default CountyList;

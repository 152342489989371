import { Button, Grid, Stack, Toolbar } from "@mui/material";
import {
  PlaylistPlus as CreateListIcon,
  PhoneOff as DoNotCallIcon,
  ArrowRight as ImportListIcon,
  EmailFastOutline as SendTemplateIcon,
} from "mdi-material-ui";
import { Prospect, ProspectPropertyType, prospectPropertyTypeLabels } from "@/types/prospect";
import { displayCurrency, displayDate } from "@/utils/display";

import { FC } from "react";
import SearchOptions from "@/components/common/SearchOptions";
import { SearchParams } from "@/types/util";
import SearchTable from "@/components/common/SearchTable";
import { displayAddress } from "@/utils/display";
import { search } from "@/api";
import { useCreateListDialog } from "@/components/common/CreateListDialog";

const ProspectList: FC = () => {
  const { openCreateListDialog } = useCreateListDialog("prospects");

  return (
    <>
      <Toolbar variant="regular" sx={{ py: 3 }}>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item>
            <Stack direction="row" spacing={1}>
              <Button startIcon={<ImportListIcon />}>Import List</Button>
              <Button startIcon={<CreateListIcon />} onClick={openCreateListDialog}>
                Create List
              </Button>
              <Button startIcon={<SendTemplateIcon />}>Send Template</Button>
              <Button color="error" startIcon={<DoNotCallIcon />}>
                Do Not Call
              </Button>
            </Stack>
          </Grid>

          <Grid item>
            <SearchOptions />
          </Grid>
        </Grid>
      </Toolbar>

      <SearchTable
        entity="prospects"
        query={(params: SearchParams) =>
          search<Prospect>()({
            entity: "prospects",
            only: [
              "id",
              "owner.name",
              "address",
              "mailing_address",
              "out_of_state",
              "type_property",
              "assessment_last",
              "assessment_next",
              "value_tax",
            ],
            ...params,
          })
        }
        columns={(col) => [
          col.accessor("owner.name", {
            header: "Name",
            size: 140,
          }),
          col.accessor("mailing_address", {
            header: "Mailing Address",
            cell: (info) => displayAddress(info.getValue()),
            size: 260,
          }),
          col.accessor("address", {
            header: "Property Address",
            cell: (info) => displayAddress(info.getValue()),
            size: 260,
          }),
          col.accessor("out_of_state", {
            header: "Out of State",
            cell: (info) => (info.getValue() ? "Yes" : "No"),
            size: 120,
          }),
          col.accessor<"type_property", ProspectPropertyType>("type_property", {
            header: "Property Type",
            cell: (info) => prospectPropertyTypeLabels[info.getValue()],
            size: 120,
          }),
          col.accessor("assessment_last", {
            header: "Last Assessment",
            cell: (info) => displayDate({ ISOdate: info.getValue(), showSkeleton: false }),
            size: 140,
          }),
          col.accessor("assessment_next", {
            header: "Next Assessment",
            cell: (info) => displayDate({ ISOdate: info.getValue(), showSkeleton: false }),
            size: 140,
          }),
          col.accessor("value_tax", {
            header: "Tax Value",
            cell: (info) => displayCurrency(info.getValue()),
          }),
        ]}
      />
    </>
  );
};

export default ProspectList;

import { FC, ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import { Styles } from "@halftax/ui";

export interface EmptyTabMessageProps {
  icon: ReactNode;
  title: ReactNode;
  description?: ReactNode;
}

const EmptyTabMessage: FC<EmptyTabMessageProps> = ({ icon, title, description }) => {
  const styles: Styles = {
    container: { display: "flex", alignItems: "center", flexDirection: "column", p: 5 },
    iconWrapper: (theme) => ({
      display: "flex",
      bgcolor: theme.palette.secondary.light,
      borderRadius: "0.5rem",
      p: 1,
      mb: 2,

      "& .MuiSvgIcon-root": {
        color: theme.palette.common.white,
        fontSize: "3rem",
      },
    }),
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.iconWrapper}>{icon}</Box>

      <Typography variant="subtitle1">{title}</Typography>

      {description && (
        <Typography
          variant="body2"
          sx={(theme) => ({ mt: 2, color: theme.palette.text.secondary })}
        >
          {description}
        </Typography>
      )}
    </Box>
  );
};

export default EmptyTabMessage;

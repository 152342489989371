import { InfoTile } from "@/components/common/infoTiles";
import { Grid } from "@mui/material";
import { FC } from "react";
import { Tile } from "@/components/dashboard/types";

type Props = {
  list: Tile[];
  valueProcessor?: (value: number) => string | undefined;
};

export const InfoTilesList: FC<Props> = ({ list, valueProcessor }) => {
  return (
    <Grid container justifyContent="space-between" sx={{ p: 0 }} columnSpacing={3}>
      {list.map((item, index) => (
        <Grid key={index} item xs={3}>
          <InfoTile tile={item} valueProcessor={valueProcessor} />
        </Grid>
      ))}
    </Grid>
  );
};

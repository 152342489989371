import { EntityType } from "@/types/common";
import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FC, useState } from "react";
import { Field, Form, FormikProvider, useFormik } from "formik";
import { TextField } from "formik-mui";
import capitalize from "lodash/capitalize";
import SearchAutocomplete from "@/components/common/form/SearchAutocomplete";
import { County } from "@/types/county";
import { search } from "@/api";
import { SearchParams } from "@/types/util";
import { Prospect } from "@/types/prospect";
import { Customer, Lead } from "@/types/lead";
import { useCreateTag } from "@/api/tag";
import { TagType } from "@/types/tag";
import { createListSchema } from "@/validation";
import { useAtomValue } from "jotai";
import { selectedTableRowsAtom } from "@/components/common/SearchTable";
import Dialog, { DialogProps } from "@/components/common/Dialog";

type ListEntityType = Extract<EntityType, "counties" | "prospects" | "leads" | "customers">;

export interface CreateListDialogProps extends DialogProps {
  entityType: ListEntityType;
}

const addFieldPlaceholders: { [T in ListEntityType]: string } = {
  counties: "Enter county name",
  prospects: "Enter name or property address",
  leads: "Enter name or property address",
  customers: "Enter name or property address",
};

const addFieldQueries = {
  counties: (params) =>
    search<County>()({
      entity: "counties",
      only: ["name", "id"],
      by: ["name"],
      ...params,
    }),
  prospects: (params) =>
    search<Prospect>()({
      entity: "prospects",
      only: ["owner.name", "id"],
      by: ["owner.address", "pin"],
      ...params,
    }),
  leads: (params) =>
    search<Lead>()({
      entity: "leads",
      only: ["owner.name", "id"],
      by: ["owner.address", "properties.main_prospect.pin"],
      ...params,
    }),
  customers: (params) =>
    search<Customer>()({
      entity: "customers",
      only: ["owner.name", "id"],
      by: ["owner.address", "properties.main_prospect.pin"],
      ...params,
    }),
} satisfies {
  [T in ListEntityType]: (params: SearchParams) => Promise<any[]>;
};

const addFieldLabels = {
  counties: (data: County) => data.name,
  prospects: (data: Prospect) => data.owner.name,
  leads: (data: Lead) => data.owner.name,
  customers: (data: Customer) => data.owner.name,
} satisfies { [T in ListEntityType]: (data: any) => string };

const CreateListDialog: FC<CreateListDialogProps> = ({ entityType, ...rest }) => {
  const selectedTableRow = useAtomValue(selectedTableRowsAtom);
  const [open, setOpen] = useState(true);
  const { mutate } = useCreateTag();

  const formik = useFormik({
    initialValues: createListSchema.cast({
      ids: selectedTableRow,
    }),
    onSubmit: ({ tag, ids: objectsWithIds }, { setSubmitting }) =>
      mutate(
        {
          tag, // the tag's name
          table: entityType,
          type: TagType.List,
          ids: objectsWithIds.map((obj) => obj.id),
        },
        {
          onSuccess: () => {
            setOpen(false);
            setSubmitting(false);
          },
        },
      ),
  });

  return (
    <Dialog open={open} fullWidth maxWidth="sm" closeButton {...rest}>
      <DialogTitle>Create List</DialogTitle>
      <FormikProvider value={formik}>
        <Form>
          <DialogContent>
            <Field
              component={TextField}
              name="tag"
              label="List name"
              placeholder="Enter list name"
              fullWidth
            />

            <Field
              component={SearchAutocomplete}
              name="ids"
              label={`Add ${capitalize(entityType)}`}
              placeholder={addFieldPlaceholders[entityType]}
              entityType={entityType}
              query={addFieldQueries[entityType]}
              getOptionLabel={addFieldLabels[entityType]}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button type="submit" disabled={formik.isSubmitting}>
              Save
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default CreateListDialog;

import { FC } from "react";
import SmallTable from "@/components/common/SmallTable";
import { ClipboardCheckMultiple as AppealResultsIcon } from "mdi-material-ui";

export const AppealResults: FC = () => {
  return (
    <SmallTable
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      data={[] as any[]}
      options={{
        placeholder: {
          icon: <AppealResultsIcon />,
          title: "No appeal results yet",
          description: "Once they are available, appeal results will be displayed here",
        },
      }}
      columns={(col) => [
        col.accessor("Name", {
          header: "Name",
        }),
        col.accessor("Before Appeal", {
          header: "Before Appeal",
        }),
        col.accessor("After Appeal", {
          header: "After Appeal",
        }),
        col.accessor("Total Savings", {
          header: "Total Savings",
        }),
      ]}
    />
  );
};

import { FC } from "react";
import { useGetDashboard } from "@/api/dashboard";
import { Grid } from "@mui/material";
import {
  DashboardAppeals,
  DashboardProperties,
  DashboardRevenue,
  InfoTilesList,
  DashboardCalendar,
} from "@/components/dashboard";

export const Dashboard: FC = () => {
  const { data, isSuccess } = useGetDashboard();

  return (
    <>
      {isSuccess && (
        <Grid container direction="column" sx={{ p: 3, pb: 3 }} rowSpacing={4}>
          <Grid item>
            <InfoTilesList list={data.general} />
          </Grid>
          <Grid item>
            <Grid container columnSpacing={3}>
              <Grid item xs={8}>
                <Grid container flexDirection="column" rowSpacing={4}>
                  <Grid item>
                    <Grid container columnSpacing={3}>
                      <Grid item xs={6}>
                        <DashboardAppeals appeals={data.appeals} />
                      </Grid>
                      <Grid item xs={6}>
                        <DashboardRevenue revenue={data.revenue} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <DashboardProperties property={data.properties} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <DashboardCalendar />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

import { IconButton, IconButtonProps } from "@mui/material";

import { WindowClose as CloseDialogIcon } from "mdi-material-ui";
import { FC } from "react";

const CloseDialogButton: FC<IconButtonProps> = (props) => {
  return (
    <IconButton
      size="medium"
      sx={(theme) => ({
        top: "1.125rem",
        right: "1.125rem",
        position: "absolute",
        color: theme.palette.common.black,
        p: 0.75,
      })}
      {...props}
    >
      <CloseDialogIcon />
    </IconButton>
  );
};

export default CloseDialogButton;

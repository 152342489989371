import { StatusDialog } from "@/components/common/dialogs/status/StatusDialog";
import { useModal } from "@halftax/ui";

export const useStatusDialog = () => {
  const { showModal } = useModal(({ hideModal }) => {
    return <StatusDialog onExited={hideModal} />;
  }, []);

  return { openStatus: showModal };
};

import {
  Avatar,
  IconButton,
  AppBar,
  Stack,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Box,
  Button,
  useTheme,
  Skeleton,
} from "@mui/material";
import { BellOutline as NotificationIcon, Magnify as SearchIcon } from "mdi-material-ui";

import { FC, useState } from "react";
import { nameToInitials } from "@/utils/display";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { useNotificationsDialog } from "@/components/common/dialogs/notifications";
import {
  MenuUp as MenuOpenedIcon,
  MenuDown as MenuClosedIcon,
  ChevronLeft as BackIcon,
} from "mdi-material-ui";
import { Styles } from "@halftax/ui";
import { useDefaultHeader } from "@/hooks/useHeader";

export interface HeaderProps {
  open: boolean;
}

const Header: FC<HeaderProps> = ({ open }) => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const { openNotifications } = useNotificationsDialog();
  const [menuElement, setMenuElement] = useState<HTMLElement | null>(null);
  const navigate = useNavigate();

  const userFullName = `${user.attributes?.given_name} ${user.attributes?.family_name}`;

  const theme = useTheme();
  const styles: Styles = {
    header: {
      ml: "3.5rem",
      width: "calc(100% - 3.5rem)",
      bgcolor: theme.palette.secondary.main,

      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),

      boxShadow: `
      0px 3px 1px -2px rgba(109, 109, 109, 0.2),
      0px 2px 2px rgba(109, 109, 109, 0.14),
      0px 1px 5px rgba(109, 109, 109, 0.12)
      `,

      "&::before": {
        bgcolor: theme.palette.primary.accent,
        borderStartStartRadius: "1.5rem",
        position: "absolute",
        content: "''",
        height: "100%",
        width: "100%",
      },
    },
    headerOpen: {
      ml: "10.5rem",
      width: "calc(100% - 10.5rem)",
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    headerButtons: {
      "& .MuiIconButton-root": { p: "0.25rem" },
      "& .MuiButton-root, & .MuiIconButton-root": {
        bgcolor: theme.palette.whiteText.disabled,
        color: theme.palette.common.black,

        ":hover": {
          bgcolor: "rgba(255, 255, 255, 0.28)",
        },
      },
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
    },
    menu: { "& .MuiPaper-root": { mt: 1 } },
    menuButton: {
      px: 0.75,
      ...theme.typography.body2,

      "& .MuiAvatar-root, & .MuiSvgIcon-root": {
        width: "1.5rem",
        height: "1.5rem",
      },
    },
    menuAvatar: {
      bgcolor: theme.palette.text.primary,
      color: theme.palette.common.white,
      ...theme.typography.body1,
    },
    backButton: {
      p: 0,
      "& .MuiSvgIcon-root": {
        height: "2rem",
        width: "2rem",
        color: theme.palette.text.primary,
      },
    },
  };

  const { title, backRoute } = useDefaultHeader();

  return (
    <AppBar sx={[styles.header, open && styles.headerOpen]} position="fixed">
      <Toolbar sx={styles.toolbar}>
        <Stack direction="row" spacing={1} alignItems="center">
          {backRoute && (
            <IconButton onClick={() => navigate(backRoute)} sx={styles.backButton}>
              <BackIcon />
            </IconButton>
          )}
          <Typography variant="h6" component="div" noWrap>
            {title ?? <Skeleton width="24ch" />}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1} alignItems="center" sx={styles.headerButtons}>
          <Box>
            <Button
              sx={styles.menuButton}
              onClick={(event) => setMenuElement(event.currentTarget)}
              startIcon={<Avatar sx={styles.menuAvatar}>{nameToInitials(userFullName, 1)}</Avatar>}
              endIcon={!!menuElement ? <MenuOpenedIcon /> : <MenuClosedIcon />}
              disableTouchRipple
            >
              {userFullName}
            </Button>

            <Menu
              sx={styles.menu}
              open={!!menuElement}
              anchorEl={menuElement}
              onClose={() => setMenuElement(null)}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem>My Profile</MenuItem>
              <MenuItem onClick={() => signOut()}>Log Out</MenuItem>
            </Menu>
          </Box>

          <IconButton size="small">
            <SearchIcon />
          </IconButton>

          <IconButton size="small" onClick={openNotifications}>
            <NotificationIcon />
          </IconButton>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

import { Box } from "@mui/material";
import { FC } from "react";
import { Styles } from "@halftax/ui";
import { useAuthenticator } from "@aws-amplify/ui-react";

const LoadingScreen: FC = () => {
  const { route } = useAuthenticator((ctx) => [ctx.route]);

  const styles: Styles = {
    loadingScreen: (theme) => ({
      position: "fixed",
      visibility: "visible",
      bgcolor: theme.palette.secondary.main,
      width: "100vw",
      height: "100vh",
      zIndex: 9999,

      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      "& .halftax-logo": {
        width: "16rem",
      },

      transition: [
        theme.transitions.create(["opacity"], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.leavingScreen,
          delay: 400,
        }),
        theme.transitions.create(["visibility"], {
          duration: 0,
          delay: 400 + theme.transitions.duration.leavingScreen,
        }),
      ].join(","),
    }),
    loadingScreenHidden: {
      opacity: 0,
      visibility: "hidden",
    },
  };

  return (
    <Box sx={[styles.loadingScreen, route !== "idle" && styles.loadingScreenHidden]}>
      <img src="/logo.svg" alt="Halftax Logo" className="halftax-logo" />
    </Box>
  );
};

export default LoadingScreen;

import { Box, Grid, Skeleton } from "@mui/material";
import { Contact, ContactType } from "@/types/contact";
import { FC, useEffect, useState } from "react";

import ContactCard from "@/components/common/ContactCard";
import { Styles } from "@halftax/ui";
import { useGetCounty } from "@/api/county";

export interface Props {
  countyId: number | string;
}

const CountyContactTab: FC<Props> = ({ countyId }) => {
  const { data, isSuccess } = useGetCounty(countyId);
  const [contacts, setContacts] = useState<Contact[]>([]);

  useEffect(() => {
    if (data) {
      const officeTypeContactIndex = data.contacts.findIndex(
        (contact) => contact.type === ContactType.Office,
      );

      if (officeTypeContactIndex) {
        data.contacts.unshift(data.contacts.splice(officeTypeContactIndex, 1)[0]);
      }
      setContacts(data.contacts);
    }
  }, [data]);

  const styles: Styles = {
    map: (theme) => ({
      backgroundColor: theme.palette.secondary.light,
      borderRadius: "0.5rem",
      width: "100%",
      height: "100%",
      maxHeight: "12rem",
    }),
  };

  return (
    <Grid container>
      <Grid item xs={9}>
        <Grid container>
          {isSuccess ? (
            contacts.map((item, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} xl>
                <ContactCard {...item} />
              </Grid>
            ))
          ) : (
            <>
              <Grid item xs={12} sm={6} md={4} xl>
                <ContactCard skeleton />
              </Grid>
              <Grid item xs={12} sm={6} md={4} xl>
                <ContactCard skeleton />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs={3} sx={{ pr: 5, py: 3 }}>
        {isSuccess ? (
          <Box sx={styles.map} />
        ) : (
          <Skeleton sx={{ transform: "none", height: "100%" }} />
        )}
      </Grid>
    </Grid>
  );
};

export default CountyContactTab;

import { FC } from "react";
import { getDevDocs } from "@/api";
import { useQuery } from "@tanstack/react-query";

const DevDocs: FC = () => {
  useQuery(["docs"], () => getDevDocs(), {
    onSuccess: ({ url }) => {
      window.location.href = url;
    },
  });

  return null;
};

export default DevDocs;

import { Dialog, DialogTitle, Grid, IconButton, LinearProgress } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { WindowClose as CloseDialogIcon } from "mdi-material-ui";
import { ContactEditForm } from "@/components/common/dialogs/contactEdit/ContactEditForm";
import { EditContact, EditOwnerContact } from "@/types/contact";
import { useConfirmationDialog } from "@/components/common/ConfirmationDialog";

export type SetSubmitting = (isSubmitting: boolean) => void;

type Props = {
  contactsList: EditContact[];
  ownerContactValue: EditOwnerContact | null;
  onClose: () => void;
  onSave: (
    contacts: EditContact[],
    owner: EditOwnerContact | null,
    setSubmitting: SetSubmitting,
  ) => void;
};

export const ContactEdit: FC<Props> = ({ onClose, onSave, contactsList, ownerContactValue }) => {
  const [open, setOpen] = useState(true);
  const [dirtyForm, setDirtyForm] = useState(false);
  const [contacts, setContacts] = useState<EditContact[]>([]);
  const [ownerContact, setOwnerContact] = useState<EditOwnerContact | null>(null);
  const [loading, setLoading] = useState(true);

  const { confirm: confirmDiscard } = useConfirmationDialog({
    title: "You have unsaved changes",
    description: "Do you want to discard them?",
    confirmLabel: "Discard",
    cancelLabel: "Keep editing",
  });

  useEffect(() => {
    setContacts(contactsList);
    setLoading(false);
  }, [contactsList]);

  useEffect(() => {
    setOwnerContact(ownerContactValue);
    setLoading(false);
  }, [ownerContactValue]);

  const dialogCloseHandler = () => {
    if (dirtyForm) {
      confirmDiscard(() => setOpen(false));
    } else {
      setOpen(false);
    }
  };

  return (
    <Dialog
      open={open}
      TransitionProps={{
        onExited: () => onClose(),
      }}
      onClose={dialogCloseHandler}
      scroll="body"
      maxWidth="lg"
    >
      <DialogTitle>
        <Grid container justifyContent="space-between">
          <Grid item>Edit Contact Information</Grid>
          {dialogCloseHandler && (
            <Grid item sx={{ lineHeight: "1.4" }}>
              <IconButton
                onClick={dialogCloseHandler}
                sx={(theme) => ({ color: theme.palette.secondary.dark, p: 0 })}
              >
                <CloseDialogIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </DialogTitle>
      <Grid container sx={{ width: 1000 }}>
        {loading && <LinearProgress sx={{ width: 1 }} />}
        {contacts?.length && (
          <ContactEditForm
            contacts={contacts}
            ownerContact={ownerContact}
            onSave={onSave}
            setDirtyForm={setDirtyForm}
          />
        )}
      </Grid>
    </Dialog>
  );
};

import { Box, Toolbar } from "@mui/material";
import { FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import CountyDetails from "@/components/county/details/CountyDetails";
import CountyList from "@/components/county/CountyList";
import DevDocs from "@/components/DevDocs";
import Header from "@/components/Header";
import LeadList from "@/components/lead/LeadList";
import ProspectDetails from "@/components/prospect/ProspectDetails";
import ProspectList from "@/components/prospect/ProspectList";
import Sidebar from "@/components/Sidebar";
import { atomWithStorage } from "jotai/utils";
import { useAtom } from "jotai";
import { Dashboard } from "@/components/dashboard";
import { CustomerDetails, CustomersList } from "@/components/customers";
import { LeadDetails } from "@/components/lead";

export const openSidebarAtom = atomWithStorage("openSidebar", true);

const Main: FC = () => {
  const [openSidebar, setOpenSidebar] = useAtom(openSidebarAtom);

  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar open={openSidebar} onToggle={() => setOpenSidebar((prev) => !prev)} />
      <Header open={openSidebar} />

      <Box
        component="main"
        sx={(theme) => ({
          borderRadius: "1.5rem 0 0 1.5rem",
          bgcolor: theme.palette.background.default,
          minHeight: "100vh",
          flexGrow: 1,
        })}
      >
        <Toolbar />

        <Suspense fallback={<></>}>
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<Dashboard />} />

            <Route path="/counties">
              <Route index element={<CountyList />} />
              <Route path=":countyId" element={<CountyDetails />} />
            </Route>

            <Route path="/prospects">
              <Route index element={<ProspectList />} />
              <Route path=":prospectId" element={<ProspectDetails />} />
            </Route>

            <Route path="/leads">
              <Route index element={<LeadList />} />
              <Route path=":leadId" element={<LeadDetails />} />
            </Route>

            <Route path="/customers">
              <Route index element={<CustomersList />} />
              <Route path=":customerId" element={<CustomerDetails />} />
            </Route>

            <Route path="/billing" />
            <Route path="/templates" />
            <Route path="/settings" />

            <Route path="/docs" element={<DevDocs />} />
          </Routes>
        </Suspense>
      </Box>
    </Box>
  );
};

export default Main;

import {
  Plus as AddIcon,
  WindowClose as RemoveIcon,
  ClockOutline as TimeIcon,
} from "mdi-material-ui";
import { Box, IconButton, Stack, Typography } from "@mui/material";

import { FC } from "react";
import { Styles } from "@halftax/ui";

const Reminder: FC = () => {
  const styles: Styles = {
    reminderCard: (theme) => ({
      bgcolor: { xl: theme.palette.primary.lightest },
      borderLeft: { xl: `${theme.palette.other.border} 1px solid` },
      borderEndEndRadius: { xl: "0.5rem" },
      height: "100%",
      p: 3,
    }),
    addReminderButton: (theme) => ({
      bgcolor: theme.palette.primary.main,
      p: 0.25,

      ":hover": { bgcolor: theme.palette.primary.accent },
    }),
  };

  return (
    <Box sx={styles.reminderCard}>
      <Box sx={{ display: "flex", mb: 3, gap: 1 }}>
        <Box>
          <IconButton size="small" sx={styles.addReminderButton}>
            <AddIcon fontSize="small" />
          </IconButton>
        </Box>
        <Typography variant="subtitle1">Set a reminder</Typography>
      </Box>

      <Stack spacing={1}>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <TimeIcon fontSize="small" />
          <Typography variant="body2">Call on Wed 16:00</Typography>
          <IconButton color="error">
            <RemoveIcon fontSize="small" />
          </IconButton>
        </Box>
      </Stack>
    </Box>
  );
};

export default Reminder;

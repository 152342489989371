import { useGetProspect } from "@/api/prospect";
import ProspectTabContent from "@/components/prospect/ProspectTabContent";
import { FC } from "react";

type Props = {
  prospectId?: number;
};

const ProspectTab: FC<Props> = ({ prospectId }) => {
  const { data, isSuccess } = useGetProspect(prospectId);

  return isSuccess ? <ProspectTabContent {...data} /> : <ProspectTabContent skeleton />;
};

export default ProspectTab;

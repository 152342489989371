import { County, EditCounty } from "@/types/county";
import { getById, putById, queryKey } from "@/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const useGetCounty = (id: number | string) =>
  useQuery(queryKey(["counties", "detail", id]), () => getById<County>("counties", id));

export const useEditCounty = (id: number | string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: EditCounty) => putById<EditCounty, County>("counties", id, data),
    onSuccess: (data) => queryClient.setQueriesData(queryKey(["counties", "detail", id]), data),
  });
};

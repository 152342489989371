import { StateCode, stateLabels } from "@halftax/ui";
import {
  differenceInDays,
  format,
  formatDistanceToNowStrict,
  isToday,
  isYesterday,
  parseISO,
} from "date-fns";

import { Address } from "@/types/address";
import { PlaceholderEnum } from "@/types/util";
import { Skeleton } from "@mui/material";

export const empty = "-";

export const nameToInitials = (name: string, max = 2): string => {
  const namesArr = name.split(" ");

  if (name.length <= max) {
    return name.toUpperCase();
  }

  if (namesArr.length === 1 || max === 1) {
    return name.charAt(0).toUpperCase();
  }

  return [
    ...namesArr.slice(0, Math.min(namesArr.length - 1, max - 1)), // max given names
    namesArr[namesArr.length - 1], // last name
  ].reduce((acc, val) => acc + val.charAt(0).toUpperCase(), "");
};

export const displayAddress = (address?: Address) => {
  if (!address) {
    return empty;
  }

  return `${address.line1}, ${address.line2}
  ${address.city}, ${address.state} ${address.zip}`;
};

export const displayCurrency = (amount?: string | number, currency = "USD") => {
  if (!amount) {
    return empty;
  }

  if (typeof amount !== "number") {
    amount = Number(amount);
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    maximumFractionDigits: amount % 1 === 0 ? 0 : 2, // strip trailing zeros
  }).format(amount);
};

export const displayDate = ({
  ISOdate,
  formatString = "MM/dd/yyyy",
  showSkeleton = true,
}: {
  ISOdate?: string;
  formatString?: string;
  showSkeleton?: boolean;
}) =>
  ISOdate ? (
    format(parseISO(ISOdate), formatString)
  ) : showSkeleton ? (
    <Skeleton width={`${formatString.length}ch`} />
  ) : (
    empty
  );

export const displayFeatureDateDistance = (date: string | undefined) => {
  if (!date) return "";
  const dateInstance = new Date(date);
  const today = new Date();
  const diff = differenceInDays(dateInstance, today);
  if (diff < 0) return "";
  return `${formatDistanceToNowStrict(dateInstance)} left`;
};

export const displayURL = (url?: string) => {
  if (!url) {
    return;
  }

  const urlObject = new URL(url);
  const domain = urlObject.host.replace(/^www\./, "");
  const path = urlObject.pathname !== "/" ? urlObject.pathname : "";

  return `${domain}${path}`;
  // e. g. example.org, example.org/path/to/url
};

// TODO(Kornelijus): write util to easily get display values for various enums.
// At the moment, we don't have the specifics of what all the enums will look like for this project,
// so it just returns Unknown.

export const displayEnum = (enumValue?: PlaceholderEnum) => {
  if (!enumValue) {
    return empty;
  }

  return "Unknown";
};

export const displayState = (state_code?: StateCode) => {
  if (!state_code) {
    return;
  }

  return stateLabels[state_code];
};

export const addMissingProtocol = (url: string) => {
  try {
    return new URL(url).toString();
  } catch (error) {
    try {
      return new URL(`http://${url}`).toString();
    } catch (error) {
      return url;
    }
  }
};

export const getDaysDiffWithToday = (date: string) => {
  return differenceInDays(new Date(), new Date(date));
};

export const displayDateTitle = (date: string) => {
  const dateInstance = new Date(date);
  if (isToday(dateInstance)) {
    return "Today";
  } else if (isYesterday(dateInstance)) {
    return "Yesterday";
  }
  return format(dateInstance, "MM/dd/yyyy");
};

export const displaySet = (values?: number | string | number[] | string[]) => {
  if (!values) {
    return empty;
  }

  if (values instanceof Array) {
    if (values.length === 0 || (values.length === 1 && values[0] === null)) {
      return empty;
    }
    return [...new Set<number | string>(values)].join(", ");
  }

  return values;
};

export const displayStringWithEnding = (ending: string, value?: string) => {
  if (!value) {
    return empty;
  } else {
    return value + " " + ending;
  }
};

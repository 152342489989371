import {
  AnyObject,
  Flags,
  Maybe,
  ObjectSchema,
  Schema,
  ValidationError,
  addMethod,
  array,
  boolean,
  date,
  mixed,
  number,
  object,
  string,
} from "yup";

import { Address } from "@/types/address";
import { AppealsProcessType } from "@/types/property";
import { ContactType } from "@/types/contact";
import { PlaceholderEnum } from "@/types/util";
import { ProspectPropertyType } from "@/types/prospect";
import { StateCode } from "@halftax/ui";
import { StatusType } from "@/types/status";

declare module "yup" {
  interface ObjectSchema<
    TIn extends Maybe<AnyObject>,
    TContext = AnyObject,
    TDefault = any,
    TFlags extends Flags = "",
  > extends Schema<TIn, TContext, TDefault, TFlags> {
    atLeastOneOf: (list: string[]) => ObjectSchema<AnyObject>;
  }
}

addMethod<ObjectSchema<AnyObject, AnyObject, any, any>>(object, "atLeastOneOf", function (list) {
  return this.test(
    "atLeastOneOf",
    "${path} must have at least one of these fields: ${keys}",
    function (value: any) {
      const processedList = list.filter((item: string) => value[item] !== null);
      const emptyFields = processedList.filter(
        (item: string) =>
          (typeof value[item] === "object" && !Object.keys(value[item]).length) || !value[item],
      );

      const errors = list
        .map((item: string) => {
          return new ValidationError(
            `Must have at least one of these fields ${list.join(",")}`,
            value[item],
            `${this.path}.${item}`,
          );
        })
        .filter(Boolean);

      return processedList.length > emptyFields.length || new ValidationError(errors);
    },
  );
});

export type LikeStringEnum<T extends string> = {
  [k: string]: T;
};

export const stringEnum = <T extends string>(values: LikeStringEnum<T>) => {
  return string<T | "">().default("").oneOf(Object.values(values));
};

export const zip = string().min(5).label("ZIP Code");

export const state = stringEnum(StateCode).label("State");

export const year = number()
  .positive("Year must be a positive number")
  .integer("Year must be a whole number");

export const addressSchema: ObjectSchema<Omit<Address, "id">> = object({
  line1: string().required().label("Address Line 1"),
  line2: string().required().label("Address Line 2"),
  city: string().required().label("City"),
  state: state.required(),
  zip: zip.required(),
  county: string().required().label("County"),
});

export const prospectEditSchema = object({
  address: addressSchema,
  last_sale_amount: number()
    .positive()
    .required()
    .label("Last sale amount")
    .typeError("Must be a number"),
  last_sale_date: date().required().label("Last sale date").typeError("Must be a valid date"),
  pin: string().required().label("Property PIN"),
  size_building: number()
    .positive()
    .required()
    .label("Building size")
    .typeError("Must be a number of square feet"),
  size_land: number()
    .positive()
    .required()
    .label("Land size")
    .typeError("Must be a number of acres"),
  // TODO(Kornelijus): add oneOf when fixed https://github.com/jquense/yup/issues/1230#issuecomment-1223321881
  type_property: mixed<ProspectPropertyType>().required().label("Property type"),
  type_zoning: mixed<PlaceholderEnum>().required().label("Zoning type"),
  year_built: year.required().label("Year when property built"),
  year_renovated: year.required().label("Year when property last renovated"),
});

const url = /^(?:(ftp|http|https):\/\/)?(?:[\w-]+\.)+[a-z]{2,6}\/?$/;
export const linkAddSchema = object({
  name: string().required("Required").default(""),
  url: string()
    .required("Required")
    .default("")
    .matches(url, "Incorrect link format. Please use the following format: www.example.com"),
});

function testForRequireIfExistAddress(value?: string) {
  const { phone, email, type } = this?.from?.[1].value;
  if (type === ContactType.Owner) {
    return !!value;
  }
  return !(!value && !phone && !email);
}

function testForRequireDueToType() {
  const { type, description } = this?.parent;
  return type === ContactType.Owner || description;
}

export const contactEditSchema = object()
  .shape(
    {
      address: object({
        line1: string().test("test", "Required", testForRequireIfExistAddress),
        line2: string().test("test", "Required", testForRequireIfExistAddress),
        city: string().test("test", "Required", testForRequireIfExistAddress),
        state: state.test("test", "Required", testForRequireIfExistAddress),
        zip: zip.test("test", "Required", testForRequireIfExistAddress),
        county: string().test("test", "Required", testForRequireIfExistAddress),
      }).nullable(),
      name: string().required("Required"),
      description: string().test("test", "Required", testForRequireDueToType),
      website: string().nullable(),
      phone: string().min(10, "10 digits").max(10, "10 digits"),
      email: string(),
    },
    [["phone", "email"]],
  )
  .atLeastOneOf(["phone", "email", "address"]);

export const contactsEditSchema = object().shape({
  contacts: array().of(contactEditSchema),
});

export const statusUpdateSchema = object({
  status: number().default(StatusType.DataUploaded),
});

export const valueAddSchema = object({
  marketCap: number().typeError("you must specify data in number type").default(0),
  valueBoost: number().typeError("you must specify data in number type").default(0),
  valueIncrease: number().typeError("you must specify data in number type").default(0),
});

export const appealsProcessSchema = object({
  status: number().default(AppealsProcessType.OfficialNotificationIsIssued),
});

export const propertyAddSchema = object({
  pin: string().default(""),
});

export const appealResultSchema = object({
  appealLandValueBefore: number().typeError("you must specify data in number type").default(0),
  appealLandValueAfter: number().typeError("you must specify data in number type").default(0),

  appealBuildingValueBefore: number().typeError("you must specify data in number type").default(0),
  appealBuildingValueAfter: number().typeError("you must specify data in number type").default(0),

  appealTotalValueBefore: number().typeError("you must specify data in number type").default(0),
  appealTotalValueAfter: number().typeError("you must specify data in number type").default(0),

  appealTaxBillBefore: number().typeError("you must specify data in number type").default(0),
  appealTaxBillAfter: number().typeError("you must specify data in number type").default(0),
});

export const pinLinkSchema = object({
  pins: array()
    .of(object().shape({ id: number().required() }).required())
    .required("Required")
    .min(1, "Required")
    .default([]),
});

export const createListSchema = object({
  tag: string().required("Required").default(""),
  ids: array()
    .of(object().shape({ id: number().required() }).required())
    .required("Required")
    .min(1, "Required")
    .default([]),
});

const isAddressFilled = (address: Address) => {
  if (address) {
    const { city, county, line1, state, zip, line2 } = address;
    return Boolean(city || county || line1 || state || zip || line2);
  }
  return false;
};

const secondaryContactAddSchema = object().shape(
  {
    address: object()
      .shape(
        {
          line1: string()
            .default("")
            .when(["line2", "city", "state", "zip", "county"], {
              // eslint-disable-next-line max-params
              is: (line2: string, city: string, state: number, zip: number, county: string) =>
                line2 || city || state || zip || county,
              then: (schema) => schema.required("Required"),
            }),
          line2: string(),
          city: string()
            .default("")
            .when(["line1", "line2", "state", "zip", "county"], {
              // eslint-disable-next-line max-params
              is: (line1: string, line2: string, state: number, zip: number, county: string) =>
                line1 || line2 || state || zip || county,
              then: (schema) => schema.required("Required"),
            }),
          state: mixed<StateCode>().when(["line1", "line2", "city", "zip", "county"], {
            // eslint-disable-next-line max-params
            is: (line1: string, line2: string, city: string, zip: number, county: string) =>
              line1 || line2 || city || zip || county,
            then: (schema) => schema.required("Required"),
          }),
          zip: zip.when(["line1", "line2", "city", "state", "county"], {
            // eslint-disable-next-line max-params
            is: (line1: string, line2: string, city: string, state: number, county: string) =>
              line1 || line2 || city || state || county,
            then: (schema) => schema.required("Required"),
          }),
          county: string()
            .default("")
            .when(["line1", "line2", "state", "zip", "city"], {
              // eslint-disable-next-line max-params
              is: (line1: string, line2: string, state: number, zip: number, city: string) =>
                line1 || line2 || state || zip || city,
              then: (schema) => schema.required("Required"),
            }),
        },
        [
          ["county", "line1"],
          ["line2", "line1"],
          ["city", "line1"],
          ["state", "line1"],
          ["zip", "line1"],
          ["county", "city"],
          ["line2", "city"],
          ["line1", "city"],
          ["state", "city"],
          ["zip", "city"],
          ["county", "state"],
          ["line2", "state"],
          ["line1", "state"],
          ["city", "state"],
          ["zip", "state"],
          ["county", "zip"],
          ["line2", "zip"],
          ["line1", "zip"],
          ["city", "zip"],
          ["state", "zip"],
          ["line1", "county"],
          ["line2", "county"],
          ["city", "county"],
          ["state", "county"],
          ["zip", "county"],
        ],
      )
      .nullable(),
    name: string()
      .default("")
      .when(["email", "description", "phone", "address"], {
        // eslint-disable-next-line max-params
        is: (email: string, description: string, phone: string, address: Address) =>
          email || description || phone || isAddressFilled(address),
        then: (schema) => schema.required("Required"),
      }),
    description: string(),
    phone: string()
      .default("")
      .when(["name", "description", "email", "address"], {
        // eslint-disable-next-line max-params
        is: (name: string, description: string, email: string, address: Address) =>
          name || description || email || isAddressFilled(address),
        then: (schema) => schema.required("Required").min(10, "10 digits").max(10, "10 digits"),
      }),
    email: string()
      .default("")
      .when(["name", "description", "phone", "address"], {
        // eslint-disable-next-line max-params
        is: (name: string, description: string, phone: string, address: Address) =>
          name || description || phone || isAddressFilled(address),
        then: (schema) => schema.required("Required"),
      }),
    type: number().default(ContactType.General),
  },
  [
    ["address", "name"],
    ["description", "name"],
    ["email", "name"],
    ["phone", "name"],
    ["address", "phone"],
    ["name", "phone"],
    ["description", "phone"],
    ["email", "phone"],
    ["address", "email"],
    ["name", "email"],
    ["description", "email"],
    ["phone", "email"],
  ],
);

const emptyAddressSchema: ObjectSchema<Omit<Address, "id">> = object({
  line1: string().required().label("Address Line 1").default(""),
  line2: string().default(""),
  city: string().required().label("City").default(""),
  state: state.required(),
  zip: zip.required().default(""),
  county: string().required().label("County").default(""),
});

const contactAddSchema = object().shape({
  address: emptyAddressSchema,
  name: string().required("Required").label("Name").min(3, "Minimum 3 characters").default(""),
  description: string().label("Job description"),
  phone: string().required().label("Phone").default("").min(10, "10 digits").max(10, "10 digits"),
  email: string().required().label("Email").default(""),
  type: number().default(ContactType.General),
});

export const leadAddSchema = object({
  owner: object({
    name: string().required("Required").label("Name").min(3, "Minimum 3 characters").default(""),
    address: emptyAddressSchema,
    type: number().default(ContactType.Owner),
  }),
  llc: boolean().default(false),
  primary_contact: contactAddSchema,
  secondary_contact: secondaryContactAddSchema,
  main_prospect: object({
    address: emptyAddressSchema,
    pin: string().required().label("Property PIN").default(""),
    type_property: mixed<ProspectPropertyType>().required().label("Property type"),
  }),
});

import {
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { displayDate, displayFeatureDateDistance, displayURL, empty } from "@/utils/display";

import { County } from "@/types/county";
import { FC } from "react";
import { PartialSkeleton } from "@/types/util";

export type AppealsTabProps = Pick<
  County,
  "deadline_appeal_annual" | "link_appeal_form" | "link_property_database"
>;

const AppealsTab: FC<PartialSkeleton<AppealsTabProps>> = ({
  deadline_appeal_annual,
  link_appeal_form,
  link_property_database,
}) => (
  <TableContainer>
    <Table
      sx={(theme) => ({
        ".MuiTableCell-root": {
          ":first-of-type": { pr: 1, ...theme.typography.subtitle2 },
          ...theme.typography.body2,
        },
      })}
    >
      <TableBody>
        <TableRow>
          <TableCell>Annual Deadline for Appeals</TableCell>
          <TableCell>
            <Grid container justifyContent="space-between">
              <Grid item>
                {displayDate({ ISOdate: deadline_appeal_annual, showSkeleton: false })}
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    color: (theme) => theme.palette.error.light,
                    fontStyle: "italic",
                  }}
                >
                  {displayFeatureDateDistance(deadline_appeal_annual)}
                </Typography>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Link to Appeal Form</TableCell>
          <TableCell>
            {link_appeal_form ? (
              <Link href={link_appeal_form}>{displayURL(link_appeal_form)}</Link>
            ) : (
              <Typography>{empty}</Typography>
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Link to Property Database File</TableCell>
          <TableCell>
            {link_property_database ? (
              <Link href={link_property_database}>{displayURL(link_property_database)}</Link>
            ) : (
              <Typography>{empty}</Typography>
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

export default AppealsTab;

import { EntityType } from "@/types/common";

export enum TagType {
  List = 1,
  Folder = 2,
}

export enum TagStatus {
  Active = 1,
  Inactive = 2,
}

export interface Tag {
  id: number;
  tag: string;
  table: EntityType;
  type: TagType;
  status: TagStatus;
}

export interface CreateTag extends Pick<Tag, "tag" | "table" | "type"> {
  status?: TagStatus;
  ids: number[];
}

import { Select, SelectProps } from "formik-mui";

import { FC } from "react";
import { MenuItem } from "@mui/material";
import { PlaceholderEnum } from "@/types/util";

const PlaceholderEnumSelect: FC<SelectProps> = (props) => (
  <Select {...props}>
    {Object.values(PlaceholderEnum)
      .filter((value) => typeof value === "number")
      .map((value: any) => (
        <MenuItem key={value} value={value}>
          {PlaceholderEnum[value]}
        </MenuItem>
      ))}
  </Select>
);

export default PlaceholderEnumSelect;

import { Property } from "@/types/property";
import { Grid } from "@mui/material";
import { FC } from "react";
import { PropertyAddButton, PropertyTile } from "@/components/property";
import { OnlyPick } from "@/types/util";

type Props = {
  leadId: number;
  properties: OnlyPick<Property, "id">[];
  selectProperty: (index: number) => void;
  selectedProperty: number;
  displayStatus?: boolean;
};

export const PropertiesList: FC<Props> = ({
  leadId,
  properties,
  selectProperty,
  selectedProperty,
  displayStatus,
}) => {
  return (
    <Grid container spacing={3}>
      {properties.map(({ id }, key) => (
        <Grid key={key} item xs={3} onClick={() => selectProperty(key)}>
          <PropertyTile
            propertyId={id}
            active={selectedProperty === key}
            displayStatus={displayStatus}
          />
        </Grid>
      ))}
      <Grid item xs={3}>
        <PropertyAddButton leadId={leadId} />
      </Grid>
    </Grid>
  );
};

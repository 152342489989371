import { StatusType, statusTypeLabels } from "@/types/status";
import { Box, Grid } from "@mui/material";
import { FC } from "react";
import { getState, StatusItem, ItemState } from "@/components/common/status";
import { Styles } from "@halftax/ui";

type Props = {
  status: StatusType;
};

export const StatusTab: FC<Props> = ({ status }) => {
  const styles: Styles = {
    activeLine: {
      "&::after": {
        content: "''",
        width: "calc(100% - 48px)",
        height: "1px",
        bgcolor: (theme) => theme.palette.secondary.main,
        display: "block",
        position: "absolute",
        right: "calc(50% + 24px)",
        top: "calc(50% - 20px)",
      },
    },
    wrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 1,
      minHeight: "9.125rem",
    },
    item: {
      position: "relative",
    },
  };

  return (
    <Box sx={styles.wrapper}>
      <Grid container>
        {Object.keys(statusTypeLabels).map((label, index) => (
          <Grid
            item
            key={index}
            xs={3}
            sx={[
              styles.item,
              index > 0 && styles.activeLine,
              getState(index + 1, status) === ItemState.NotStarted && {
                "&::after": {
                  bgcolor: (theme) => theme.palette.text.disabled,
                },
              },
            ]}
          >
            <StatusItem step={index} status={status} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

import { Forum as ChatIcon } from "mdi-material-ui";
import EmptyTabMessage from "@/components/common/EmptyTabMessage";

const MessagesTab = () => (
  <EmptyTabMessage
    icon={<ChatIcon />}
    title="No messages yet"
    description="Start sending messages and they will appear here"
  />
);

export default MessagesTab;

import { FC, useState } from "react";
import { EyeOffOutline as HidePasswordIcon, EyeOutline as ShowPasswordIcon } from "mdi-material-ui";
import { TextField, TextFieldProps } from "formik-mui";

import { IconButton } from "@mui/material";

const PasswordInput: FC<TextFieldProps> = (props) => {
  const [displayPassword, setDisplayPassword] = useState(false);
  const toggleDisplayPassword = () => setDisplayPassword((prev) => !prev);

  return (
    <TextField
      type={displayPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <IconButton sx={{ p: 0.5 }} onClick={toggleDisplayPassword}>
            {displayPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
          </IconButton>
        ),
      }}
      {...props}
    />
  );
};

export default PasswordInput;

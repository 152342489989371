import { DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { Dispatch, FC, SetStateAction } from "react";
import { WindowClose as CloseDialogIcon } from "mdi-material-ui";
import { Styles } from "@halftax/ui";
import { NotificationsFilter } from "@/components/common/dialogs/notifications";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

type Props = {
  notificationsFilterUpdate: Dispatch<SetStateAction<NotificationsFilter | string>>;
  notificationsFilter: NotificationsFilter | string;
  onClose?: () => void;
  loading: boolean;
};

export const NotificationsHeader: FC<Props> = ({
  onClose,
  notificationsFilter,
  notificationsFilterUpdate,
  loading,
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    notificationsFilterUpdate(event.target.value);
  };

  const styles: Styles = {
    wrapper: {
      background: (theme) => theme.palette.primary.main,
      px: 3,
      py: 2,
      mb: 0,
    },
    select: {
      "& .MuiSelect-select": {
        p: 0,
        "& .MuiTypography-root": { mr: 2 },
      },
      "& .MuiOutlinedInput-notchedOutline": { display: "none" },
    },
    closeWrapper: {
      justifyCcontent: "center",
      alignItems: "center",
      display: "flex",
      lineHeight: "1.4",
    },
    closeIcon: {
      color: (theme) => theme.palette.secondary.dark,
      p: 0,
    },
  };

  return (
    <DialogTitle sx={styles.wrapper}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <FormControl sx={{ m: 0 }}>
            <Select
              disabled={loading}
              value={notificationsFilter}
              onChange={handleChange}
              sx={styles.select}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
              }}
              renderValue={(value) => <Typography variant="h6">{value}</Typography>}
            >
              {Object.values(NotificationsFilter).map((item, key) => (
                <MenuItem key={key} value={item}>
                  <Typography variant="body1">{item}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {onClose && (
          <Grid item sx={styles.closeWrapper}>
            <IconButton onClick={onClose} sx={styles.closeIcon}>
              <CloseDialogIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </DialogTitle>
  );
};

import { API_NAME, API_VERSION } from "@/config";
import { Only, OnlyPick, Search } from "@/types/util";
import { API } from "aws-amplify";
import { EntityType } from "@/types/common";
import { QueryClient } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";

import axios from "axios";
export type { AxiosError } from "axios";
export const isAxiosError = axios.isAxiosError;

import * as Sentry from "@sentry/react";

const errorSnackbar = (text: string) =>
  enqueueSnackbar(text || "Sorry something went wrong. Please try again later.", {
    variant: "error",
  });

export const handleQueryError = (error: unknown) => {
  if (error instanceof Error) {
    if (isAxiosError(error)) {
      errorSnackbar(error?.response?.data?.Message);
    }

    Sentry.captureException(error);
  }
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5000,
      onError: (error) => {
        handleQueryError(error);
      },
    },
    mutations: {
      onError: (error) => {
        handleQueryError(error);
      },
    },
  },
});

export const updateNotify = (identityId: string) =>
  API.put(API_NAME, "config/start", {
    body: { identityId },
    queryStringParameters: {
      api: API_VERSION,
    },
  });

export const post = <T, TResponse = T>(entity: EntityType, body: T): Promise<TResponse> =>
  API.post(API_NAME, entity, { body });

export const postByUrl = <T, TResponse = T>(url: string, body: T): Promise<TResponse> =>
  API.post(API_NAME, url, { body });

export const getByIdOnly =
  <T extends object>() =>
  <O extends readonly (Only<T> & string)[]>(
    entity: EntityType,
    id: number | string,
    only: O,
    // eslint-disable-next-line max-params
  ): Promise<OnlyPick<T, (typeof only)[number]>> =>
    API.get(API_NAME, `${entity}/${id}`, {
      queryStringParameters: { only: only.join() },
    });

export const getById = <T>(entity: EntityType, id: number | string): Promise<T> =>
  API.get(API_NAME, `${entity}/${id}`, {});

export const putById = <T, TResponse = T>(
  entity: EntityType,
  id: number | string,
  body: T,
  // eslint-disable-next-line max-params
): Promise<TResponse> => API.put(API_NAME, `${entity}/${id}`, { body });

export const deleteById = <T>(entity: EntityType, id: number | string): Promise<T> =>
  API.del(API_NAME, `${entity}/${id}`, {});

/**
 * Search and query for some fields of the given entity.
 *
 * This has to be called like `search<T>()(params)` because of limitations with Typescript default generics:
 * https://github.com/Microsoft/TypeScript/issues/10571
 */
export const search =
  <T extends object>() =>
  <O extends readonly (Only<T> & string)[]>({
    entity,
    only,
    by,
    ...params
  }: Search<O>): Promise<OnlyPick<T, (typeof only)[number]>[]> =>
    API.get(API_NAME, entity, {
      queryStringParameters: {
        only: only.join(),
        by: by ? by.join() : undefined,
        ...params,
      },
    });

export const getDevDocs = (): Promise<{ url: string }> => API.get(API_NAME, "dev/docs", {});

export type QueryKey<T extends unknown[] = unknown[], ID = number> =
  | [EntityType]
  | [EntityType, "list", ...T]
  | [EntityType, "detail", ID, ...T];

export const queryKey = (key: QueryKey<unknown[], number | string | undefined>): QueryKey => {
  if (key[1] === "detail") {
    const [entity, queryType, id, ...rest] = key;
    return [entity, queryType, Number(id), ...rest];
  }

  return key;
};

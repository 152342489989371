import { FC } from "react";
import { Field } from "formik";
import { Stack } from "@mui/material";
import { StateAutocomplete } from "@halftax/ui";
import { TextField } from "formik-mui";

export interface AddressField {
  label?: string;
  prefix?: string;
}

const AddressField: FC<AddressField> = ({ prefix, label }: AddressField) => {
  const nameWithPrefix = (name: string) => (prefix ? `${prefix}.${name}` : name);

  return (
    <>
      <Field
        component={TextField}
        name={nameWithPrefix("line1")}
        placeholder="Address Line 1"
        fullWidth
        sx={{ mb: 1 }}
        label={label}
      />
      <Field
        component={TextField}
        name={nameWithPrefix("line2")}
        placeholder="Address Line 2"
        fullWidth
        sx={{ mb: 1 }}
      />

      <Stack direction="row" spacing={1}>
        <Field
          component={TextField}
          name={nameWithPrefix("city")}
          placeholder="City"
          fullWidth
          sx={{ mb: 1 }}
        />
        <Field
          component={TextField}
          name={nameWithPrefix("zip")}
          placeholder="ZIP"
          fullWidth
          sx={{ mb: 1 }}
        />
      </Stack>

      <Stack direction="row" spacing={1}>
        <Field component={StateAutocomplete} name={nameWithPrefix("state")} fullWidth />
        <Field
          component={TextField}
          name={nameWithPrefix("county")}
          placeholder="County"
          fullWidth
        />
      </Stack>
    </>
  );
};

export default AddressField;

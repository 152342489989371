import { Button, DialogActions, DialogContent, DialogTitle, Divider, Grid } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";

import { FC, useState } from "react";
import { appealResultSchema } from "@/validation";
import { AppealResultRow } from "@/components/common/dialogs/appealResults";
import Dialog, { DialogProps } from "@/components/common/Dialog";

export type SetSubmitting = (isSubmitting: boolean) => void;

export interface AppealResultsProps extends DialogProps {
  disableAfter?: boolean;
}

export const AppealResults: FC<AppealResultsProps> = ({ disableAfter, ...rest }) => {
  const [open, setOpen] = useState(true);

  const appealResultsRows = [
    {
      rowTitle: "Land Value",
      formPrefix: "appealLandValue",
    },
    {
      rowTitle: "Building Value",
      formPrefix: "appealBuildingValue",
    },
    {
      rowTitle: "Total Value",
      formPrefix: "appealTotalValue",
    },
    {
      rowTitle: "Tax Bill",
      formPrefix: "appealTaxBill",
    },
  ];

  const formik = useFormik({
    validationSchema: appealResultSchema,
    initialValues: appealResultSchema.cast({}),
    onSubmit: (values, { setSubmitting }) => {
      setOpen(false);
      setSubmitting(false);
    },
  });

  return (
    <Dialog open={open} scroll="body" maxWidth="sm" fullWidth closeButton {...rest}>
      <DialogTitle>Appeal Results</DialogTitle>
      <FormikProvider value={formik}>
        <Form>
          <DialogContent>
            <Grid container>
              {appealResultsRows.map((row) => (
                <Grid item key={row.formPrefix} xs={12}>
                  <AppealResultRow {...row} disableAfter={disableAfter} />
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button type="submit" disabled={formik.isSubmitting}>
              Save
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

import { Avatar, Grid, IconButton, Typography } from "@mui/material";
import { FC, useState } from "react";
import { MenuDown, MenuUp } from "mdi-material-ui";

import { Notification } from "@/components/common/dialogs/notifications/types";
import { Styles } from "@halftax/ui";
import { displayDate } from "@/utils/display";
import { getNotificationIcon } from "@/components/common/dialogs/notifications/utils";

type Props = {
  notification: Notification;
  newNotification: boolean;
};

export const NotificationsItem: FC<Props> = ({ notification, newNotification }) => {
  const [openDescription, setOpenDescription] = useState(false);
  const { title, description, date, type } = notification;

  const expandableText = description.length > 32;

  const styles: Styles = {
    wrapper: {
      p: 2,
      justifyContent: "space-between",
      borderTop: (theme) => `1px solid ${theme.palette.other.border}`,
    },
    newWrapper: (theme) => ({
      bgcolor: theme.palette.primary.lightest,
      borderColor: theme.palette.common.white,
    }),
    time: {
      textAlign: "right",
    },
    arrow: {
      textAlign: "right",
    },
    description: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    descriptionOpen: {
      whiteSpace: "normal",
    },
  };

  return (
    <Grid container sx={[styles.wrapper, newNotification && styles.newWrapper]}>
      <Grid
        item
        xs={2}
        sx={{
          pr: 2,
        }}
      >
        <Avatar variant="notification">{getNotificationIcon(type, title)}</Avatar>
      </Grid>
      <Grid item xs={8}>
        <Grid container direction="column">
          <Grid item sx={{ mb: 1 }}>
            <Typography variant="subtitle2" sx={styles.title}>
              {title}
            </Typography>
          </Grid>
          <Grid item sx={{ width: 1 }}>
            <Typography
              variant="body2"
              sx={[styles.description, openDescription && styles.descriptionOpen]}
            >
              {description}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="subtitle2" sx={styles.time}>
              {displayDate({ ISOdate: date, formatString: "kk:mm" })}
            </Typography>
          </Grid>
          <Grid item sx={styles.arrow}>
            {expandableText && (
              <IconButton size="small" onClick={() => setOpenDescription((prev) => !prev)}>
                {openDescription ? <MenuUp /> : <MenuDown />}
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

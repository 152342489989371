import {
  NotificationsFilter,
  NotificationsType,
} from "@/components/common/dialogs/notifications/types";
import { nameToInitials } from "@/utils/display";
import {
  Cash as BillingIcon,
  CityVariantOutline as CountiesIcon,
  AccountMultiplePlusOutline as ProspectsIcon,
  AccountGroupOutline as LeadsIcon,
  AccountTie as CustomersIcon,
  TextBoxPlusOutline as TemplateIcon,
} from "mdi-material-ui";

export const getNotificationType = (
  filter: NotificationsFilter | string,
): NotificationsType | string => {
  if (Object.keys(NotificationsType).includes(filter)) {
    return Object(NotificationsType)[filter];
  }
  return "";
};

export const getNotificationIcon = (type: NotificationsType, title: string) => {
  switch (type) {
    case NotificationsType.Messages:
      return nameToInitials(title);
    case NotificationsType.Billing:
      return <BillingIcon />;
    case NotificationsType.Counties:
      return <CountiesIcon />;
    case NotificationsType.Prospects:
      return <ProspectsIcon />;
    case NotificationsType.Leads:
      return <LeadsIcon />;
    case NotificationsType.Customers:
      return <CustomersIcon />;
    case NotificationsType.Templates:
      return <TemplateIcon />;
  }
};

import { DatePickerProps, DatePicker as FormikMuiDatePicker } from "formik-mui-x-date-pickers";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FC } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";

const DatePicker: FC<DatePickerProps> = (props) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <FormikMuiDatePicker inputFormat="MM/dd/yyyy" {...props} />
  </LocalizationProvider>
);

export default DatePicker;

import { Field } from "formik";
import { FC } from "react";
import { TextField } from "formik-mui";
import { Box, Grid, Typography } from "@mui/material";
import { Styles } from "@halftax/ui";

type Props = {
  rowTitle: string;
  formPrefix: string;
  disableBefore?: boolean;
  disableAfter?: boolean;
};

export const AppealResultRow: FC<Props> = ({
  formPrefix,
  rowTitle,
  disableBefore,
  disableAfter,
}) => {
  const styles: Styles = {
    inputWrapper: {
      position: "relative",
      "& .MuiInputBase-input": {
        pl: 2,
      },
    },
    icon: {
      color: (theme) => theme.palette.common.black,
      position: "absolute",
      left: 12,
      top: 8,
      zIndex: 1,
    },
    iconDisabled: {
      color: (theme) => theme.palette.text.disabled,
    },
  };

  return (
    <Grid container columnSpacing={1}>
      <Grid item xs={4}>
        <Typography variant="h6">{rowTitle}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Box sx={styles.inputWrapper}>
          <Field
            name={`${formPrefix}Before`}
            label="Before Appeal"
            component={TextField}
            InputProps={{
              startAdornment: (
                <Box sx={[styles.icon, Boolean(disableBefore) && styles.iconDisabled]}>$</Box>
              ),
            }}
            disabled={disableBefore}
            fullWidth
          />
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box sx={styles.inputWrapper}>
          <Field
            name={`${formPrefix}After`}
            label="After Appeal"
            component={TextField}
            InputProps={{
              startAdornment: (
                <Box sx={[styles.icon, Boolean(disableAfter) && styles.iconDisabled]}>$</Box>
              ),
            }}
            disabled={disableAfter}
            fullWidth
          />
        </Box>
      </Grid>
    </Grid>
  );
};

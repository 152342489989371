import { StateCode } from "@halftax/ui";

export type Appeal = {
  name: string;
  value: number;
};

export type Tile = {
  label: string;
  value: number;
};

export type Revenue = {
  date: string;
  value: number;
};

export enum Direction {
  Up = "up",
  Down = "down",
}

export type PropertyState = {
  code: StateCode;
  value: number;
  change: string;
  direction: Direction;
};

export type Property = {
  total: number;
  states: PropertyState[];
};

export type Dashboard = {
  general: Tile[];
  appeals: Appeal[];
  revenue: Revenue[];
  properties: Property;
};

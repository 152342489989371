import { Alert, Box, Button, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Field, Form, FormikProvider, useFormik } from "formik";
import { object, string } from "yup";

import CloseDialogButton from "@/components/common/CloseDialogButton";
import { AccountPlusOutline as CreateAccountIcon } from "mdi-material-ui";
import PasswordTextField from "@/components/auth/PasswordTextField";
import { Styles } from "@halftax/ui";
import { TextField } from "formik-mui";
import { useAuthenticator } from "@aws-amplify/ui-react";

const signUpSchema = object({
  username: string()
    .required("Required")
    .min(3, "Minimum 3 characters")
    .matches(/^[a-z0-9._]+$/, "Username must consist of letters, numbers, underscores or dots")
    .default(""),
  password: string()
    .required("Required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number",
    )
    .default(""),
  // Note: don't change this to require a minimum character count for names:
  // https://www.kalzumeus.com/2010/06/17/falsehoods-programmers-believe-about-names/
  given_name: string().required("Required").default(""),
  family_name: string().required("Required").default(""),
  email: string().required("Required").email("Must be valid email address").default(""),
  phone_number: string()
    .required("Required")
    .min(11, "Phone number consists of at least 11 digits")
    .default(""),
});

const SignUpDialog: FC = () => {
  const [open, setOpen] = useState(false);
  const { route, toSignIn, toSignUp, isPending, error, submitForm } = useAuthenticator((ctx) => [
    ctx.route,
    ctx.toSignIn,
    ctx.toSignUp,
    ctx.isPending,
    ctx.error,
    ctx.submitForm,
  ]);
  const formik = useFormik({
    validationSchema: signUpSchema,
    initialValues: signUpSchema.cast({}),
    validateOnBlur: false,
    onSubmit: (values) => {
      submitForm({
        type: "SIGN_UP",
        ...values,
      });
    },
  });
  const { setSubmitting, isSubmitting } = formik;

  useEffect(() => {
    if (route === "signUp") setSubmitting(isPending);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPending]);

  const handleOpen = () => {
    toSignUp();
    setOpen(true);
  };

  const handleClose = () => {
    toSignIn();
    setOpen(false);
  };

  const styles: Styles = {
    containerDialog: {
      display: "flex",
      justifyContent: "center",
      padding: 3,
    },
    containerSignUp: {
      display: "flex",
      flexDirection: "column",
      minHeight: { sm: "47.5rem" },
      width: { xs: "100%", sm: "24.5rem" },
      padding: 3,
    },
    createAccountButton: (theme) => ({
      borderRadius: 0,
      boxSizing: "border-box",
      fontSize: "0.875rem",
      fontWeight: 500,
      letterSpacing: "0.15px",
      lineHeight: "1.25rem",
      padding: 0,

      ":hover": {
        background: "none",
        color: theme.palette.common.black,
      },
    }),
  };

  return (
    <>
      <Button
        variant="text"
        startIcon={<CreateAccountIcon />}
        sx={styles.createAccountButton}
        disableRipple
        disableElevation
        onClick={handleOpen}
      >
        Create an account
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <CloseDialogButton onClick={handleClose} />
        <DialogContent sx={styles.containerDialog}>
          <FormikProvider value={formik}>
            <Box component={Form} sx={styles.containerSignUp}>
              <Box sx={{ mb: { xs: 2, md: 6 } }}>
                <Typography variant="h4" sx={{ mb: 1 }}>
                  Register
                </Typography>
                <Typography
                  variant="body1"
                  sx={(theme) => ({ color: theme.palette.text.secondary })}
                >
                  Enter your details to create an account
                </Typography>
              </Box>

              <Stack direction="row" spacing={1}>
                <Field
                  component={TextField}
                  name="given_name"
                  label="First Name"
                  placeholder="Your name"
                />
                <Field
                  component={TextField}
                  name="family_name"
                  label="Last name"
                  placeholder="Your last name"
                />
              </Stack>

              <Field
                component={TextField}
                name="email"
                label="E-mail"
                type="email"
                placeholder="Your e-mail"
                fullWidth
              />
              <Field
                component={TextField}
                name="phone_number"
                label="Phone"
                placeholder="Your phone"
                type="tel"
                fullWidth
              />
              <Field
                component={TextField}
                name="username"
                label="Username"
                placeholder="Choose username"
                fullWidth
              />
              <Field
                component={PasswordTextField}
                name="password"
                label="Password"
                placeholder="Choose password"
                fullWidth
                sx={{ mb: 2 }}
              />

              {route === "signUp" && error && <Alert severity="error">{error}</Alert>}

              <Button
                size="medium"
                type="submit"
                disabled={isSubmitting}
                fullWidth
                sx={{ mt: "auto" }}
              >
                Create account
              </Button>
            </Box>
          </FormikProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SignUpDialog;

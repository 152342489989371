export const API_VERSION = "2023-03-08.01";
export const API_NAME = "api";
export const USE_LOCAL_SERVER = import.meta.env.VITE_USE_LOCAL_SERVER === "true";

export const COGNITO_POOLS = [
  {
    name: "dev",
    title: "Development",
    id: "eu-central-1_zSYFyLlqQ",
    arn: "arn:aws:cognito-idp:eu-central-1:128725411836:userpool/eu-central-1_zSYFyLlqQ",
    app: "6tgl0de2i19qkg996d0r0fhofc",
    identityPoolId: "eu-central-1:70c512a9-7197-4891-b96a-d97758e515cb",
  },
];

import { Grid, Typography, useTheme } from "@mui/material";
import { AlertCircleOutline as Alert } from "mdi-material-ui";
import { Styles } from "@halftax/ui";

export const ContactError = () => {
  const theme = useTheme();
  const styles: Styles = {
    errorWrapper: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      borderRadius: "0.5rem",
      px: 3,
      py: 2,
    },
  };
  return (
    <Grid container sx={styles.errorWrapper}>
      <Grid item sx={{ pr: 2 }}>
        <Alert />
      </Grid>
      <Grid item>
        <Typography variant="subtitle2">We found a few mistakes in your form</Typography>
        <Typography variant="body2">Please correct them before saving</Typography>
      </Grid>
    </Grid>
  );
};

import { Paper, Typography } from "@mui/material";
import { FC } from "react";
import { Tile } from "@/components/dashboard/types";

type Props = {
  tile: Tile;
  valueProcessor?: (value: number) => string | undefined;
};

export const InfoTile: FC<Props> = ({ tile, valueProcessor }) => {
  const { label, value } = tile;
  return (
    <Paper sx={{ textAlign: "center", p: 3 }} variant="outlined">
      <Typography variant="h5" sx={{ mb: 2, fontWeight: 500 }}>
        {valueProcessor ? valueProcessor(value) : value}
      </Typography>
      <Typography
        variant="body1"
        sx={{ color: (theme) => theme.palette.text.secondary, whiteSpace: "nowrap" }}
      >
        {label}
      </Typography>
    </Paper>
  );
};

import { Box, Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Field, Form, FormikProvider, useFormik } from "formik";
import { useEditProspect, useGetProspect } from "@/api/prospect";

import AddressField from "@/components/common/form/AddressField";
import DatePicker from "@/components/common/form/DatePicker";
import PlaceholderEnumSelect from "@/components/common/form/PlaceholderEnumSelect";
import EnumSelect from "@/components/common/form/EnumSelect";
import { Stack } from "@mui/material";
import { Styles } from "@halftax/ui";
import TabMenu from "@/components/common/TabMenu";
import { TextField } from "formik-mui";
import isEqual from "lodash/isEqual";
import { prospectEditSchema } from "@/validation";
import { ProspectPropertyType, prospectPropertyTypeLabels } from "@/types/prospect";
import { FC, useState } from "react";

import Dialog, { DialogProps } from "@/components/common/Dialog";

export interface ProspectEditDialogProps extends DialogProps {
  prospectId: number;
}

const ProspectEditDialog: FC<ProspectEditDialogProps> = ({ prospectId, ...rest }) => {
  const [open, setOpen] = useState(true);
  const { data, isSuccess } = useGetProspect(prospectId);
  const { mutateAsync } = useEditProspect(prospectId);

  const initialValues = prospectEditSchema.cast(data, { stripUnknown: true });

  const formik = useFormik({
    validationSchema: prospectEditSchema,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      // only send request to update if any values have changed
      if (!isEqual(values, initialValues)) {
        mutateAsync(values).then(() => {
          setOpen(false);
          setSubmitting(false);
        });
      } else {
        setOpen(false);
        setSubmitting(false);
      }
    },
  });

  const styles: Styles = {
    textFieldAdornment: (theme) => ({
      whiteSpace: "nowrap",
      color: theme.palette.text.disabled,
    }),
  };

  // TODO(Kornelijus): Add a loading state in case the cached prospect is stale and will take time to refetch
  if (!isSuccess) {
    return null;
  }

  return (
    <Dialog open={open} closeButton maxWidth="md" fullWidth {...rest}>
      <DialogTitle>Edit Property</DialogTitle>
      <FormikProvider value={formik}>
        <Form>
          <DialogContent sx={{ px: 0, pb: 0 }}>
            <TabMenu
              tabs={[
                {
                  label: "Identification",
                  content: (
                    <Box>
                      <Field
                        component={EnumSelect}
                        name="type_property"
                        label="Property Type"
                        placeholder="Select Property Type"
                        formControl={{ fullWidth: true }}
                        values={ProspectPropertyType}
                        labels={prospectPropertyTypeLabels}
                      />

                      <Field
                        name="pin"
                        label="Property PIN"
                        placeholder="Enter PIN"
                        component={TextField}
                        fullWidth
                      />
                      <AddressField label="Property Address" prefix="address" />
                    </Box>
                  ),
                },
                {
                  label: "Details",
                  content: (
                    <Box>
                      <Stack spacing={3} direction="row">
                        <Field
                          name="size_building"
                          label="Building Size"
                          component={TextField}
                          fullWidth
                          InputProps={{
                            endAdornment: <Box sx={styles.textFieldAdornment}>sq. ft.</Box>,
                          }}
                        />
                        <Field
                          name="size_land"
                          label="Land Size"
                          component={TextField}
                          fullWidth
                          InputProps={{
                            endAdornment: <Box sx={styles.textFieldAdornment}>acre</Box>,
                          }}
                        />
                      </Stack>

                      <Stack spacing={3} direction="row">
                        <Field
                          name="last_sale_amount"
                          label="Last Sale Amount"
                          component={TextField}
                          InputProps={{
                            endAdornment: <Box sx={styles.textFieldAdornment}>$</Box>,
                          }}
                          fullWidth
                        />
                        <Field
                          name="last_sale_date"
                          label="Last Sale Date"
                          component={DatePicker}
                          textField={{ fullWidth: true }}
                          fullWidth
                        />
                      </Stack>

                      <Stack spacing={3} direction="row">
                        <Field
                          name="year_built"
                          label="Year Built"
                          component={TextField}
                          fullWidth
                          InputProps={{
                            endAdornment: <Box sx={styles.textFieldAdornment}>y</Box>,
                          }}
                        />
                        <Field
                          name="year_renovated"
                          label="Year Renovated"
                          component={TextField}
                          fullWidth
                          InputProps={{
                            endAdornment: <Box sx={styles.textFieldAdornment}>y</Box>,
                          }}
                        />
                      </Stack>

                      <Field
                        name="type_zoning"
                        label="Zoning Type"
                        placeholder="Select Zoning Type"
                        component={PlaceholderEnumSelect}
                        formControl={{ fullWidth: true }}
                      />
                    </Box>
                  ),
                },
              ]}
            />
          </DialogContent>
          <DialogActions>
            <Button type="submit" disabled={formik.isSubmitting}>
              Save
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default ProspectEditDialog;

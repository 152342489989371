import { useCallback, useState } from "react";

import { AddProperty } from "@/components/common/dialogs/addProperty/AddProperty";
import { useModal } from "@halftax/ui";

export const useAddPropertyDialog = () => {
  const [leadId, setLeadId] = useState<number>();
  const { showModal } = useModal(
    ({ hideModal }) => !!leadId && <AddProperty onExited={hideModal} leadId={leadId} />,
    [leadId],
  );

  const openAddProperty = useCallback(
    (leadId: number) => {
      setLeadId(leadId);
      showModal();
    },
    [showModal],
  );

  return { openAddProperty };
};

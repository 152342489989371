import { COGNITO_POOLS } from "@/config";

export const getEnvironment = (hostname: string = window.location.hostname) =>
  isLocalhost(hostname) ? "dev" : hostname.split(".")[0];

export const isLocalhost = (hostname: string = window.location.hostname) =>
  hostname === "localhost" || hostname === "127.0.0.1";

export const getPool = () => {
  return COGNITO_POOLS.find((pool) => pool.name === getEnvironment());
};

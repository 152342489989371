import { CreateTag, Tag } from "@/types/tag";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { post, queryKey } from "@/api";

export const useCreateTag = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreateTag) => post<CreateTag, Tag>("tags", data),
    onSuccess: (data) => queryClient.setQueriesData(queryKey(["tags", "detail", data.id]), data),
  });
};

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { Field, Form, FormikProvider, useFormik } from "formik";

import { FC, useState } from "react";
import { pinLinkSchema } from "@/validation";
import PinSearchAutosuggestion from "@/components/common/dialogs/pinLink/PinSearchAutosuggestion";
import Dialog, { DialogProps } from "@/components/common/Dialog";
import { usePropertyLinkPins } from "@/api/property";

export interface PinLinkProps extends DialogProps {
  propertyId: number;
}

export const PinLink: FC<PinLinkProps> = ({ propertyId, ...rest }) => {
  const [open, setOpen] = useState(true);
  const { mutate } = usePropertyLinkPins(propertyId);

  const formik = useFormik({
    validationSchema: pinLinkSchema,
    initialValues: pinLinkSchema.cast({}),
    onSubmit: (values, { setSubmitting }) => {
      mutate(
        { prospect_ids: values.pins.map((pin) => pin.id) },
        {
          onSuccess: () => {
            setOpen(false);
            setSubmitting(false);
          },
        },
      );
    },
  });

  return (
    <Dialog open={open} scroll="body" maxWidth="sm" fullWidth closeButton {...rest}>
      <DialogTitle>Link PINs</DialogTitle>
      <FormikProvider value={formik}>
        <Form>
          <DialogContent>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Search property by PIN or Address
            </Typography>
            <Box
              sx={{
                position: "relative",
                mb: `${70 + formik.values.pins.length * 56}px`,

                "& .MuiInputBase-input": {
                  pl: 4,
                },

                "MuiAutocomplete-poper": {
                  whiteSpace: "pre",
                },
              }}
            >
              <Field component={PinSearchAutosuggestion} name="pins" fullWidth />
            </Box>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button type="submit" disabled={formik.isSubmitting}>
              Link
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

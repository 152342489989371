import { Grid } from "@mui/material";
import { FC, useState } from "react";
import { PropertyTile } from "@/components/common/dialogs/addProperty";
import { Property } from "@/types/property";

type Props = {
  properties: Pick<Property, "id" | "main_prospect">[];
};

export const PropertyList: FC<Props> = ({ properties }) => {
  const [selectedIndex, setSelectedIndex] = useState<number>();

  return (
    <Grid container direction="column">
      {properties.map((property, key) => (
        <Grid
          item
          key={key}
          sx={{
            mb: 2,
          }}
          onClick={() => setSelectedIndex(key)}
        >
          <PropertyTile property={property} active={selectedIndex === key} />
        </Grid>
      ))}
    </Grid>
  );
};

import { Asset, Audit } from "@/types/common";

import { Lead } from "@/types/lead";
import { Prospect } from "@/types/prospect";

export interface Property extends Asset, Audit {
  id: number;
  lead_id?: number;

  lead: Lead;
  main_prospect: Prospect;
  prospects: Prospect[];

  size_land: string;
  size_building: string;

  value_land: string;
  value_building: string;

  value_tax: string;
  acreage: string;

  last_sale_amount: string;
  last_sale_date: string;
}

export enum AppealsProcessType {
  AppealPreperationInProgress = 1,
  AppealPackageCompleted = 2,
  AppealPackageCorrectnessVerified = 3,
  AppealPackageSubmitted = 4,
  NextAwaitingDecision = 5,
  DecissionIssued = 6,
  OfficialNotificationIsIssued = 7,
}

export const appealsProcessLables: {
  [T in AppealsProcessType]: string;
} = {
  [AppealsProcessType.AppealPreperationInProgress]: "Appeal Preperation in Progress",
  [AppealsProcessType.AppealPackageCompleted]: "Appeal Package Completed",
  [AppealsProcessType.AppealPackageCorrectnessVerified]: "Appeal Package Correctness Verified",
  [AppealsProcessType.AppealPackageSubmitted]: "Appeal Package Submitted",
  [AppealsProcessType.NextAwaitingDecision]: "Next Awaiting Decision",
  [AppealsProcessType.DecissionIssued]: "Decission Issued",
  [AppealsProcessType.OfficialNotificationIsIssued]: "Official Notification is Issued",
};

import {
  AutocompleteRenderGetTagProps,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { Close as RemoveLink } from "mdi-material-ui";
import { Prospect } from "@/types/prospect";
import { displayAddress } from "@/utils/display";
import { OnlyPick } from "@/types/util";

type Props = {
  pinLinkList: OnlyPick<Prospect, "owner.address" | "id" | "pin">[];
  getTagProps: AutocompleteRenderGetTagProps;
  isSubmitting: boolean;
};

export const PinLinksList: FC<Props> = ({ pinLinkList, getTagProps, isSubmitting }) => {
  return (
    <TableContainer
      sx={{
        px: 0,
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                width: "30%",
              }}
            >
              <Typography variant="subtitle2">PIN</Typography>
            </TableCell>
            <TableCell
              sx={{
                width: "60%",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  pl: 2,
                }}
              >
                Address
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {pinLinkList.map(({ pin, owner }, index) => (
            <TableRow key={index}>
              <TableCell
                sx={{
                  width: "30%",
                }}
              >
                <Typography variant="body2">{pin}</Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: "60%",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    pl: 2,
                  }}
                >
                  {displayAddress(owner?.address)}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "right",
                }}
              >
                <IconButton
                  onClick={(e) => getTagProps({ index }).onDelete(e)}
                  disabled={isSubmitting}
                >
                  <RemoveLink
                    sx={{
                      color: (theme) => theme.palette.error.light,
                      fontSize: 20,
                      ".Mui-disabled &": {
                        color: (theme) => theme.palette.action.disabled,
                      },
                    }}
                  />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

import { Avatar, Button, DialogContent, Grid, Stack, Typography } from "@mui/material";
import Dialog, { DialogProps } from "@/components/common/Dialog";
import { FC, useState } from "react";

import { AccountCheckOutline } from "mdi-material-ui";
import { Styles } from "@halftax/ui";
import { useNavigate } from "react-router-dom";

export interface LeadAddedProps extends DialogProps {
  leadId: number;
}

const LeadAddedDialog: FC<LeadAddedProps> = ({ leadId, ...props }) => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const styles: Styles = {
    iconCircle: (theme) => ({
      bgcolor: theme.palette.primary.main,
      width: "7.5rem",
      height: "7.5rem",
      color: theme.palette.text.primary,
    }),
    icon: { height: "3rem", width: "3rem", alignSelf: "center" },
    title: { fontSize: "1.25rem", mt: 3, mb: 2, fontWeight: 500 },
    buttonsContainer: {
      alignSelf: "center",
      mt: 3,
    },
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm" {...props}>
      <DialogContent sx={{ px: 0, py: 3 }}>
        <Grid container direction="column" sx={{ textAlign: "center" }}>
          <Grid item alignSelf="center">
            <Avatar sx={styles.iconCircle}>
              <AccountCheckOutline sx={styles.icon} />
            </Avatar>
          </Grid>
          <Grid item>
            <Typography sx={styles.title}>New lead added</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              You can update their profile with more details later.
            </Typography>
          </Grid>
          <Grid item sx={styles.buttonsContainer}>
            <Stack direction="row" spacing={1}>
              <Button onClick={() => setOpen(false)} variant="outlined">
                Close
              </Button>
              <Button onClick={() => navigate(`/leads/${leadId}`)}>Check Profile</Button>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default LeadAddedDialog;

import { Button, ButtonProps, Link, LinkProps } from "@mui/material";

import { FC } from "react";
import { LinkVariant as LinkIcon } from "mdi-material-ui";

export type LinkButtonProps = ButtonProps & LinkProps & { href: string };

const LinkButton: FC<LinkButtonProps> = ({ children, ...rest }) => (
  <Button
    component={Link}
    target="_blank"
    rel="noopener noreferrer"
    startIcon={<LinkIcon />}
    sx={(theme) => ({
      bgcolor: "#d9eff8",
      color: theme.palette.text.primary,
      ":hover": {
        bgcolor: "#c4e3f2",
      },
    })}
    {...rest}
  >
    {children}
  </Button>
);

export default LinkButton;

import { Select, SelectProps } from "formik-mui";

import { MenuItem } from "@mui/material";

export interface EnumSelectProps<T extends number> extends SelectProps {
  values: T;
  labels: { [K in T]: string };
}

const EnumSelect = <T extends number>({ values, labels, ...rest }: EnumSelectProps<T>) => (
  <Select {...rest}>
    {Object.values(values)
      .filter((value) => typeof value === "number")
      .map((value: T) => (
        <MenuItem key={value} value={value}>
          {labels[value]}
        </MenuItem>
      ))}
  </Select>
);

export default EnumSelect;

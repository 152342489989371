import { Styles } from "@halftax/ui";
import { Box, Grid, Typography } from "@mui/material";

export const PropertyStatus = () => {
  const styles: Styles = {
    wrapper: {
      bgcolor: (theme) => theme.palette.action.active,
      position: "absolute",
      p: 1,
      borderRadius: "0.5rem",
      top: 16,
      left: 16,
      width: "auto",
    },
    iconWrapper: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    },
    icon: {
      bgcolor: (theme) => theme.palette.primary.main,
      borderRadius: "50%",
      width: 8,
      height: 8,
      mr: 1,
    },
    label: {
      fontWeight: "600",
      color: (theme) => theme.palette.common.white,
    },
  };

  return (
    <Grid container sx={styles.wrapper}>
      <Grid item sx={styles.iconWrapper}>
        <Box sx={styles.icon}></Box>
      </Grid>
      <Grid
        item
        sx={{
          display: "flex",
        }}
      >
        <Typography variant="caption" sx={styles.label}>
          Awaiting Payment
        </Typography>
      </Grid>
    </Grid>
  );
};

import { FC } from "react";
import SmallTable from "@/components/common/SmallTable";
import { FileDocumentMultiple as InvoiceIcon } from "mdi-material-ui";

export const InvoicesTab: FC = () => {
  return (
    <SmallTable
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      data={[] as any[]}
      options={{
        placeholder: {
          icon: <InvoiceIcon />,
          title: "No invoice yet",
          description: "Once invoices are added, they will be displayed here",
        },
      }}
      columns={(col) => [
        col.accessor("invoiceNumber", {
          header: "Invoice number",
        }),
        col.accessor("Amount", {
          header: "Amount",
        }),
        col.accessor("issueDate", {
          header: "Issue Date",
        }),
        col.accessor("paymentDate", {
          header: "Payment Date",
        }),
        col.accessor("status", {
          header: "Status",
        }),
      ]}
    />
  );
};

import CreateListDialog, {
  CreateListDialogProps,
} from "@/components/common/CreateListDialog/CreateListDialog";

import { useModal } from "@halftax/ui";

const useCreateListDialog = (entityType: CreateListDialogProps["entityType"]) => {
  const { showModal } = useModal(
    ({ hideModal }) => <CreateListDialog entityType={entityType} onExited={hideModal} />,
    [],
  );
  return { openCreateListDialog: showModal };
};

export default useCreateListDialog;

import { FC } from "react";
import { Revenue } from "@/components/dashboard/types";
import { Box, Paper, Typography, useTheme } from "@mui/material";
import { LineChart, Line, XAxis, Tooltip, ResponsiveContainer, YAxis } from "recharts";
import { TooltipProps } from "recharts/types/component/Tooltip";

type Props = {
  revenue: Revenue[];
};

export const DashboardRevenue: FC<Props> = ({ revenue }) => {
  const theme = useTheme();
  const total = revenue
    .reduce((sum, item) => {
      sum += item.value;
      return sum;
    }, 0)
    .toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  const DashboardRevenueTooltip = ({ label, payload, active }: TooltipProps<string, number>) => {
    if (active && payload && payload.length) {
      return (
        <Paper
          sx={{
            py: 1,
            px: 2,
          }}
        >
          <Typography>{`${label} : $${payload[0].value}`}</Typography>
        </Paper>
      );
    }

    return null;
  };

  return (
    <Paper sx={{ p: 3 }} variant="outlined">
      <Typography
        variant="h5"
        align="center"
        sx={{
          fontWeight: 500,
        }}
      >
        $ {total}
      </Typography>

      <Typography
        variant="body1"
        align="center"
        sx={{
          mt: 2,
          color: theme.palette.text.secondary,
        }}
      >
        Total Revenue
      </Typography>
      <Box
        sx={{
          height: 275,
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <LineChart width={500} height={275} data={revenue}>
            <XAxis dataKey="date" tickLine={false} axisLine={false} />
            <YAxis
              dataKey="value"
              hide
              type="number"
              domain={[(dataMin: number) => dataMin - 10000, (dataMax: number) => dataMax + 10000]}
            />
            <Tooltip content={DashboardRevenueTooltip} />
            <Line
              type="monotone"
              dataKey="value"
              stroke={theme.palette.primary.main}
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
};

import EmptyTabMessage from "@/components/common/EmptyTabMessage";
import { Image as GalleryIcon } from "mdi-material-ui";

const GalleryTab = () => (
  <EmptyTabMessage
    icon={<GalleryIcon />}
    title="No pictures yet"
    description="Once pictures are added, they will be displayed here"
  />
);

export default GalleryTab;

import { FC } from "react";
import { Field } from "formik";
import { PhoneInput } from "@halftax/ui";
import { TextField } from "formik-mui";

type Props = {
  formPrefix: string;
};

export const ContactEditGeneralFields: FC<Props> = ({ formPrefix }) => {
  return (
    <>
      <Field
        sx={{
          "& .MuiOutlinedInput-input": {
            textTransform: "capitalize",
          },
        }}
        name={`${formPrefix}.name`}
        label="Full name"
        component={TextField}
        fullWidth
      />
      <Field
        name={`${formPrefix}.description`}
        label="Description"
        component={TextField}
        fullWidth
      />
      <Field
        name={`${formPrefix}.phone`}
        label="Phone"
        component={TextField}
        fullWidth
        InputProps={{
          inputComponent: PhoneInput,
        }}
      />
      <Field
        name={`${formPrefix}.email`}
        label="E-mail"
        type="email"
        component={TextField}
        fullWidth
      />
    </>
  );
};

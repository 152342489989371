import {
  Plus as AddLeadIcon,
  PlaylistPlus as CreateListIcon,
  EmailFastOutline as SendTemplateIcon,
} from "mdi-material-ui";
import { Box, Button, Grid, Stack, Toolbar } from "@mui/material";
import { ProspectPropertyType, prospectPropertyTypeLabels } from "@/types/prospect";
import { displayAddress, displayCurrency, displayDate } from "@/utils/display";

import { FC } from "react";
import { Lead } from "@/types/lead";
import SearchOptions from "@/components/common/SearchOptions";
import { SearchParams } from "@/types/util";
import SearchTable from "@/components/common/SearchTable";
import { search } from "@/api";
import { useAddLeadDialog } from "@/components/lead/AddLeadDialog";
import { useCreateListDialog } from "@/components/common/CreateListDialog";

const LeadList: FC = () => {
  const { openCreateListDialog } = useCreateListDialog("leads");
  const { openAddLeadDialog } = useAddLeadDialog();

  return (
    <>
      <Toolbar variant="regular" sx={{ py: 3 }}>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item>
            <Stack direction="row" spacing={1}>
              <Button startIcon={<AddLeadIcon />} onClick={openAddLeadDialog}>
                Add Lead
              </Button>
              <Button startIcon={<CreateListIcon />} onClick={openCreateListDialog}>
                Create List
              </Button>
              <Button startIcon={<SendTemplateIcon />}>Send Template</Button>
            </Stack>
          </Grid>

          <Grid item>
            <SearchOptions />
          </Grid>
        </Grid>
      </Toolbar>
      <SearchTable
        entity="leads"
        query={(params: SearchParams) =>
          search<Lead>()({
            entity: "leads",
            only: [
              "id",
              "owner.name",
              "owner.address",
              "properties.id",
              "properties.main_prospect.id",
              "properties.main_prospect.address",
              "properties.main_prospect.out_of_state",
              "properties.main_prospect.county.deadline_appeal_tax",
              "properties.main_prospect.type_property",
              "properties.main_prospect.value_tax",
              "properties.main_prospect.assessment_last",
              "properties.main_prospect.assessment_next",
            ],
            ...params,
          }).then((leads) =>
            leads.map(({ id, owner, properties }) => ({
              ...(!!properties?.[0]?.main_prospect ? properties[0].main_prospect : { county: {} }),

              id,
              owner,
              properties,
            })),
          )
        }
        columns={(col) => [
          col.accessor("owner.name", {
            header: "Name",
            cell: (info) => info.row.depth === 0 && info.getValue(),
            size: 140,
          }),
          col.accessor("owner.address", {
            header: "Mailing Address",
            cell: (info) => info.row.depth === 0 && displayAddress(info.getValue()),
            size: 260,
          }),
          col.accessor("address", {
            header: "Property Address",
            cell: (info) => (
              <>
                {displayAddress(info.getValue())}

                {info.row.subRows.length > 0 &&
                  info.row.depth === 0 &&
                  !info.row.getIsExpanded() && (
                    <>
                      ,
                      <Box
                        sx={(theme) => ({
                          color: theme.palette.primary.dark,
                          fontWeight: "bold",
                          display: "inline",
                          pl: 0.5,
                        })}
                      >
                        {`+${info.row.subRows.length}`}
                      </Box>
                    </>
                  )}
              </>
            ),
            size: 260,
          }),
          col.accessor("out_of_state", {
            header: "Out of State",
            cell: (info) => (info.getValue() ? "Yes" : "No"),
            size: 120,
          }),
          col.accessor<"type_property", ProspectPropertyType>("type_property", {
            header: "Type",
            cell: (info) => prospectPropertyTypeLabels[info.getValue()],
            size: 120,
          }),
          col.accessor("county.deadline_appeal_tax", {
            header: "Appeal Deadline",
            cell: (info) => displayDate({ ISOdate: info.getValue(), showSkeleton: false }),
            size: 140,
          }),
          col.accessor("assessment_last", {
            header: "Last Assessment",
            cell: (info) => displayDate({ ISOdate: info.getValue(), showSkeleton: false }),
            size: 140,
          }),
          col.accessor("assessment_next", {
            header: "Next Assessment",
            cell: (info) => displayDate({ ISOdate: info.getValue(), showSkeleton: false }),
            size: 140,
          }),
          col.accessor("value_tax", {
            header: "Tax Value",
            cell: (info) => displayCurrency(info.getValue()),
          }),
        ]}
        options={{
          getSubRows: (row) =>
            row.properties.slice(1).map(({ main_prospect }) => ({
              ...main_prospect,
              owner: row.owner,
              properties: [],
            })),
        }}
      />
    </>
  );
};

export default LeadList;

import { AttachmentEntityType, AttachmentType } from "@/types/attachment";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  useTheme,
} from "@mui/material";
import {
  FileDocumentOutline as DocumentIcon,
  WindowClose as RemoveFileIcon,
  FileDocumentPlusOutline as UploadDocumentIcon,
} from "mdi-material-ui";
import { Form, FormikProvider, useFormik } from "formik";

import { FC } from "react";
import { Field } from "formik";
import { Styles } from "@halftax/ui";
import { TextField } from "formik-mui";
import { useDropzone } from "react-dropzone";
import { useState } from "react";
import { useUploadAttachment } from "@/api/attachment";
import Dialog, { DialogProps } from "@/components/common/Dialog";

export interface DocumentsUploadDialogProps extends DialogProps {
  entityId: number;
  entityType: AttachmentEntityType;
}

const DocumentsUploadDialog: FC<DocumentsUploadDialogProps> = ({
  entityId,
  entityType,
  ...rest
}) => {
  const [open, setOpen] = useState(true);
  const { mutateAsync: uploadAttachment } = useUploadAttachment();
  const theme = useTheme();

  const formik = useFormik({
    initialValues: {
      files: [],
      description: "",
    },
    onSubmit: async (
      { files, description }: { files: File[]; description: string },
      { setSubmitting },
    ) => {
      for (const file of files) {
        await uploadAttachment({
          entity_id: entityId,
          entity_type: entityType,
          file,
          description,
          type: AttachmentType.Document,
        });
      }

      setOpen(false);
      setSubmitting(false);
    },
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      const files: File[] = [...formik.getFieldMeta("files").value, ...acceptedFiles];
      formik.setFieldValue("files", files);
    },
  });

  const removeFile = (index: number) => {
    const files: File[] = [...formik.getFieldMeta("files").value];
    files.splice(index, 1);
    formik.setFieldValue("files", files);
  };

  const styles: Styles = {
    dropzone: {
      bgcolor: theme.palette.primary.lightest,
      borderRadius: "0.25rem",
      fontWeight: 500,
      mb: 3,
      cursor: "pointer",

      // Workaround, CSS does not support customizing dashed borders as in our design,
      // and implementations are browser specific.
      // if you ever need to do this again: https://kovart.github.io/dashed-border-generator/
      backgroundImage: `url("data:image/svg+xml,                          \
      <svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'> \
        <rect                                                             \
          width='100%' height='100%' fill='none'                          \
          stroke='${theme.palette.secondary.main.replace("#", "%23")}'    \
          stroke-width='2' stroke-dasharray='12' stroke-dashoffset='3'    \
        />                                                                \
      </svg>")`,
    },
    selectedFile: {
      p: 2,
      gap: "0.5rem",
      border: `1px solid ${theme.palette.other.border}`,
      borderRadius: "0.5rem",
    },
    removeFileButton: { color: theme.palette.error.main, my: -1, ml: "auto" },
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm" closeButton {...rest}>
      <DialogTitle>Upload Documents</DialogTitle>
      <FormikProvider value={formik}>
        <Form>
          <DialogContent>
            <Box {...getRootProps()} sx={styles.dropzone}>
              <Stack justifyContent="center" alignItems="center" sx={{ py: 3 }} spacing={2}>
                <UploadDocumentIcon fontSize="large" />
                <Box component="input" {...getInputProps()} />
                <Box sx={{ userSelect: "none" }}>Drag & drop file or click here</Box>
              </Stack>
            </Box>

            <Stack spacing={2} sx={{ my: 2 }}>
              {formik.getFieldMeta("files").value.map((file: File, index: number) => (
                <Stack key={`file-${file.name}-${index}`} direction="row" sx={styles.selectedFile}>
                  <DocumentIcon />
                  <Box>{file.name}</Box>
                  <IconButton sx={styles.removeFileButton} onClick={() => removeFile(index)}>
                    <RemoveFileIcon />
                  </IconButton>
                </Stack>
              ))}
            </Stack>

            <Field
              component={TextField}
              name="description"
              placeholder="Enter file description"
              fullWidth
              sx={{ mb: 1 }}
              label="Description"
            />
          </DialogContent>
          <DialogActions>
            <Button type="submit" disabled={formik.isSubmitting}>
              Upload
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default DocumentsUploadDialog;

import { useCallback, useState } from "react";

import { AttachmentEntityType } from "@/types/attachment";
import DocumentsUploadDialog from "@/components/document/DocumentsUploadDialog/DocumentsUploadDialog";
import { useModal } from "@halftax/ui";

const useDocumentsUploadDialog = () => {
  const [entityId, setEntityId] = useState<number>();
  const [entityType, setEntityType] = useState<AttachmentEntityType>();
  const { showModal } = useModal(
    ({ hideModal }) =>
      !!entityId &&
      !!entityType && (
        <DocumentsUploadDialog onExited={hideModal} entityType={entityType} entityId={entityId} />
      ),
    [entityId, entityType],
  );

  const openDocumentsUploadDialog = useCallback(
    (entityType: AttachmentEntityType, entityId: number) => {
      setEntityType(entityType);
      setEntityId(entityId);
      showModal();
    },
    [showModal],
  );

  return { openDocumentsUploadDialog };
};

export default useDocumentsUploadDialog;

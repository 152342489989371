export enum StatusType {
  DataUploaded = 1,
  MarketingCompleted = 2,
  ProposalSent = 3,
  ContractSigned = 4,
}

export const statusTypeLabels: {
  [T in StatusType]: string;
} = {
  [StatusType.DataUploaded]: "Data Uploaded",
  [StatusType.MarketingCompleted]: "Marketing Completed",
  [StatusType.ProposalSent]: "Proposal Sent",
  [StatusType.ContractSigned]: "Contract Signed",
};

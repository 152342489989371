import { Asset, Audit, EntityType } from "@/types/common";

export type AttachmentEntityType = Extract<
  EntityType,
  "counties" | "prospects" | "properties" | "leads" | "leads"
>;

export enum AttachmentType {
  Document = 1,
  Gallery = 2,
  Note = 3,
}

export interface Attachment extends Asset, Audit {
  id: number;
  description?: string;
  entity_type: AttachmentEntityType;
  filename: string;
  upload_key?: string;
  type: AttachmentType;
}

export interface UploadAttachment {
  entity_id: number;
  entity_type: AttachmentEntityType;
  type: AttachmentType;
  description?: string;
  file: File;
}

export interface GetAttachments extends Pick<Attachment, "entity_type" | "type"> {
  entity_id: number;
}

export interface DeleteAttachment extends Pick<Attachment, "id" | "entity_type" | "type"> {
  entity_id: number;
}

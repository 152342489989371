import {
  MapMarkerOutline as AddressIcon,
  At as EmailIcon,
  PhoneOutline as PhoneIcon,
  Web as WebsiteIcon,
} from "mdi-material-ui";
import { Avatar, Box, Skeleton, Stack, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import { displayAddress, displayURL, nameToInitials } from "@/utils/display";

import { Contact } from "@/types/contact";
import { Link } from "@mui/material";
import { PartialSkeleton } from "@/types/util";
import { PhoneOutput } from "@halftax/ui";
import { Styles } from "@halftax/ui";

export interface ContactInfoProps extends Omit<Contact, "id"> {
  icon?: ReactNode;
}

const ContactCard: FC<PartialSkeleton<ContactInfoProps>> = ({
  name,
  description,
  address,
  phone,
  email,
  website,
  icon,
  skeleton,
}) => {
  const styles: Styles = {
    container: {
      p: 3,
      height: "100%",
      minHeight: "13.25rem",
    },

    contactMethods: (theme) => ({
      ".MuiBox-root": {
        display: "flex",
        alignItems: "center",
      },

      ".MuiSvgIcon-root": {
        color: theme.palette.secondary.main,
        mr: 2,
      },

      ".MuiTypography-root": {
        fontWeight: 500,

        ":not(.MuiLink-root)": {
          color: theme.palette.text.secondary,
        },
      },
    }),

    description: (theme) => ({
      color: theme.palette.text.disabled,
      fontSize: "0.875rem",
      fontWeight: 400,
      letterSpacing: "0.15px",
      lineHeight: "1.25rem",
    }),
  };

  return skeleton ? (
    <Box sx={styles.container}>
      <Box sx={{ display: "flex", mb: 3 }}>
        <Box>
          <Skeleton variant="circular">
            <Avatar variant="contact" />
          </Skeleton>
        </Box>
        <Box sx={{ px: 1, width: "100%" }}>
          <Typography variant="subtitle1">
            <Skeleton width="min(16ch, 90%)" />
          </Typography>
          <Typography sx={styles.description}>
            <Skeleton width="min(16ch, 85%)" />
          </Typography>
        </Box>
      </Box>
      <Stack spacing={2} sx={styles.contactMethods}>
        <Typography variant="body2">
          <Skeleton width="min(26ch, 100%)" />
        </Typography>
        <Typography variant="body2">
          <Skeleton width="min(26ch, 100%)" />
        </Typography>
        <Typography variant="body2">
          <Skeleton width="min(26ch, 100%)" />
        </Typography>
      </Stack>
    </Box>
  ) : (
    <Box sx={styles.container}>
      <Box sx={{ display: "flex", mb: 3 }}>
        <Avatar variant="contact">{icon ? icon : nameToInitials(name || "", 2)}</Avatar>
        <Box sx={{ px: 1, width: "100%" }}>
          <Typography
            component="div"
            variant="subtitle1"
            sx={{
              textTransform: "capitalize",
            }}
          >
            {name}
          </Typography>
          <Typography component="div" sx={styles.description}>
            {description}
          </Typography>
        </Box>
      </Box>

      <Stack spacing={2} sx={styles.contactMethods}>
        {phone && (
          <Box>
            <PhoneIcon fontSize="small" />
            <Typography variant="body2">{PhoneOutput(phone)}</Typography>
          </Box>
        )}

        {email && (
          <Box>
            <EmailIcon fontSize="small" />
            <Link variant="body2" href={`mailto:${email}`}>
              {email}
            </Link>
          </Box>
        )}

        {address && (
          <Box>
            <AddressIcon fontSize="small" />
            <Typography variant="body2">{displayAddress(address)}</Typography>
          </Box>
        )}

        {website && (
          <Box>
            <WebsiteIcon fontSize="small" />
            <Link href={website}>{displayURL(website)}</Link>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default ContactCard;

import { Grid, IconButton } from "@mui/material";

import { FC } from "react";
import TabPanel from "@/components/common/TabPanel";
import invariant from "tiny-invariant";
import { useParams } from "react-router-dom";
import MessagesTab from "@/components/common/MessagesTab";
import GeneralTab from "@/components/common/GeneralTab";
import { useGetProspect } from "@/api/prospect";
import { useContactEditDialog } from "@/components/common/dialogs/contactEdit";
import { StatusTab } from "@/components/common/status";
import LatestActivity from "@/components/common/LatestActivity";
import { useStatusDialog } from "@/components/common/dialogs/status";
import { useHeader } from "@/hooks/useHeader";

import AppealsTab from "@/components/common/AppealsTab";
import AssessmentsTab from "@/components/common/AssessmentsTab";
import { PencilOutline as EditIcon, Plus as AddIcon } from "mdi-material-ui";
import { useProspectEditDialog } from "@/components/prospect/ProspectEditDialog";
import ProspectTab from "@/components/prospect/ProspectTab";
import DocumentsTab from "@/components/document/DocumentsTab";
import { useDocumentsUploadDialog } from "@/components/document/DocumentsUploadDialog";
import GalleryTab from "@/components/common/GalleryTab";

const ProspectDetails: FC = () => {
  const { prospectId: id } = useParams();
  invariant(id, "Missing URL param for Prospect ID!");
  const prospectId = Number(id);

  const { data, isSuccess } = useGetProspect(prospectId);

  const { openContactEdit } = useContactEditDialog("prospects", prospectId);
  const { openStatus } = useStatusDialog();
  const { openProspectEditDialog } = useProspectEditDialog();
  const { openDocumentsUploadDialog } = useDocumentsUploadDialog();

  useHeader({
    title: `${data?.owner.name} Property`,
    backRoute: "/prospects",
    enabled: isSuccess,
  });

  const tabs = {
    general: {
      label: "General",
      content: <GeneralTab entityId={prospectId} entityType="prospects" />,
      buttons: (
        <IconButton onClick={openContactEdit}>
          <EditIcon />
        </IconButton>
      ),
    },
    messages: {
      label: "Messages",
      content: <MessagesTab />,
    },
    status: {
      label: "Status",
      content: isSuccess && <StatusTab status={data.status} />,
      buttons: (
        <IconButton onClick={openStatus}>
          <EditIcon />
        </IconButton>
      ),
    },
    activity: {
      label: "Latest Activity",
      disabled: true,
      content: <LatestActivity />,
    },
    appeals: {
      label: "Appeals",
      content: isSuccess ? <AppealsTab {...data.county} /> : <AppealsTab skeleton />,
    },
    assessments: {
      label: "Assessments",
      content: isSuccess ? (
        <AssessmentsTab {...{ ...data.county, ...data }} />
      ) : (
        <AssessmentsTab skeleton />
      ),
    },
    property: {
      label: "Property",
      content: isSuccess ? <ProspectTab prospectId={prospectId} /> : <ProspectTab />,
      buttons: (
        <IconButton onClick={() => openProspectEditDialog(prospectId)}>
          <EditIcon />
        </IconButton>
      ),
    },
    documents: {
      label: "Documents",
      content: <DocumentsTab entityType="prospects" entityId={prospectId} />,
      buttons: (
        <IconButton onClick={() => openDocumentsUploadDialog("prospects", prospectId)}>
          <AddIcon />
        </IconButton>
      ),
    },
    gallery: {
      label: "Gallery",
      content: <GalleryTab />,
    },
  };

  return (
    <Grid container columnSpacing={3} rowSpacing={4} sx={{ p: 3, flexGrow: 1 }}>
      <Grid item xs={12}>
        <TabPanel tabs={[tabs.general, tabs.messages, tabs.status]} />
      </Grid>

      <Grid item xs={12}>
        <TabPanel tabs={[tabs.property]} />
      </Grid>

      <Grid item xs={12} lg={6}>
        <TabPanel tabs={[tabs.appeals]} />
      </Grid>

      <Grid item xs={12} lg={6}>
        <TabPanel tabs={[tabs.assessments]} />
      </Grid>

      <Grid item xs={12}>
        <TabPanel tabs={[tabs.documents]} />
      </Grid>

      <Grid item xs={12}>
        <TabPanel tabs={[tabs.gallery]} />
      </Grid>

      <Grid item xs={12}>
        <TabPanel tabs={[tabs.activity]} />
      </Grid>
    </Grid>
  );
};

export default ProspectDetails;

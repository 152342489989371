import { FC } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Payload } from "recharts/types/component/DefaultLegendContent";

type Props = {
  payload?: Payload[];
};

export const DashboardAppealsLegend: FC<Props> = ({ payload }) => {
  return (
    <Grid container justifyContent="center" sx={{ mt: 2 }}>
      {payload?.map(({ color, value }, index: number) => (
        <Grid
          key={`item-${index}`}
          item
          sx={{
            mx: 3,
          }}
        >
          <Box
            sx={{
              backgroundColor: color,
              width: 8,
              height: 8,
              borderRadius: "50%",
              display: "inline-block",
              mr: 1,
            }}
          ></Box>
          <Typography
            variant="body1"
            sx={{
              display: "inline-block",
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            {value}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

import {
  Avatar,
  Box,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, Fragment } from "react";
import { Prospect, prospectPropertyTypeIcons, prospectPropertyTypeLabels } from "@/types/prospect";
import {
  displayAddress,
  displayCurrency,
  displayDate,
  displayEnum,
  displaySet,
  displayStringWithEnding,
  empty,
} from "@/utils/display";

import { PartialSkeleton } from "@/types/util";
import { HomeOutline as PropertyIcon } from "mdi-material-ui";
import { Styles } from "@halftax/ui";

export interface ProspectTabContentProps
  extends Pick<
    Prospect,
    | "acreage"
    | "address"
    | "description"
    | "last_sale_amount"
    | "last_sale_date"
    | "size_building"
    | "size_land"
    | "type_property"
    | "type_zoning"
  > {
  county: Pick<Prospect["county"], "name">;
  pin: Prospect["pin"] | Prospect["pin"][];
  year_built: Prospect["year_built"] | Prospect["year_built"][];
  year_renovated: Prospect["year_renovated"] | Prospect["year_renovated"][];
}

const ProspectTabContent: FC<PartialSkeleton<ProspectTabContentProps>> = ({
  address,
  county,
  description,
  last_sale_amount,
  last_sale_date,
  pin,
  size_building,
  size_land,
  type_property,
  type_zoning,
  year_built,
  year_renovated,
}) => {
  const styles: Styles = {
    propertyCard: (theme) => ({
      bgcolor: theme.palette.primary.light,
      borderRadius: "1rem",
      boxShadow: "0px 0px 5px rgba(213, 213, 213, 0.25)",
      marginBottom: { md: "-4.75rem" },
      minHeight: { md: "calc(100% + 4.75rem)" },
      minWidth: { lg: "25rem" },
      position: { md: "relative" },
      top: "-4.75rem",
      display: { md: "flex" },
      alignItems: { md: "center" },
    }),
    propertyAvatar: (theme) => ({
      height: "3.5rem",
      width: "3.5rem",
      bgcolor: theme.palette.common.white,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.other.border,
      color: theme.palette.primary.dark,
      position: "relative",
      top: "-2.25rem",
      marginBottom: "-2.25rem",

      "& .MuiSvgIcon-root": {
        width: "1.75rem",
        height: "1.75rem",
      },
    }),
    propertyTable: (theme) => ({
      ".MuiTableCell-root": {
        borderBottom: "none",
        ...theme.typography.body2,

        ":first-of-type": {
          ...theme.typography.subtitle2,
          pr: 2,
        },
      },
    }),
    textSecondary: (theme) => ({
      ...theme.typography.body2,
      color: theme.palette.text.secondary,
    }),
  };

  return (
    <Box>
      <Box sx={(theme) => ({ height: "10rem", bgcolor: theme.palette.secondary.light })} />
      <Grid container sx={{ p: 3, pt: 1 }} spacing={1}>
        <Grid item xs={12} md>
          <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
            <Avatar sx={styles.propertyAvatar}>
              {type_property ? prospectPropertyTypeIcons[type_property] : <PropertyIcon />}
            </Avatar>
            <Tooltip title="Property Type" placement="top">
              <Typography sx={styles.textSecondary}>
                {type_property ? prospectPropertyTypeLabels[type_property] : empty}
              </Typography>
            </Tooltip>
            <Divider orientation="vertical" flexItem />
            {pin instanceof Array ? (
              // eslint-disable-next-line max-params
              pin.map((pinValue, index, pin) => (
                <Fragment key={pinValue}>
                  <Typography sx={styles.textSecondary}>PIN {pinValue}</Typography>
                  {!(index === pin.length - 1) && <Divider orientation="vertical" flexItem />}
                </Fragment>
              ))
            ) : (
              <Typography sx={styles.textSecondary}>PIN {pin}</Typography>
            )}
          </Stack>
          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1" sx={{ whiteSpace: "pre-line" }}>
              {displayAddress(address)}
            </Typography>
            <Typography sx={[styles.textSecondary, { fontWeight: 500 }]}>
              {county ? `${county.name} County` : empty}
            </Typography>
            <Divider sx={{ mt: 3, mb: 2 }} />
            <Typography variant="subtitle1">Description</Typography>
            <Typography paragraph sx={[styles.textSecondary, { mb: 0 }]}>
              {description ? description : empty}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md="auto">
          <Box sx={styles.propertyCard}>
            <TableContainer>
              <Table sx={styles.propertyTable}>
                <TableBody>
                  <TableRow>
                    <TableCell>Building Size</TableCell>
                    <TableCell>{displayStringWithEnding("sq.ft", size_building)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Land Size</TableCell>
                    <TableCell>{displayStringWithEnding("acres.ft", size_land)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Last Sale Amount</TableCell>
                    <TableCell>
                      {last_sale_amount ? displayCurrency(last_sale_amount) : empty}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Last Sale Date</TableCell>
                    <TableCell>
                      {displayDate({ ISOdate: last_sale_date, showSkeleton: false })}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Year Built</TableCell>
                    <TableCell>{displaySet(year_built)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Year Renovated</TableCell>
                    <TableCell>{displaySet(year_renovated)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Zoning Type</TableCell>
                    <TableCell>{displayEnum(type_zoning)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProspectTabContent;

import { Button, Stack, TextField } from "@mui/material";
import {
  ViewWeek as ColumnsIcon,
  FilterVariant as FilterIcon,
  FormatListBulleted as ListIcon,
  Magnify as SearchIcon,
} from "mdi-material-ui";

import { FC } from "react";
import OutlinedIconButton from "@/components/common/OutlinedIconButton";
import { Styles } from "@halftax/ui";

const SearchOptions: FC = () => {
  const styles: Styles = {
    searchTextField: (theme) => ({
      bgcolor: theme.palette.common.white,
      borderRadius: "1.5rem",
      fontSize: "0.8125rem",
      fontWeight: 500,
      letterSpacing: "0.15px",
      lineHeight: "1.375rem",
      maxHeight: "1.875rem",
      px: 1,
      py: 0.5,

      fieldset: {
        borderColor: theme.palette.other.border,
        borderRadius: "1.5rem",
      },

      "input::placeholder": {
        opacity: 1,
        color: theme.palette.text.disabled,
      },
    }),
  };

  return (
    <Stack direction="row" spacing={1}>
      <TextField
        sx={{ m: 0 }}
        InputProps={{
          startAdornment: <SearchIcon sx={(theme) => ({ color: theme.palette.action.active })} />,
          sx: styles.searchTextField,
        }}
        placeholder="Search"
      />
      <Button variant="outlined" startIcon={<ListIcon />}>
        Lists
      </Button>
      <Button variant="outlined" startIcon={<FilterIcon />}>
        Filters
      </Button>
      <OutlinedIconButton size="small">
        <ColumnsIcon fontSize="small" />
      </OutlinedIconButton>
    </Stack>
  );
};

export default SearchOptions;

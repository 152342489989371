import { AddContactOwner, Contact, ContactOwner, EditContact } from "@/types/contact";
import { Asset, Audit } from "@/types/common";
import {
  HomeModern as Flex,
  Bed as Hospitality,
  Factory as Industrial,
  ImageFilterHdr as Land,
  HomeSilo as MixedUse,
  HomeGroup as MultiFamily,
  OfficeBuilding as Office,
  HomeVariant as Other,
  Home as Residential,
  Store as Retail,
  Warehouse as Specialty,
  HomeClock as Student,
} from "mdi-material-ui";

import { Address } from "@/types/address";
import { County } from "@/types/county";
import { PlaceholderEnum } from "@/types/util";
import { ReactNode } from "react";
import { StatusType } from "@/types/status";

export enum ProspectPropertyType {
  Office = 1,
  Industrial = 2,
  Retail = 3,
  Flex = 4,
  MultiFamily = 5,
  Student = 6,
  Land = 7,
  Hospitality = 8,
  Residential = 9,
  Specialty = 10,
  MixedUse = 11,
  Other = 12,
}

export const prospectPropertyTypeLabels: {
  [T in ProspectPropertyType]: string;
} = {
  [ProspectPropertyType.Office]: "Office",
  [ProspectPropertyType.Industrial]: "Industrial",
  [ProspectPropertyType.Retail]: "Retail",
  [ProspectPropertyType.Flex]: "Flex",
  [ProspectPropertyType.MultiFamily]: "Multi-Family",
  [ProspectPropertyType.Student]: "Student",
  [ProspectPropertyType.Land]: "Land",
  [ProspectPropertyType.Hospitality]: "Hospitality",
  [ProspectPropertyType.Residential]: "Residential",
  [ProspectPropertyType.Specialty]: "Specialty",
  [ProspectPropertyType.MixedUse]: "Mixed-Use",
  [ProspectPropertyType.Other]: "Other",
};

export interface Prospect extends Asset, Audit {
  id: number;
  property_id?: number;

  status: StatusType;
  llc: boolean;
  responsible_person: string;
  pin: string;
  type_property: ProspectPropertyType;
  type_street: PlaceholderEnum;
  type_zoning: PlaceholderEnum;
  size_land: string;
  size_building: string;
  year_built: number;
  year_renovated: number;
  assessment_last: string;
  assessment_next: string;
  value_land: string;
  value_building: string;
  gis: string;
  description: string;
  last_sale_amount: string;
  last_sale_date: string;
  value_tax: string;
  acreage: string;
  billing_class: PlaceholderEnum;
  deed_date: string;
  out_of_state: boolean;

  address: Address;
  mailing_address: Address;

  county: County;
  owner: ContactOwner;
  contacts: Contact[];
}

export interface EditProspect {
  last_sale_date?: string | number | Date;
  contacts?: EditContact[];
}

export const prospectPropertyTypeIcons: {
  [T in ProspectPropertyType]: ReactNode;
} = {
  [ProspectPropertyType.Office]: <Office />,
  [ProspectPropertyType.Industrial]: <Industrial />,
  [ProspectPropertyType.Retail]: <Retail />,
  [ProspectPropertyType.Flex]: <Flex />,
  [ProspectPropertyType.MultiFamily]: <MultiFamily />,
  [ProspectPropertyType.Student]: <Student />,
  [ProspectPropertyType.Land]: <Land />,
  [ProspectPropertyType.Hospitality]: <Hospitality />,
  [ProspectPropertyType.Residential]: <Residential />,
  [ProspectPropertyType.Specialty]: <Specialty />,
  [ProspectPropertyType.MixedUse]: <MixedUse />,
  [ProspectPropertyType.Other]: <Other />,
};

export interface LeadProspect {
  pin: string;
  type_property: ProspectPropertyType;

  address: Address;
  mailing_address: Address;

  owner: AddContactOwner;
  contacts: EditContact[];
}

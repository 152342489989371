import { Box, Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Dialog, { DialogProps } from "@/components/common/Dialog";
import { FC, useState } from "react";
import { Field, Form, FormikProvider, useFormik } from "formik";
import { ProspectPropertyType, prospectPropertyTypeLabels } from "@/types/prospect";

import AddressField from "@/components/common/form/AddressField";
import { AlertCircleOutline as Alert } from "mdi-material-ui";
import { ContactError } from "@/components/common/dialogs/contactEdit";
import { CreateLead } from "@/types/lead";
import { EditContact } from "@/types/contact";
import EnumSelect from "@/components/common/form/EnumSelect";
import { PhoneInput } from "@halftax/ui";
import { Styles } from "@halftax/ui";
import TabMenu from "@/components/common/TabMenu";
import { TextField } from "formik-mui";
import { checkIfSecondaryContactFilled } from "@/components/lead/AddLeadDialog/utils";
import { contactsProcess } from "@/components/county/utils";
import { leadAddSchema } from "@/validation";
import { useCreateLead } from "@/api/lead";
import { useLeadAddedDialog } from "@/components/lead/LeadAddedDialog";

const AddLeadDialog: FC<DialogProps> = ({ ...props }) => {
  const [open, setOpen] = useState(true);
  const [submitTriggered, setSubmitTriggered] = useState(false);

  const { mutate } = useCreateLead();
  const { openLeadAddedDialog } = useLeadAddedDialog();

  const initialValues = leadAddSchema.cast({
    main_prospect: {
      type_property: "",
    },
  });

  const submitHandler = () => {
    setSubmitTriggered(true);
  };

  const formik = useFormik({
    validationSchema: leadAddSchema,
    initialValues: initialValues,
    validateOnChange: true,
    onSubmit: (values, { setSubmitting }) => {
      const { llc, owner, main_prospect, primary_contact, secondary_contact } = values;

      const primaryContact: EditContact = { ...primary_contact, website: "" };

      const contacts = [primaryContact];

      const secondaryContact = checkIfSecondaryContactFilled(secondary_contact);
      if (secondaryContact) {
        contacts.push({ ...secondaryContact, website: "" });
      }

      const processedContacts = contactsProcess(contacts);
      const newLeadPayload: CreateLead = {
        contacts: processedContacts,
        llc: llc,
        owner: owner,
        main_prospect: {
          owner: owner,
          contacts: processedContacts,
          address: main_prospect.address,
          mailing_address: owner.address,
          pin: main_prospect.pin,
          type_property: Number(main_prospect.type_property),
        },
      };

      mutate(newLeadPayload, {
        onSuccess: (leadData) => {
          setSubmitting(false);
          setSubmitTriggered(false);
          setOpen(false);
          openLeadAddedDialog(leadData.id);
        },
      });
    },
  });

  const { errors, isSubmitting, isValid } = formik;

  const styles: Styles = {
    alignedIcon: {
      position: "absolute",
      top: "50%",
      right: 0,
      transform: "translateY(-50%)",
    },
  };

  return (
    <Dialog open={open} maxWidth="md" closeButton fullWidth {...props}>
      <DialogTitle>Add lead</DialogTitle>
      <FormikProvider value={formik}>
        <Form>
          <DialogContent sx={{ px: 0, pb: 0 }}>
            <TabMenu
              tabs={[
                {
                  label: "Owner of record",
                  additionalInfo: errors?.owner && submitTriggered && (
                    <Alert sx={[styles.alignedIcon, { color: "error.main" }]} />
                  ),
                  content: (
                    <Box>
                      <Field
                        sx={{
                          "& .MuiOutlinedInput-input": {
                            textTransform: "capitalize",
                          },
                        }}
                        component={TextField}
                        name="owner.name"
                        label="Owner of record"
                        placeholder="Enter full name"
                        fullWidth
                      />
                      <AddressField label="Mailing address" prefix="owner.address" />
                      {!isValid && submitTriggered && <ContactError />}
                    </Box>
                  ),
                },
                {
                  label: "Primary contact",
                  additionalInfo: errors?.primary_contact && submitTriggered && (
                    <Alert sx={[styles.alignedIcon, { color: "error.main" }]} />
                  ),
                  content: (
                    <Box>
                      <Field
                        sx={{
                          "& .MuiOutlinedInput-input": {
                            textTransform: "capitalize",
                          },
                        }}
                        name="primary_contact.name"
                        label="Primary contact"
                        placeholder="Enter full name"
                        component={TextField}
                        fullWidth
                      />
                      <Field
                        name="primary_contact.description"
                        label="Job position"
                        placeholder="e.g. Director"
                        component={TextField}
                        fullWidth
                      />
                      <Field
                        name="primary_contact.phone"
                        label="Phone"
                        type="tel"
                        placeholder="(000) 000-0000"
                        component={TextField}
                        fullWidth
                        InputProps={{
                          inputComponent: PhoneInput,
                        }}
                      />
                      <Field
                        name="primary_contact.email"
                        label="E-mail"
                        type="email"
                        placeholder="example@email.com"
                        component={TextField}
                        fullWidth
                      />
                      <AddressField label="Mailing Address" prefix="primary_contact.address" />
                      {!isValid && submitTriggered && <ContactError />}
                    </Box>
                  ),
                },
                {
                  label: "Secondary contact",
                  additionalInfo: errors?.secondary_contact && submitTriggered && (
                    <Alert sx={[styles.alignedIcon, { color: "error.main" }]} />
                  ),
                  content: (
                    <Box>
                      <Field
                        sx={{
                          "& .MuiOutlinedInput-input": {
                            textTransform: "capitalize",
                          },
                        }}
                        name="secondary_contact.name"
                        label="Secondary contact"
                        placeholder="Enter full name"
                        component={TextField}
                        fullWidth
                      />
                      <Field
                        name="secondary_contact.description"
                        label="Job position"
                        placeholder="e.g. Director"
                        component={TextField}
                        fullWidth
                      />
                      <Field
                        name="secondary_contact.phone"
                        label="Phone"
                        type="tel"
                        placeholder="(000) 000-0000"
                        component={TextField}
                        fullWidth
                        InputProps={{
                          inputComponent: PhoneInput,
                        }}
                      />
                      <Field
                        name="secondary_contact.email"
                        label="E-mail"
                        type="email"
                        placeholder="example@email.com"
                        component={TextField}
                        fullWidth
                      />
                      <AddressField label="Mailing Address" prefix="secondary_contact.address" />
                      {!isValid && submitTriggered && <ContactError />}
                    </Box>
                  ),
                },
                {
                  label: "Property",
                  additionalInfo: errors?.main_prospect && submitTriggered && (
                    <Alert sx={[styles.alignedIcon, { color: "error.main" }]} />
                  ),
                  content: (
                    <Box>
                      <Field
                        name="main_prospect.pin"
                        label="Property PIN"
                        placeholder="Enter PIN"
                        component={TextField}
                        fullWidth
                      />
                      <Field
                        component={EnumSelect}
                        name="main_prospect.type_property"
                        label="Property Type"
                        placeholder="Select Property Type"
                        formControl={{ fullWidth: true }}
                        values={ProspectPropertyType}
                        labels={prospectPropertyTypeLabels}
                      />
                      <AddressField label="Property Address" prefix="main_prospect.address" />
                      {!isValid && submitTriggered && <ContactError />}
                    </Box>
                  ),
                },
              ]}
            />
          </DialogContent>
          <DialogActions>
            <Button type="submit" onClick={submitHandler} disabled={isSubmitting && !isValid}>
              Save
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default AddLeadDialog;

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { Field, Form, FormikProvider, useFormik } from "formik";

import { FC, useState } from "react";
import { statusUpdateSchema } from "@/validation";
import EnumSelect from "@/components/common/form/EnumSelect";
import { statusTypeLabels, StatusType } from "@/types/status";

import Dialog, { DialogProps } from "@/components/common/Dialog";

export const StatusDialog: FC<DialogProps> = ({ ...rest }) => {
  const [open, setOpen] = useState(true);

  const formik = useFormik({
    validationSchema: statusUpdateSchema,
    initialValues: statusUpdateSchema.cast({}),
    onSubmit: (values, { setSubmitting }) => {
      setOpen(false);
      setSubmitting(false);
    },
  });

  return (
    <Dialog open={open} scroll="body" maxWidth="xs" fullWidth {...rest}>
      <DialogTitle>Status</DialogTitle>
      <FormikProvider value={formik}>
        <Form>
          <DialogContent>
            <Typography variant="body1" sx={{ mb: 5 }}>
              You can change the status here:
            </Typography>
            <Field
              name="status"
              label="Status"
              component={EnumSelect}
              formControl={{ fullWidth: true }}
              values={StatusType}
              labels={statusTypeLabels}
              fullWidth
            />
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button type="submit" disabled={formik.isSubmitting}>
              Save
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

import { Box, Grid, Typography } from "@mui/material";

import { FC } from "react";
import { HammerWrench } from "mdi-material-ui";
import { Styles } from "@halftax/ui";

type Props = {
  title?: string;
  subtitle?: string;
};

const Incomplete: FC<Props> = ({
  title = "Coming soon",
  subtitle = "Soon you will be able to see this feature",
}) => {
  const styles: Styles = {
    iconBox: (theme) => ({
      boxShadow: 1,
      display: "inline-block",
      bgcolor: theme.palette.primary.main,
      p: 2,
    }),
    icon: (theme) => ({
      width: "3rem",
      height: "3rem",
      color: theme.palette.text.primary,
    }),
    title: { fontSize: "1.125rem", mt: 3, mb: 2 },
  };

  return (
    <Box sx={{ minHeight: 200, py: 2 }}>
      <Grid container direction="column" sx={{ textAlign: "center" }}>
        <Grid item>
          <Box sx={styles.iconBox}>
            <HammerWrench sx={styles.icon} />
          </Box>
        </Grid>
        <Grid item>
          <Typography sx={styles.title}>{title}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" sx={(theme) => ({ color: theme.palette.text.secondary })}>
            {subtitle}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Incomplete;

import { FC } from "react";
import { Notification } from "@/components/common/dialogs/notifications/types";
import { Grid, Typography } from "@mui/material";
import { displayDateTitle, getDaysDiffWithToday } from "@/utils/display";
import { NotificationsItem } from "@/components/common/dialogs/notifications";
import { Styles } from "@halftax/ui";

type Props = {
  notifications: Notification[];
};

export const NotificationsGroup: FC<Props> = ({ notifications }) => {
  const newNotification = getDaysDiffWithToday(notifications[0].date) === 0;

  const styles: Styles = {
    wrapper: {
      borderBottom: (theme) => `1px solid ${theme.palette.other.border}`,
    },
  };

  return (
    <Grid
      container
      direction="column"
      sx={[
        {
          width: 1,
        },
        !newNotification && styles.wrapper,
      ]}
    >
      <Grid item>
        <Typography
          variant="subtitle2"
          sx={{
            p: 2,
          }}
        >
          {displayDateTitle(notifications[0].date)}
        </Typography>
      </Grid>
      {notifications.map((notification, index) => (
        <Grid
          item
          key={index}
          sx={{
            width: 1,
          }}
        >
          <NotificationsItem notification={notification} newNotification={newNotification} />
        </Grid>
      ))}
    </Grid>
  );
};

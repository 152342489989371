import { EditProspect, Prospect } from "@/types/prospect";
import { putById, queryKey, getByIdOnly } from "@/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { formatISO } from "date-fns";
import invariant from "tiny-invariant";

export const useGetProspect = (id?: number | string) => {
  const only = [
    "id",
    "status",
    "type_property",
    "pin",
    "description",
    "size_land",
    "size_building",
    "acreage",
    "last_sale_amount",
    "last_sale_date",
    "year_built",
    "year_renovated",
    "type_zoning",
    "address",
    "county.name",
    "assessment_next",
    "assessment_last",
    "county.deadline_appeal_annual",
    "county.link_appeal_form",
    "county.link_property_database",
    "county.assessment_frequency",
    "owner",
    "contacts",
    "property_id",
  ] as const;

  return useQuery(
    queryKey(["prospects", "detail", id, { only }]),
    () => {
      invariant(id, "Query should always have id when enabled");
      return getByIdOnly<Prospect>()("prospects", id, only);
    },
    { enabled: !!id },
  );
};

export const useEditProspect = (id: number | string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ last_sale_date, ...rest }: EditProspect) =>
      // formatting date to ISO string here because we can't use yup transforms while using Formik to validate
      putById<EditProspect, Prospect>("prospects", id, {
        ...rest,
        ...(last_sale_date
          ? { last_sale_date: formatISO(new Date(last_sale_date), { representation: "date" }) }
          : {}),
      }),
    onSuccess: (data) => {
      queryClient.setQueriesData(queryKey(["prospects", "detail", id]), data);

      if (!!data.property_id) {
        queryClient.invalidateQueries({
          queryKey: queryKey(["properties", "detail", data.property_id]),
        });
      }
    },
  });
};

export const propertiesSuggestions = [
  {
    address: "701 Glenwood Ave, Raleigh NC 27605-1507",
    pin: "PIN 2433-2311-1000-3333",
    place: "Wake County",
  },
  {
    address: "100 West Washington Street Hagerstown, MD 21740",
    pin: "PIN 2433-444-000000-7777",
    place: "Washington County",
  },
];

export const pinLinkList = [
  {
    pin: "1922-0002-3334-3345-3332",
    address: "701 Glenwood Ave, Raleigh NC 27605-1507",
  },
];

export const infoTiles = [
  {
    label: "Invoice Rate",
    value: 1273,
  },
  {
    label: "Invoice Amount",
    value: 2433,
  },
  {
    label: "Annual Savings",
    value: 24544,
  },
  {
    label: "Total Savings",
    value: 80330,
  },
];

import { Alert, Box, Button, Typography } from "@mui/material";
import { FC, useEffect } from "react";
import { Field, Form, FormikProvider, useFormik } from "formik";

import { Styles } from "@halftax/ui";
import { TextField } from "formik-mui";
import { ArrowLeftThin as ToLoginIcon } from "mdi-material-ui";
import { useAuthenticator } from "@aws-amplify/ui-react";

const ConfirmSignInForm: FC = () => {
  const {
    submitForm,
    isPending,
    error,
    codeDeliveryDetails: { DeliveryMedium, Destination } = {},
    resendCode,
    toSignIn,
  } = useAuthenticator((ctx) => [
    ctx.submitForm,
    ctx.isPending,
    ctx.error,
    ctx.codeDeliveryDetails,
    ctx.resendCode,
    ctx.toSignIn,
  ]);
  const formik = useFormik({
    initialValues: { confirmation_code: "" },
    onSubmit: (values) => {
      submitForm({
        type: "CONFIRM_SIGN_UP",
        ...values,
      });
    },
  });
  const { setSubmitting, isSubmitting } = formik;

  useEffect(() => {
    setSubmitting(isPending);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPending]);

  const title =
    DeliveryMedium === "EMAIL"
      ? "We Emailed You"
      : DeliveryMedium === "SMS"
      ? "We Texted You"
      : "We Sent A Code";

  const message =
    DeliveryMedium === "EMAIL"
      ? `Your code is on the way. To log in, enter the code we emailed to ${Destination}. It may take a minute to arrive.`
      : DeliveryMedium === "SMS"
      ? `Your code is on the way. To log in, enter the code we texted to ${Destination}. It may take a minute to arrive.`
      : "Your code is on the way. To log in, enter the code we sent you. It may take a minute to arrive.";

  const styles: Styles = {
    containerAuth: {
      display: "flex",
      flexDirection: "column",
      minHeight: { sm: "45rem" },
      padding: 4,
      width: { xs: "100%", sm: "24.5rem" },
    },
    toLoginButton: (theme) => ({
      borderRadius: 0,
      boxSizing: "border-box",
      fontSize: "0.875rem",
      fontWeight: 500,
      letterSpacing: "0.15px",
      lineHeight: "1.25rem",
      padding: 0,

      ":hover": {
        background: "none",
        color: theme.palette.common.black,
      },
    }),
  };

  return (
    <Box sx={styles.containerAuth}>
      <Box sx={{ mb: { xs: 2, md: 10 } }}>
        <Typography variant="h4" sx={{ mb: 1 }}>
          {title}
        </Typography>
        <Typography variant="body1" sx={(theme) => ({ color: theme.palette.text.secondary })}>
          {message}
        </Typography>
      </Box>

      <FormikProvider value={formik}>
        <Form>
          <Field
            component={TextField}
            name="confirmation_code"
            label="Code"
            placeholder="Enter your code"
            fullWidth
            required
            sx={{ mb: 6 }}
          />

          <Button size="medium" type="submit" disabled={isSubmitting} fullWidth>
            Confirm
          </Button>

          <Button
            size="medium"
            variant="outlined"
            disabled={isSubmitting}
            fullWidth
            onClick={resendCode}
            sx={{ mt: 2 }}
          >
            Resend Code
          </Button>

          {error && (
            <Alert severity="error" sx={{ mt: 4 }}>
              {error}
            </Alert>
          )}
        </Form>
      </FormikProvider>

      <Box sx={{ mt: "auto", pt: 4 }}>
        <Button
          variant="text"
          startIcon={<ToLoginIcon />}
          sx={styles.toLoginButton}
          disableRipple
          disableElevation
          onClick={toSignIn}
        >
          Back to Login
        </Button>
      </Box>
    </Box>
  );
};

export default ConfirmSignInForm;

import { IconButton as MuiIconButton, iconButtonClasses, styled } from "@mui/material";

import { alpha } from "@mui/material";

const OutlinedIconButton = styled(MuiIconButton)(({ theme }) => ({
  color: theme.palette.secondary.main,
  border: `1px inset ${alpha(theme.palette.secondary.main, 0.5)}`,
  transition: theme.transitions.create(
    ["background-color", "box-shadow", "border-color", "color"],
    {
      duration: theme.transitions.duration.short,
    },
  ),

  ":hover": {
    border: `1px solid ${theme.palette.secondary.main}`,
  },

  [`&.${iconButtonClasses.sizeSmall}`]: {
    width: "1.875rem",
    height: "1.875rem",
  },

  [`&.${iconButtonClasses.sizeMedium}`]: {
    width: "2.25rem",
    height: "2.25rem",
  },

  [`&.${iconButtonClasses.sizeLarge}`]: {
    width: "2.625rem",
    height: "2.625rem",
  },
}));

export default OutlinedIconButton;

import { Property } from "@/types/property";
import { Grid } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { PropertiesList, PropertyTabsContent } from "@/components/property";
import { OnlyPick } from "@/types/util";

type Props = {
  leadId: number;
  properties: OnlyPick<
    Property,
    | "id"
    | "main_prospect.address"
    | "main_prospect.type_property"
    | "prospects.id"
    | "prospects.pin"
  >[];
  displayStatus?: boolean;
  displayAppealsProcess?: boolean;
  displayInfoTiles?: boolean;
  disableAppealsResultAfter?: boolean;
  setPropertyIndex: (index: number) => void;
};

export const PropertyTab: FC<Props> = ({
  leadId,
  properties,
  displayStatus,
  displayAppealsProcess,
  displayInfoTiles,
  disableAppealsResultAfter,
  setPropertyIndex,
}) => {
  const [selectedPropertyIndex, setSelectedPropertyIndex] = useState(0);

  useEffect(() => {
    setPropertyIndex(selectedPropertyIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPropertyIndex]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <PropertiesList
          leadId={leadId}
          properties={properties}
          selectProperty={setSelectedPropertyIndex}
          selectedProperty={selectedPropertyIndex}
          displayStatus={displayStatus}
        />
      </Grid>
      <Grid item xs={12}>
        {properties.map(
          (property, index) =>
            index === selectedPropertyIndex && (
              <PropertyTabsContent
                key={index}
                propertyId={property.id}
                displayAppealsProcess={displayAppealsProcess}
                displayInfoTiles={displayInfoTiles}
                disableAppealsResultAfter={disableAppealsResultAfter}
              />
            ),
        )}
      </Grid>
    </Grid>
  );
};
